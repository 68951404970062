import React, { useRef } from "react";
import photoUrl from "./Profile.png";

const UploadImage = ({ onFileUpload, name, image, noCirle, size }) => {
  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);

  const handleUploadClick = () => {
    console.log(fileInputRef.current);
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    } else if (fileInputRef2 && fileInputRef2.current) {
      fileInputRef2.current.click();
    }
  };
  const handleDeleteUpload = (e) => {
    onFileUpload(e, true);
  };
  return (
    <div
      className={`${!noCirle && "rounded-circle"} d-flex gap-4 text-center ${
        size ? size : "w-100 h-100"
      }`}
    >
      <label className="text-center d-flex p-cursor imageUpload">
        {image ? (
          <>
            <input
              type="file"
              onChange={onFileUpload}
              name={name}
              className="d-none"
              ref={fileInputRef2}
            />
            <img
              src={URL.createObjectURL(image)}
              alt=""
              className={`${size ? size : "w-100 h-100"} img-fluid rounded`}
            />
          </>
        ) : (
          <>
            <input
              type="file"
              onChange={onFileUpload}
              name={name}
              className="d-none"
              ref={fileInputRef}
            />
            <img
              src={photoUrl}
              alt="pics"
              className={`${size ? size : "w-100 h-100"} img-fluid rounded`}
            />
          </>
        )}
      </label>
      {/* <p className="fw-bold">Upload</p> */}
      <div className="d-flex gap-2 flex-column align-items-start justify-content-between">
        <h5>Profile Photo</h5>
        <p>Recommended 250*250</p>
        <div className="buttons navigation">
          <button
            type="button"
            className="outline navigation py-2 px-4 danger"
            onClick={handleDeleteUpload}
          >
            Delete
          </button>
          <button
            type="button"
            className="outline navigation py-2 px-4"
            onClick={handleUploadClick}
          >
            {image ? "Change" : "Upload"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadImage;
