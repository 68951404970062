import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { backendApis } from "../../config";
import { Modal } from "react-bootstrap";
import ModalLoader from "../utils/ModalLoader";

function Department({ saveDepartment, email, next, width, allDepts }) {
  let fexspaceBaseUrl = process.env.REACT_APP_FEXSPACE_CLOUD_DOMAIN;
  const { backendUrl, token, user } = useAuth();
  const [CreatedDepartments, setCreatedDepartments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const nameRef = useRef(null);
  const descRef = useRef(null);
  const checkRef = useRef(null);

  const createDepartment = async (cond) => {
    const name = nameRef.current?.value;
    const description = descRef.current?.value;
    const create_folder = checkRef.current?.checked;
    console.log({ name });
    if (name) {
      setIsLoading(true);
      console.log("all depts: ", allDepts);
      let existingDepts = allDepts.map(({ Department }) =>
        Department.toLowerCase()
      );
      console.log(existingDepts);
      if (existingDepts.includes(name.toLowerCase())) {
        toast.error("Department already exists");
        setIsLoading(false);
        return false;
      }
      let newDepartment = {
        Dept_ID: name,
        Dept_Name: name,
        Dept_Desc: description,
        create_folder,
        authEmail: JSON.parse(localStorage.getItem("user")).email,
        users: [],
      };
      let saveDepartment = async () => {
        let url = `${backendApis[0].url}/api/company/save-system-departments`;
        try {
          let res = await fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
              Accept: "Application/json",
              "Content-Type": "Application/json",
              ...(token ? { Authorization: `Bearer ${token}` } : {}),
            },
            body: JSON.stringify(newDepartment),
          });

          if (!res.ok) {
            // toast.success("Done");
            throw new Error("Error creating department");
            // create in fexspace
          }
          if (!cond) next();
          toast.success("Department created Successfully");
        } catch (error) {
          toast.error("Error occurred, pleas try again");
        }
      };
      await saveDepartment();
      setCreatedDepartments((prev) => [...prev, name]);
      nameRef.current.value = "";
      descRef.current.value = "";
      checkRef.current.checked = false;
    } else {
      if (!cond) {
        next();
        return;
      }
      console.log(email);
      toast.warning("Please enter a department name");
    }
    setIsLoading(false);
  };
  useEffect(() => {
    saveDepartment(createDepartment);
  }, []);

  const deleteDept = async (name) => {
    setIsLoading(true);
    let url = `${backendApis[0].url}/api/company/delete-system-departments`;
    try {
      let res = await fetch(url, {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
        body: JSON.stringify({ Dept_Name: name }),
      });

      if (!res.ok) {
        // toast.success("Done");
        throw new Error("Something went wrong, try again");
        // create in fexspace
      }
      setCreatedDepartments((prev) => prev.filter((d) => d !== name));
      toast.success("Department deleted Successfully");
    } catch (error) {
      toast.error("Error occurred, pleas try again");
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (nameRef.current) nameRef.current.focus();
  }, [showModal]);
  return (
    <div className="contents departments" style={{ width }}>
      <div className="heading d-flex mb-4">
        <div className="title">
          <h1>What are the departments in your company</h1>
          <h5 className="desc">
            Create as much departments staffs can belong to
          </h5>
        </div>
        <button
          type="submit"
          className="outline navigation align-self-end"
          onClick={() => setShowModal(true)}
        >
          Add New Department
        </button>
      </div>
      <div className="dept-container">
        <div className="new-departments department-list">
          <h3>New Departments</h3>
          <ul>
            {CreatedDepartments.map((department, index) => (
              <li key={index} className=" ">
                <h5>{department}</h5>
                <button
                  type="button"
                  className="btn dept "
                  onClick={() => deleteDept(department)}
                >
                  X
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="default-departments department-list">
          <h3>Default Departments</h3>
          <ul>
            {allDepts.map(({ Department, index }) => (
              <li key={index} className="mb-3 ">
                <h5>{Department}</h5>
              </li>
            ))}
          </ul>
        </div>
        <Modal
          show={showModal}
          centered={true}
          enforceFocus={true}
          onHide={() => setShowModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h1 className="h5">Add New Department</h1>
            </Modal.Title>
          </Modal.Header>
          <form
            className="p-4"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="form-group d-grid gap-4">
              <label htmlFor="department">Department Name</label>
              <input
                ref={nameRef}
                type="text"
                className="form-control"
                id="department"
                placeholder="Enter unique department name"
              />
              <label htmlFor="description">Department Description</label>
              <textarea
                ref={descRef}
                type="text"
                className="form-control"
                id="description"
                placeholder="Enter department description"
                rows={4}
              />
              <div className="checkbox d-flex gap-2 align-items-center">
                <input ref={checkRef} type="checkbox" id="fexspace" />
                <label htmlFor="fexspace" className="mb-0">
                  Create group folder in fexspace
                </label>
              </div>
              <Modal.Footer className="buttons">
                <div className="navigation">
                  <button
                    type="submit"
                    className="submit"
                    onClick={() => createDepartment(true)}
                  >
                    Save
                  </button>
                </div>
              </Modal.Footer>
            </div>
          </form>
        </Modal>
      </div>
      <ModalLoader show={isLoading} />
    </div>
  );
}

export default Department;
