import { Dropdown, Table, Form, Button, InputGroup } from "react-bootstrap";
import { CSVLink } from "react-csv";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  MessageIcon,
  ExcelIcon,
  PDFIcon,
  EditIcon,
  ViewIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useIsAdmin,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import { customerFullName, paginationOptions, reactSelectTheme } from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import CustomerSelector from "../utils/CustomerSelector";
import NoTableItem from "../utils/NoTableItem";
import { isEmpty } from "lodash";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import SetSalesRepModal from "../SalesAndInvoicing/SetSalesRepModal";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { IsPrivileged } from "../DisplayChildElement";
import ChartofAccountReceivables from "../Maintenance/ChartofAccountReceivables";

function AccountsReceivables() {
  useScrollTop();
  const reportTypeOptions = [
    {
      value: "Summary",
      label: "Summary",
    },
    {
      value: "Detailed",
      label: "Detailed",
    },
  ];
  const initialFilterParams = {
    customerLastname: "",
    customerFirstname: "",
  };

  const { backendUrl } = useAuth();
  const isAdmin = useIsAdmin();
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [excelData, setExcelData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showSalesRepManager, setShowSalesRepManager] = useState(null);
  const [totalDebtFromOtherAccountRecievables, setTotalDebtFromOtherAccountRecievables] = useState(
    0
  );

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(excelData, CSVLinkRef);

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchRecievables = async queryParams => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/accounts-recievables?&${queryString.stringify(queryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.manufacturers = data.manufacturers.map(el => {
      const value = !["None"].includes(el?.InvoiceCat) ? el.InvoiceCat : "";
      return {
        label: el?.InvoiceCat,
        value,
      };
    });
    return data;
  };

  const {
    data = {
      count: 0,
      recievables: [],
    },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [reportActions.ACCOUNT_RECIEVABLES, queryParams],
    () => fetchRecievables(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/reports/accounts-recievables?${queryString.stringify(rest)}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;

    const manufacturer = exData.data.debtorsCat.map((d, i) => [
      i + 1,
      customerFullName(d?.DebtorsCat_Customers),
      d.InvoiceCat,
      currency(d.TotalDebt, {
        symbol: "",
        precision: 2,
      }).format(),
    ]);
    const nonManufacturer = exData.data.recievables.map((d, i) => [
      i + 1,
      d.Cust_ID,
      d.Customer,
      d.PhoneNo1,
      currency(d.TotalDebt, {
        symbol: "",
        precision: 2,
      }).format(),
    ]);

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";

    exData = queryParams?.manufacturer ? manufacturer : nonManufacturer;

    exData = [
      [company],
      ["Account Receivables Report"],
      [date],
      [""],
      queryParams?.manufacturer
        ? ["S/N", "Business Name", "Manufaturer", "Total Debt"]
        : ["S/N", "Cust ID", "Business Name", "Phone No", "Total Debt"],
      ...exData,
      [""],
      ["Summary"],
      [
        "Total Debt",
        currency(data.totalDebt, {
          symbol: "",
        }).format(),
      ],
      ["Credit Count", 0],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  const handleFilterParamsChange = e => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = e => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = date => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedCustomer = customer => {
    setFilterParams({
      ...filterParams,
      Cust_ID: customer.Cust_ID,
      customerLastname: customer.LastName,
      customerFirstname: customer.FirstName,
    });
    setShowItemsPopover(false);
  };

  const manageSalesRep = el => {
    setSelectedCustomer(el);
    setShowSalesRepManager(true);
  };

  return (
    <IsPrivileged roleName="Accounts Receivables">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button variant="white" onClick={() => setShowFilter(!showFilter)}>
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                    {/*  <Form.Group className="mb-2-5">
                    <Form.Label>Type</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      placeholder="All"
                      isSearchable={false}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          reportType: selected.value,
                        });
                      }}
                      defaultValue={
                        queryParams?.reportType
                          ? reportTypeOptions.find(
                              (el) => el.value === queryParams?.reportType
                            )
                          : reportTypeOptions[0]
                      }
                      options={reportTypeOptions}
                    />
                  </Form.Group> */}
                    <Form.Group className="mb-2-5">
                      <Form.Label>Manufacturer</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        name="manufacturer"
                        placeholder="None"
                        theme={reactSelectTheme}
                        isSearchable={false}
                        key={data?.manufacturers}
                        options={data?.manufacturers || []}
                        defaultValue={
                          queryParams?.manufacturer && data?.manufacturers
                            ? data?.manufacturers.find(el => el.value === queryParams?.manufacturer)
                            : {
                                value: "",
                                label: "None",
                              }
                        }
                        onChange={selected => {
                          setFilterParams({
                            ...filterParams,
                            manufacturer: selected.value,
                          });
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Customer ID</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="Cust_ID"
                          placeholder="Enter Customer ID"
                          value={filterParams?.Cust_ID}
                          onChange={e => handleFilterParamsChange(e)}
                        />
                        <Popover
                          isOpen={showItemsPopover}
                          onClickOutside={() => setShowItemsPopover(false)}
                          content={() => (
                            <CustomerSelector
                              handleSelectedCustomer={handleSelectedCustomer}
                              setShowCustomerSelector={setShowItemsPopover}
                            />
                          )}
                          position="bottom"
                        >
                          <InputGroup.Text onClick={() => setShowItemsPopover(!showItemsPopover)}>
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Business Name</Form.Label>
                      <Form.Control
                        name="customerLastname"
                        placeholder="Enter Customer Lastname"
                        value={filterParams?.customerLastname}
                        onChange={e => handleFilterParamsChange(e)}
                      />
                    </Form.Group>

                    <hr className="mt-3 mb-4" />

                    <Form.Group className="">
                      <Form.Label>Date Range</Form.Label>
                      <div className="position-relative">
                        <RsDateRangePicker
                          disabled
                          placement="auto"
                          value={
                            filterParams.startDate && filterParams.endDate
                              ? [
                                  parse(filterParams.startDate, "yyyy-MM-dd", new Date()),
                                  parse(filterParams.endDate, "yyyy-MM-dd", new Date()),
                                ]
                              : []
                          }
                          onClean={() => clearDateRange()}
                          onOk={date => filterByDateRange(date)}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>

            <div className="content-body">
              <header>
                <h1>
                  {!showFilter && (
                    <button onClick={() => setShowFilter(!showFilter)} className="btn filter">
                      <FilterTwoIcon />
                    </button>
                  )}
                  Receivables Spreadsheet
                  <button title="Refresh" onClick={() => refetch()} className="btn text-primary">
                    <CachedIcon />
                  </button>
                </h1>

                <div className="actions mr-5">
                  {/*  <button className="btn print">
                  Print <PrintIcon />
                </button>

                <button className="btn print">
                  Export <ExportIcon />
                </button>

                <button className="btn sendSms">
                  Send SMS <MessageIcon />
                </button> */}

                  <CSVLink
                    className="btn print d-none"
                    filename={`Account Receivables(${format(
                      new Date(),
                      "dd-MMM-yyyy hh:mm:ss a"
                    )}).csv`}
                    data={excelData}
                    ref={CSVLinkRef}
                  />

                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="btn print"
                      disabled={isfetchingExcel}
                      bsPrefix=""
                    >
                      <ExportIcon color="#008000" />
                      Export
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="text-center"
                    >
                      <Dropdown.Item as="div" onClick={onDownloadExcelData} className="p-cursor">
                        Excel <ExcelIcon color="#008000" />
                      </Dropdown.Item>
                      <Dropdown.Item as="div">
                        <a
                          href={`${backendUrl}/api/reports/pdf/accounts-recievables?${queryString.stringify(
                            rest
                          )}`}
                          target="_blank"
                        >
                          PDF <PDFIcon color="#ff0000" />
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <button
                    onClick={() => setBtnSummaryActive(true)}
                    className="btn summaryBtnWhite "
                  >
                    Show Summary
                  </button>
                </div>
              </header>

              <div className="px-md-4">
                {queryParams?.manufacturer ? (
                  <>
                    <Table responsive borderless striped className="product-table">
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Business Name</th>
                          <th>Manufaturer</th>
                          <th>Total Debt (NGN)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.debtorsCat?.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{customerFullName(el?.DebtorsCat_Customers)}</td>
                            <td>{el.InvoiceCat}</td>
                            <td>
                              {currency(el.TotalDebt, {
                                symbol: "",
                                precision: 2,
                                negativePattern: "0.00",
                              }).format()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {!isFetching && isSuccess && isEmpty(data?.debtorsCat) ? (
                      <NoTableItem queryParams={queryParams} />
                    ) : null}
                  </>
                ) : (
                  <>
                    <Table responsive borderless striped hover className="product-table">
                      <thead>
                        <tr>
                          <th />
                          <th>S/N</th>
                          <th>Cust ID</th>
                          <th>Business Name</th>
                          <th>Phone No</th>
                          <th>Total Debt(NGN)</th>
                          <th>Total Debt (USD)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.recievables.map((el, index) => (
                          <tr
                            className="p-cursor"
                            onClick={e => {
                              if (e.detail === 2)
                                navigate(
                                  `/reports/customer-ledger?customerId=${el.Cust_ID}&customerName=${
                                    el.Customer
                                  }&enableDateRange=false`
                                );
                            }}
                            key={index}
                          >
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant=""
                                  className="bg-white border-0"
                                  bsPrefix="print more"
                                >
                                  <DotsVerticalIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  popperConfig={{
                                    strategy: "fixed",
                                  }}
                                  renderOnMount
                                  className="dropdown-with-icons"
                                >
                                  {isAdmin && (
                                    <Dropdown.Item onClick={() => manageSalesRep(el)} as="button">
                                      <EditIcon />
                                      Manage Sales Rep
                                    </Dropdown.Item>
                                  )}
                                  <Link
                                    to={`/reports/customer-ledger?customerId=${
                                      el.Cust_ID
                                    }&customerName=${el.Customer}&enableDateRange=false`}
                                  >
                                    <Dropdown.Item as="button">
                                      <ViewIcon />
                                      View Customer Ledger
                                    </Dropdown.Item>
                                  </Link>
                                </Dropdown.Menu>{" "}
                              </Dropdown>
                            </td>
                            <td>{data?.startIndex + index + 1}</td>
                            <td>{el.Cust_ID}</td>
                            <td>{el.Customer}</td>
                            <td>{el.PhoneNo1}</td>
                            <td>
                              {currency(el.TotalDebt, {
                                symbol: "",
                                precision: 2,
                                negativePattern: "0.00",
                              }).format()}
                            </td>{" "}
                            <td>
                              {currency(el.debtInUSD.TotalDebt, {
                                symbol: "",
                                precision: 2,
                                negativePattern: "0.00",
                              }).format()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {!isFetching && isSuccess && isEmpty(data.recievables) ? (
                      <NoTableItem queryParams={queryParams} />
                    ) : null}
                  </>
                )}
              </div>

              {!data?.paginationDisabled ? (
                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>

                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={e => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={data.count / queryParams.limit}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              ) : (
                <div className="py-2" />
              )}

              <div
                className={btnSummryActive ? `summaryNew border-top open` : `summaryNew border-top`}
              >
                <div className="summaryHeader">
                  <h2>Summary</h2>
                  {btnSummryActive && (
                    <button onClick={() => close()} className="btn p-0">
                      <SummaryCloseIcon />
                    </button>
                  )}
                </div>

                <div className="gridCont">
                  <div className="gridChild gridChildBorderLeftBlue">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data.totalDebt, {
                          symbol: "",
                        })
                          // .add(totalDebtFromOtherAccountRecievables)
                          .format()}
                      </p>
                      <p className="gridChld2">Total Debt (₦)</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftBlue">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data.totalDebtUSD, {
                          symbol: "$",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Debt ($)</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftOrange">
                    <div className="gridChildLeft">
                      <p className="gridChld1">{0} </p>
                      <p className="gridChld2">Credit Count</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showSalesRepManager && selectedCustomer ? (
          <SetSalesRepModal
            selectedCustomer={selectedCustomer}
            setSelectedCustomer={setSelectedCustomer}
            setShowSaleRepModal={setShowSalesRepManager}
          />
        ) : null}
        <ModalLoader show={isfetchingExcel} />
      </main>
    </IsPrivileged>
  );
}

export default AccountsReceivables;
