import "../../assets/scss/signupProcess.scss";
import Industry from "./Industry";
import { useEffect, useRef, useState } from "react";
import BusinessType from "./BusinessType";
// import CompanySettings from "./CompanySettings";
import Reciept from "./Reciept";
import { backendApis } from "../../config";
import { toast } from "react-toastify";
import Department from "./Department";
import Staffs from "./Staffs";
import CompanyInformationSettings from "./CompanyInformationSettings";
import LogUserIn from "./LogUserIn";
import Background from "./Background";
import ModalLoader from "../utils/ModalLoader";
const EBbackendApis = process.env.REACT_APP_EB_BACKEND_URL;

function SignupProcess() {
  const PAGENUM = 6; // Number of pages
  // const pageWidth = 0; // size of each page
  const FULLPAGEWIDTH = 74 * PAGENUM;
  const HRSLIDINGWIDTH = 100 / PAGENUM; // max width divided by number of pages

  const [slider, setSlider] = useState({
    hrValue: HRSLIDINGWIDTH,
    sliderValue: 0,
  }); // sliding of the page
  let [once, setOnce] = useState(true); // to execute the login function once
  const containerRef = useRef(null);

  const [userData, setUserData] = useState({}); // user's data to login
  const [userLogin, setUserLogin] = useState(null); // login function
  const [companyInfo, setCompanyInfo] = useState(null); // company information submittion function
  const [settingsFunction, setSettingsFunction] = useState(null); // company settings submittion function
  const [industryFunction, setIndustryFunction] = useState(null); // industry information submittion function
  const [businessType, setBusinessType] = useState(null); // business type submittion function
  const [recieptType, setRecieptType] = useState(null); // reciept submittion function
  const [departmentFunction, setDepartmentFunction] = useState(null); // department submittion function
  const [staffFunction, setStaffFunction] = useState(null); // staff submittion function
  const [currentPage, setCurrentPage] = useState(0); // current page submittion function
  const [isLoading, setIsLoading] = useState(false); // is loading submittion function
  let [pageWidth, setPageWidth] = useState(0);
  const [allDepts, setAllDepts] = useState([]);

  const saveUserLoggedIn = (func) => {
    setUserLogin(() => func); // save the child's function
  };

  // const saveCompanySettings = async (func) => {
  //   setSettingsFunction(() => func); // Save the child function
  // };

  const saveCompanyInfo = (func) => {
    setCompanyInfo(() => func); // save the child's function
  };

  const saveIndustry = (func) => {
    setIndustryFunction(() => func); // Save the child's function
  };

  const saveBusinessType = (func) => {
    setBusinessType(() => func); // save the child's function
  };

  const saveRecieptType = (func) => {
    setRecieptType(() => func); // save the child's function
  };

  const saveDepartment = (func) => {
    setDepartmentFunction(() => func); // save the child's function
  };

  const saveStaff = (func) => {
    setStaffFunction(() => func); // save the child's function
  };

  const functions = [
    industryFunction,
    businessType,
    // settingsFunction,
    recieptType,
    companyInfo,
    departmentFunction,
    staffFunction,
  ]; // array of submittion functions

  // handle each page size
  useEffect(() => {
    let width = pageWidth;
    if (containerRef.current) {
      width = containerRef.current.getBoundingClientRect().width + 16;
    }
    setPageWidth(width);

    // Update width when window resizes
    const handleResize = () => {
      if (containerRef.current) {
        width = containerRef.current.getBoundingClientRect().width + 16;
        let sliderValue = -width * currentPage;
        setSlider({
          hrValue: HRSLIDINGWIDTH,
          sliderValue,
        });
        setPageWidth(width);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [currentPage]);
  const handleSlider = async (direction) => {
    if (once) {
      userLogin(); // Invoke the login function to log the user in
      setOnce(false);
    }

    if (direction === "next") {
      let submit = functions[currentPage];
      if (submit) {
        setIsLoading(true);
        if (currentPage === 3) {
          await submit.requestSubmit();
        } else {
          await submit();
        }
        setIsLoading(false);
      }
    }
    if (direction === "prev") {
      if (currentPage === 0) return;
      setSlider((prev) => {
        const hrValue = prev.hrValue - HRSLIDINGWIDTH;
        const sliderValue = prev.sliderValue + pageWidth;
        if (hrValue < HRSLIDINGWIDTH) {
          setCurrentPage(PAGENUM - 1);
          return {
            hrValue: 100,
            sliderValue: pageWidth - FULLPAGEWIDTH,
          };
        }
        setCurrentPage((prev) => --prev);
        return { hrValue, sliderValue };
      });
    }
  };

  const nextPage = () => {
    pageWidth = containerRef.current.getBoundingClientRect().width + 16;
    setSlider((prev) => {
      const hrValue = prev.hrValue + HRSLIDINGWIDTH;
      const sliderValue = prev.sliderValue - pageWidth;
      if (hrValue > 101) {
        setCurrentPage(0);
        return { hrValue: HRSLIDINGWIDTH, sliderValue: 0 };
      }
      setCurrentPage((prev) => ++prev);
      return { hrValue, sliderValue };
    });
  };

  useEffect(() => {
    let fetchedUserData = {};
    (async () => {
      try {
        let id = localStorage.getItem("newUserId");

        // get the signup data from EB server
        let response = await fetch(
          `${EBbackendApis}/api/users/get-first-staff`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({ id }),
          }
        );

        if (!response.ok) {
          response = await response.json();
          throw new Error(response.message);
        }
        response = await response.json();
        if (response.success) {
          fetchedUserData = response.data;
          setUserData(response.data);
          console.log("fetching user successful");
        }

        // creating the first user in their db
        response = await fetch(
          `${backendApis[0].url}/api/users/create-first-user`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(fetchedUserData),
            credentials: "include",
          }
        );

        if (!response.ok) {
          response = await response.json();
          throw new Error(response.message);
        }
        response = await response.json();
        if (response.success) {
          console.log("creation successful");
        }
      } catch (error) {
        console.error("Error creating first user: ", error.message);
        if (error.message !== "Email already exists") {
          toast.error("Error occurred during signup process");
        }
      }
    })();
  }, []); // handle getting user from EB, saving the user in the database and logging them in.

  return (
    <Background>
      <div className="pageContainer">
        <section className="main">
          <div className="progressBar">
            <div className="base">
              {Array(PAGENUM)
                .fill("")
                .map((_, i) => (
                  <hr
                    className={currentPage >= i && "active"}
                    style={{ width: slider.hrValue + "%" }}
                  />
                ))}
            </div>
            <div className="progressNum">
              <span>{currentPage + 1}</span>/{PAGENUM}
            </div>
          </div>
          <section className="slider" ref={containerRef}>
            <div
              className="slideContainer"
              style={{
                left: slider.sliderValue + "px",
              }}
            >
              {/* <BusinessName setData={setData}/> */}
              <LogUserIn
                userData={userData}
                saveUserLoggedIn={saveUserLoggedIn}
              />
              <Industry
                saveIndustry={saveIndustry}
                email={userData.email}
                next={nextPage}
              />
              <BusinessType
                saveBusinessType={saveBusinessType}
                email={userData.email}
                next={nextPage}
              />
              {/* <CompanySettings
                submitForm={saveCompanySettings}
                next={nextPage}
              /> */}
              <Reciept saveReciept={saveRecieptType} next={nextPage} />
              <CompanyInformationSettings
                setCompanyInfo={saveCompanyInfo}
                next={nextPage}
              />
              <Department
                saveDepartment={saveDepartment}
                email={userData.email}
                next={nextPage}
                allDepts={allDepts}
              />
              <Staffs
                next={nextPage}
                saveStaff={saveStaff}
                setAllDepts={setAllDepts}
              />
              {/* <FinalPage /> */}
              {/* <Submitting /> */}
            </div>
          </section>
          <div className="buttons">
            <div className={`navigation ${isLoading && "isLoading"}`}>
              <>
                <button
                  onClick={() => handleSlider("prev")}
                  type="submit"
                  className="outline navigation"
                >
                  Previous
                </button>
                <button
                  className="navigation next"
                  onClick={() => handleSlider("next")}
                  type="submit"
                >
                  {currentPage === PAGENUM - 1 ? "Finish" : "Save and Next"}
                </button>
              </>
            </div>
            {/* {setUpComplete && ( */}
            <div>
              {currentPage < PAGENUM - 1 && (
                <button
                  type="button"
                  className="skip"
                  // disabled={isLoading}
                  onClick={() =>
                    currentPage === PAGENUM - 1 ? "" : nextPage()
                  }
                >
                  Skip Step
                </button>
              )}
            </div>
            {/* )} */}
          </div>
        </section>
      </div>
      <ModalLoader show={isLoading} />
    </Background>
  );
}

export default SignupProcess;
