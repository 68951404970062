import { format } from "date-fns";
import { Modal, Form, Button } from "react-bootstrap";
import { useQuery } from "react-query";
import { useFormik } from "formik";
import * as yup from "yup";
import { Fragment, useEffect, useRef, useState, useMemo } from "react";
import { useAuth } from "../../hooks/useAuth";
import eventBus from "../../utils/EventBus";
import RequisitionService from "../../hooks/requisitionService";
import { isEmpty, uniqBy } from "lodash";
import ModalLoader from "../utils/ModalLoader";
import { useEffectOnce } from "../../utils/hooks";
import ConfirmDialog from "../ConfirmDialogue";
import { LoaderIcon, UserIcon } from "../Icons";
import { cacheTimes, queryActions } from "../../utils/reactQueryActions";
import { useStoreState } from "easy-peasy";
import Select from "react-select";
import StaffInviteModal from "../utils/StaffInviteModal";
import AccountPlusIcon from "mdi-react/AccountPlusIcon";

export default function NewCustomRequisitionModal({
  setShowNewCustomRequisitionModal,
  template,
  sendAfterRequisition,
  FormID,
  handleCreatedRequisition,
  childFormId,
}) {
  const { user: authUser, backendUrl } = useAuth();
  const { createRequisitionMutation } = RequisitionService();
  const iframeRef = useRef();
  const currentLoggedInCompany = useStoreState(state => state.currentLoggedInCompany);

  const [isLoadingIframe, setIsLoadingIframe] = useState(true);
  const [reciever, setReceiver] = useState();
  const [showStaffInviteModal, setShowStaffInviteModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      title: "",
      cost: 0.0,
      description: "",
      department: "",
      jobGrade: "",
      recipient: "",
      comment: " ",
      vendor: "nil",
      type: "Normal",
      filledFormData: "",
      recipientEmails: [],
      selectedUsers: [],
    },
    validationSchema: yup.object().shape({
      title: yup.string().required(),
      //  description: yup.string().required(),
      // filledFormData: yup.string().required(),
    }),
    onSubmit: async values => {
      if (
        isEmpty(values.recipient) &&
        isEmpty(values.recipientEmails) &&
        !(await ConfirmDialog({
          title: `Recipient not Selected`,
          description: "Continue?",
        }))
      ) {
        return;
      }

      values.amount = 0;
      values.requestto = values.recipient;
      values.regdate = format(new Date(), "yyyy-MM-dd");
      values.status = "Pending";

      if (isEmpty(values.comment)) values.comment = "...";

      /*  console.log(values);
       return; */

      createRequisitionMutation.mutate(
        {
          ...values,
          description: values?.description ? values?.description : "...",
          FormID,
          childFormId,
        },
        {
          onSuccess: data => {
            sendAfterRequisition && sendAfterRequisition(data.requisition);
            eventBus.dispatch("REQUISITION_CREATED", data.requisition);
            if (handleCreatedRequisition) handleCreatedRequisition();
            setShowNewCustomRequisitionModal(false);
          },
          onError: ({ errors }) => {
            if (errors) formik.setErrors(errors);
          },
        }
      );
    },
  });

  useEffectOnce(() => {
    try {
      const page = JSON.parse(template)[0];
      const signatories = page.children
        .filter(el => el.type === "signatoryElement")
        .map(el => el.props.fields);

      if (signatories) {
        const permissions = signatories.flat().map(el => el.permission);

        if (permissions) {
          setReceiver(permissions[1]);

          formik.setFieldValue("department", permissions[1].department);
          formik.setFieldValue("recipient", permissions[1].staff);
          formik.setFieldValue("jobGrade", permissions[1].jobGrade);
        }
      }
    } catch (err) {
      console.log(err);
    }
  });

  const setformData = async data => {
    formik.setFieldValue("filledFormData", data);

    if (
      await ConfirmDialog({
        title: "Submit",
        description: "Are you sure, you want to submit",
      })
    ) {
      formik.submitForm();
    }
    /*   setTimeout(() => {
      formik.submitForm();
    }, 500); */
  };

  const handleMessage = message => {
    if (message.data.type === "CLOSE") {
      setShowNewCustomRequisitionModal(false);
    } else if (message.data.type === "SAVE") {
      //  console.log(message.data.value, "react");
      setformData(message.data.value);
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    // cleanup this component
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  //----------------------------------------------------------------------
  // fetch data for form
  const getDepartments = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/users/departments-with-users`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    data = {
      departments: [],
      staffs: [],
      jobGrades: [],
      departmentWithLeads: [],
    },
    isFetching,
  } = useQuery([queryActions.GET_USERS_WITH_DEPARTMENTS], () => getDepartments(), {
    enabled: true,
    keepPreviousData: true,
    cacheTime: cacheTimes.GET_USERS_WITH_DEPARTMENTS,
  });

  const getForm = async () => {
    let response = await fetch(`${backendUrl}/api/forms/get-form/${FormID}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };
  const getFormQuery = useQuery(["GET_FORM"], () => getForm(), {
    enabled: true,
  });
  //-----

  const setOrientation = printOrientation => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type: "OREINTATION",
          data: {
            ...printOrientation,
          },
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  const sendSetupData = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          data: {
            ...data,
            template: JSON.parse(template),
            backendUrl,
            companyData: currentLoggedInCompany,
          },
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  const save = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type: "SUBMIT",
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  eventBus.useCustomEventListener("SEND_FILE_TO_IFRAME", ({ files, elementId, elementType }) => {
    console.log(files, elementId, elementType);
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          data: {
            files,
            elementId,
            elementType,
          },
          type: "FILES_FROM_PARENT",
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  });

  const staffSelectOptions = useMemo(() => {
    const jobGrades = data.staffs
      .filter(el => el.Department === formik.values.department)
      .map(el => ({ jobGrade: el.jobGrade }));

    const staffs = data.staffs.filter(el => {
      if (!formik.values.department) return true;

      if (!formik.values.department && !formik.values.jobGrade) return true;

      if (formik.values.department === "Department Lead") {
        const myDepartmentLead = data.departmentWithLeads
          .filter(el => el.Dept_Lead && el.Dept_Name === authUser.Department)
          .map(el => JSON.parse(el.Dept_Lead))
          .flat();

        return myDepartmentLead.find(lead => lead.Staff_ID === el.Staff_ID);
      }

      if (formik.values.department && !formik.values.jobGrade) {
        return el.Department === formik.values.department;
      }

      if (formik.values.department && formik.values.jobGrade) {
        return el.Department === formik.values.department && el.jobGrade === formik.values.jobGrade;
      }
    });

    return {
      departments: [
        { label: "Any Department", value: "" },
        { label: "Department Lead", value: "Department Lead" },
        ...data.departments.map(el => ({
          label: el.Department,
          value: el.Department,
        })),
      ],
      jobGrades: [
        { label: "Any Job Grade", value: "" },
        ...uniqBy(jobGrades, "jobGrade").map(el => ({
          label: el.jobGrade,
          value: el.jobGrade,
        })),
      ],
      staffs: [
        { label: "Any Staff", value: "" },
        ...staffs.map(el => ({
          label: `${el?.changeableId ? el?.changeableId : el.Staff_ID} - ${el.Name}`,
          value: el.Staff_ID,
        })),
      ],
    };
  }, [data, formik.values.department, formik.values.jobGrade]);

  const handleSelectionCompleted = selectedUsers => {
    formik.setFieldValue("recipientEmails", []);
    formik.setFieldValue("selectedUsers", selectedUsers);
    formik.setFieldValue("department", selectedUsers[0].Department);
    formik.setFieldValue("jobGrade", selectedUsers[0].jobGrade);
    formik.setFieldValue("recipient", selectedUsers[0].Staff_ID);
    // console.log(selectedUsers)
  };

  const handleSelectedEmail = email => {
    formik.setFieldValue("recipientEmails", [email]);
    formik.setFieldValue("selectedUsers", []);
  };

  return (
    <>
      <Modal
        show={true}
        onHide={() => setShowNewCustomRequisitionModal(false)}
        dialogClassName={`${
          getFormQuery?.data?.form.orientation === "Landscape" ? "modal-90w" : ""
        }`}
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        size="lg"
        // fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="h5"> New Request</h1>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="approve-action p-0">
          <Form noValidate onSubmit={formik.handleSubmit} className="p-3 px-4">
            <Form.Group className="mb-3">
              <Form.Label>Title :</Form.Label>
              <Form.Control
                name="title"
                placeholder="Enter a title"
                value={formik.values.title}
                onChange={formik.handleChange}
                isInvalid={formik.touched.title && !!formik.errors.title}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.title}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Description :</Form.Label>

              <Form.Control
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                as="textarea"
                placeholder="Enter your detail description"
                rows={3}
                isInvalid={formik.touched.description && !!formik.errors.description}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.description}
              </Form.Control.Feedback>
            </Form.Group>

            <div className={`row gap-3 ${authUser.account_type === 1 ? "d-none" : ""}`}>
              <Form.Group className="col mb-3">
                <Form.Label>Department :</Form.Label>
                <Select
                  classNamePrefix={`form-select`}
                  name="department"
                  options={staffSelectOptions?.departments}
                  value={staffSelectOptions?.departments.find(
                    el => el.value === formik.values.department
                  )}
                  onChange={({ value }) => {
                    formik.setFieldValue("department", value);

                    // clear others
                    formik.setFieldValue("jobGrade", "");
                    formik.setFieldValue("recipient", "");
                  }}
                  isDisabled={reciever?.department}
                />
              </Form.Group>

              <Form.Group className="col mb-3">
                <Form.Label>Job Grade :</Form.Label>
                <Select
                  classNamePrefix={`form-select`}
                  name="jobGrade"
                  options={staffSelectOptions?.jobGrades}
                  value={staffSelectOptions?.jobGrades.find(
                    el => el.value === formik.values.jobGrade
                  )}
                  onChange={({ value }) => {
                    formik.setFieldValue("jobGrade", value);

                    // clear others
                    formik.setFieldValue("recipient", "");
                  }}
                  isDisabled={
                    reciever?.jobGrade ||
                    reciever?.staff ||
                    reciever?.department === "Department Lead"
                  }
                />
              </Form.Group>

              <Form.Group className="col mb-3">
                <Form.Label>Recipient :</Form.Label>

                <Select
                  classNamePrefix={`form-select`}
                  name="recipient"
                  options={staffSelectOptions?.staffs}
                  value={staffSelectOptions?.staffs.find(
                    el => el.value === formik.values.recipient
                  )}
                  onChange={({ value }) => formik.setFieldValue("recipient", value)}
                  isDisabled={isFetching || reciever?.staff}
                />
              </Form.Group>
            </div>

            {authUser?.account_type === 1 ? (
              <div className="d-flex gap-3 align-items-center mb-3">
                <Button
                  variant="outline-primary"
                  type="button"
                  onClick={() => setShowStaffInviteModal(true)}
                >
                  <AccountPlusIcon /> Add Signatory
                </Button>

                <p>
                  {formik.values.recipientEmails
                    ? formik.values.recipientEmails.map(el => el).join(", ")
                    : ""}
                </p>
                <p>
                  {formik.values.selectedUsers
                    ? formik.values.selectedUsers
                        .map(el => `${el.Name} (${el.username})`)
                        .join(", ")
                    : ""}
                </p>
              </div>
            ) : null}
          </Form>

          <div className="form-area border">
            {!isFetching && !getFormQuery.isFetching ? (
              <iframe
                ref={iframeRef}
                id="Frame"
                onLoad={e => {
                  setIsLoadingIframe(false);
                  sendSetupData();
                  setOrientation({
                    orientation: getFormQuery.data.form.orientation,
                    pageSize: getFormQuery.data.form.pageSize,
                  });
                }}
                className="w-100"
                style={{ backgroundColor: "#E5E7EB", minHeight: "100vh" }}
                title="Form Builder"
                src={`${process.env.REACT_APP_FORM_BUILDER_URL}/form/fill`}
              />
            ) : null}

            {isLoadingIframe && !isFetching ? (
              <div className="global-spinner">
                <LoaderIcon className="spin text-primary" />
              </div>
            ) : null}
          </div>

          {/*   <div className="row gap-3">
            <Form.Group className="col mb-3">
              <Form.Label>Department :</Form.Label>
              <Form.Select
                name="department"
                value={formik.values.department}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.department && !!formik.errors.department
                }
              >
                <option value="">Select Recipient's Department</option>
                {departments &&
                  departments.map((el, index) => (
                    <option key={index} value={el.Department}>
                      {el.Department}
                    </option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.department}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col mb-3">
              <Form.Label>Recipient :</Form.Label>
              <Form.Select
                name="recipient"
                value={formik.values.recipient}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.recipient && !!formik.errors.recipient
                }
                disabled={isLoadingUsers}
              >
                <option value="">Select Recipient </option>
                {users &&
                  users.map((el, index) => (
                    <Fragment key={index}>
                      {el.Staff_ID !== authUser.Staff_ID && (
                        <option value={el.Staff_ID}>{el.Name}</option>
                      )}
                    </Fragment>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.recipient}
              </Form.Control.Feedback>
            </Form.Group>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={createRequisitionMutation.isLoading}
            onClick={() => setShowNewCustomRequisitionModal(false)}
            type="button"
            variant="white"
            className="border bg-white px-4"
            size="lg"
          >
            Cancel
          </Button>
          <Button
            disabled={createRequisitionMutation.isLoading}
            type="button"
            variant="primary"
            className="px-4"
            onClick={() => save()}
            size="lg"
          >
            {createRequisitionMutation.isLoading ? "Please wait..." : "Send"}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Form Selector Modal variant  */}
      <StaffInviteModal
        isMutiple={false}
        show={showStaffInviteModal}
        setShow={value => setShowStaffInviteModal(value)}
        handleSelectionCompleted={handleSelectionCompleted}
        handleSelectedEmail={handleSelectedEmail}
      />
      {/* <ModalLoader show={isFetching } /> */}
    </>
  );
}
