import React, { useEffect, useState } from "react";

function Reciept({ saveReciept, next }) {
  const [selected, setSelected] = useState("");

  const ReceiptType = [
    {
      title: "I save paper receipts",
      value: "paper",
    },
    {
      title: "I save cloud receipts",
      value: "cloud",
    },
    {
      title: "I save in paper and cloud",
      value: "cloudBook",
    },
    {
      title: "I don't save receipts",
      value: "none",
    },
  ];
  const handleSelected = (value) => {
    console.log(value);
    setSelected(value);
  };

  const submit = async () => {
    console.log("submitting receipt type...", selected);
    next();
  };

  useEffect(() => {
    saveReciept(submit);
  }, [selected]);
  return (
    <div className="contents">
      <h1>How do you keep track of your receipts today?</h1>
      <h5 className="desc">
        Saving receipt is an important part of running your business, you'll
        need them for tax time and for good record keeping.
      </h5>
      <div className="options">
        {ReceiptType.map((item, index) => (
          <div className="card" onClick={() => handleSelected(item.value)}>
            <div className="left">
              <h5 className="title">{item.title}</h5>
            </div>
            <span className="selection">
              {selected === item.value ? (
                <svg
                  className="selected"
                  height="512px"
                  id="Layer_1"
                  style={{ enableBackground: "new 0 0 512 512" }}
                  version="1.1"
                  viewBox="0 0 512 512"
                  width="512px"
                  space="preserve"
                >
                  <g>
                    <g>
                      <path d="M256,48C141.1,48,48,141.1,48,256s93.1,208,208,208c114.9,0,208-93.1,208-208S370.9,48,256,48z M256,446.7    c-105.1,0-190.7-85.5-190.7-190.7c0-105.1,85.5-190.7,190.7-190.7c105.1,0,190.7,85.5,190.7,190.7    C446.7,361.1,361.1,446.7,256,446.7z" />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M256,96c-88.4,0-160,71.6-160,160c0,88.4,71.6,160,160,160c88.4,0,160-71.6,160-160C416,167.6,344.4,96,256,96z" />
                    </g>
                  </g>
                </svg>
              ) : (
                <svg
                  height="512px"
                  id="Layer_1"
                  style={{ enableBackground: "new 0 0 512 512" }}
                  version="1.1"
                  viewBox="0 0 512 512"
                  width="512px"
                  space="preserve"
                >
                  <g>
                    <g>
                      <path d="M256,48C141.1,48,48,141.1,48,256s93.1,208,208,208c114.9,0,208-93.1,208-208S370.9,48,256,48z M256,446.7    c-105.1,0-190.7-85.5-190.7-190.7c0-105.1,85.5-190.7,190.7-190.7c105.1,0,190.7,85.5,190.7,190.7    C446.7,361.1,361.1,446.7,256,446.7z" />
                    </g>
                  </g>
                </svg>
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Reciept;
