import currency from "currency.js";
import React, { useState } from "react";
import { Table, Button, Accordion, Modal } from "react-bootstrap";

const CartStash = ({
  onHide,
  onRestoreItem,
  cartStash,
  setCartStash,
  onRestoreCart,
  currencySymbol,
}) => {
  const [show, setShow] = useState(true);

  const handleDelete = (index) => {
    // remove item from the stash
    console.log("deleting index: ", index);
    let newStash = cartStash.filter((_, i) => i !== index);
    setCartStash(newStash);
    localStorage.setItem("stash-invoice", JSON.stringify(newStash));
    // close the modal if the stash is empty
    if (newStash.length === 0) {
      onHide(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        onHide(false);
      }}
      size="lg"
      style={{
        fontSize: "18px",
      }}
      className="stash-modal mt-4 side-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="pd-4">Cart Stash</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion defaultActiveKey="0">
          {cartStash.length ? (
            cartStash.map((cart, cartIndex) => (
              <Accordion.Item eventKey={cartIndex.toString()} key={cartIndex}>
                <Accordion.Header>
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <h5>
                      {currency(cart.totalPrice, {
                        symbol: currencySymbol,
                      }).format()}
                    </h5>
                    <span>
                      <Button
                        variant="outline-primary"
                        size="md"
                        className="mx-4"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent accordion toggle
                          onRestoreCart(cart.items, cartIndex);
                        }}
                      >
                        Restore
                      </Button>
                      <Button
                        variant="outline-danger"
                        size="md"
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(cartIndex);
                        }}
                      >
                        Delete
                      </Button>
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="pd-4">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Item Name</th>
                        <th>Quantity</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart.items.map((item, itemIndex) => (
                        <tr key={itemIndex}>
                          <td>{item.Item_Name}</td>
                          <td>{item.quantity}</td>
                          <td>
                            {currency(item.SubTotal, {
                              symbol: currencySymbol,
                            }).format()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            ))
          ) : (
            <div className="text-center text-muted py-5">No stashed items.</div>
          )}
        </Accordion>
      </Modal.Body>
    </Modal>
  );
};

export default CartStash;
