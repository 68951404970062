import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Turnstile } from "@marsidev/react-turnstile";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { useAuth } from "../hooks/useAuth";
import { backendApis } from "../config";
function CloudflareRobotCheckModal(props) {
  const mutation = useMutation(token =>
    fetch(`${backendApis[0].url}/api/auth/cloudflare/siteverify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    }).then(response => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
  );

  return (
    <Modal {...props} fullscreen size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div className="w-100 h-100 gap-3 flex-column d-flex align-items-center justify-content-center">
          <Turnstile
            siteKey={process.env.REACT_APP_CLOUDFLARE_SITE_KEY}
            onError={() => toast.error("error")}
            onExpire={() => toast.error("expired")}
            onSuccess={token => {
              mutation.mutate(token, {
                onSuccess: () => {
                  toast.success("Verification successful");
                  props.onHide();
                },
                onError: () => toast.error("Verification failed"),
              });
            }}
            options={{
              theme: "auto",
            }}
          />

          {mutation.isLoading ? <p>Please wait...</p> : ""}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CloudflareRobotCheckModal;

/* function App() {


  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button>

      <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

render(<App />); */
