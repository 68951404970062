import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Button, Form, Row, Col, Dropdown, Overlay, Table, InputGroup } from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import {
  convertFromBaseCurrency,
  convertToBaseCurrency,
  copyText,
  customerFullName,
  dateIsBeforeMaxBackDate,
  initialScheduledPostingForm,
  maxTopPopperConfig,
  pcsToTons,
  qtyFormat,
  reactSelectTheme,
  scheduledFormValidation,
  tonsToPcs,
  toTonsOrPcs,
  Units,
} from "./../utils/helpers";
import {
  AddCircleIcon,
  CreateInvoiceIcon,
  NoSelectedItemIcon,
  UserSolidIcon,
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
  CustomerAccountSelectIcon,
  CreditMemoSelectIcon,
  EditIcon,
  DeleteIcon,
  ExcelIcon,
  DownloadIcon,
} from "./Icons";
import NewCustomerModal from "./NewCustomerModal";
import NewItemModal from "./NewItemModal";
import PageHeader from "./PageHeader";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import ItemsTable from "./utils/ItemsTable";
import "./../assets/scss/create-invoice.scss";
import currency from "currency.js";
import DotsVeritcalIcon from "mdi-react/DotsVerticalIcon";
import { cloneDeep, isEmpty, lowerCase } from "lodash";
import { useMemo } from "react";
import NumberCustomInput from "./utils/NumberCustomInput";
import { services } from "./../config";
import queryString from "query-string";
import { useQuery, useQueryClient } from "react-query";
import { queryActions, reportActions } from "./../utils/reactQueryActions";
import { useFormik } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import SelectBankDialog from "./SelectBankDialog";
import useDebounce, {
  useCurrencies,
  useEffectOnce,
  useIsAdmin,
  useResolveRootRoles,
  useUpdateEffect,
} from "./../utils/hooks";
import ConfirmDialog from "./ConfirmDialogue";
import { useAuth } from "./../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import ModalLoader from "./utils/ModalLoader";
import printJS from "print-js";
import CustomerSelectModal from "./CustomerSelectModal";
import DatePickerCustomInput from "./utils/DatePickerCustomInput";
import CreatableSelect from "react-select/creatable";
import ExpensesSelector from "./utils/ExpensesSelector";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { first } from "lodash";
import { last } from "lodash";
import moment from "moment";
import eventBus from "../utils/EventBus";
import { read, utils } from "xlsx";
import { IsPrivileged } from "./DisplayChildElement";
import ScheduledPostingForm from "./SalesAndInvoicing/ScheduledPostingForm";

const EditableRow = ({ expense, handleRemove, index, edit, currencySymbol }) => {
  const [showExpensesPopover, setShowExpensesPopover] = useState(false);
  const initialValues = {
    AccountID: "",
    DetailType: "",
    Type: "",
    Description: "",
    Debit: 0,
    Credit: 0,
    Remark: "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: yup.object().shape({}),
    onSubmit: values => {},
  });

  const formValues = useDebounce(formik.values, 500);

  const setUp = () => {
    formik.setValues({
      AccountID: expense.AccountID,
      DetailType: expense.DetailType,
      Type: expense.Type,
      Description: expense.Description,
      Credit: expense.Credit,
      Debit: expense.Debit,
      Remark: expense?.Remark,
    });
  };

  useEffectOnce(() => {
    setUp();
  });

  useEffect(() => {
    for (let k in initialValues) {
      if (!formValues.hasOwnProperty(k)) {
        const key = String(k);
        formValues[key] = "";
      }
    }
    edit({
      index,
      formValues,
    });
  }, [formValues]);

  const handleSelectedExpense = expense => {
    formik.setValues({
      ...formik.values,
      AccountID: expense.AccountID,
      DetailType: expense.DetailType,
      Type: expense.Type,
      Description: expense.Description,
      Remark: expense?.Remark,
    });
    setShowExpensesPopover(false);
  };

  return (
    <tr>
      <td>
        <Dropdown>
          <Dropdown.Toggle variant="" className="bg-white border-0" bsPrefix="print more">
            <DotsVeritcalIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu
            popperConfig={{
              strategy: "fixed",
            }}
            renderOnMount
            className="dropdown-with-icons"
          >
            <Dropdown.Item as="button" onClick={() => handleRemove(index)}>
              <DeleteIcon />
              Remove
            </Dropdown.Item>
          </Dropdown.Menu>{" "}
        </Dropdown>
      </td>
      <td>
        <InputGroup className="flex-nowrap">
          <Form.Control
            name="Account"
            value={`${expense.AccountID}/${expense.DetailType || ""}`}
            onChange={() => {}}
            readOnly
          />
          <Popover
            isOpen={showExpensesPopover}
            onClickOutside={() => setShowExpensesPopover(false)}
            content={() => (
              <ExpensesSelector
                handleSelectedExpense={handleSelectedExpense}
                usage={"chart of accounts"}
              />
            )}
            position="bottom"
          >
            <InputGroup.Text onClick={() => setShowExpensesPopover(true)}>
              <MagnifyIcon />
            </InputGroup.Text>
          </Popover>
        </InputGroup>
      </td>
      <td>
        <Form.Control
          name="AccountType"
          value={formik.values.Type}
          onChange={formik.handleChange}
          readOnly
        />
      </td>
      <td>
        <Form.Control
          name="Description"
          value={formik.values.Description}
          onChange={formik.handleChange}
        />
      </td>

      <td>
        <CurrencyCustomInput
          currencySymbol={currencySymbol}
          name="Debit"
          value={formik.values.Debit}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
        />
      </td>
      <td>
        <CurrencyCustomInput
          currencySymbol={currencySymbol}
          name="Credit"
          value={formik.values.Credit}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
        />
      </td>
      <td>
        <Form.Control
          className="h-auto col-6 table-textarea"
          as="textarea"
          rows={4}
          name="Remark"
          value={formik.values.Remark}
          onChange={formik.handleChange}
          style={{ width: "25rem" }}
        />
      </td>
    </tr>
  );
};

export default function JournalEntry({ type }) {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const defaultCustomer = useStoreState(state => state.defaultCustomer);
  const { backendUrl } = useAuth();
  const [showExpensesPopover, setShowExpensesPopover] = useState(false);
  const { deploymentCurrencies: currenciesOptions } = useCurrencies();

  const generalSettings = useStoreState(state => state.generalSettings);
  const [tableData, setTableData] = useState([]);
  const isAdmin = useIsAdmin();

  const { isPrivileged } = useResolveRootRoles();
  const requiresApproval = useMemo(
    () => isPrivileged({ roleName: "Journal", parent: "Posting Requires Approval" }),
    [isPrivileged]
  );

  useEffectOnce(() => {
    if (location.state && location.state?.expenses) {
      setTableData(location.state.expenses);
      navigate(location.pathname, { replace: true });
    }
    if (location.state && location.state?.transactionData) {
      const { invoice, ...others } = location.state?.transactionData;
      const journals = JSON.parse(invoice).journals;

      setTableData(journals);

      formik.setValues({
        ...formik.values,
        ...journals[0],
        ...others,
        schedulePosting: true,
      });
    }
  });

  const totalDebit = useMemo(
    () => tableData.map(el => el.Debit).reduce((a, b) => currency(a).add(b).value, 0),
    [tableData]
  );

  const totalCredit = useMemo(
    () => tableData.map(el => el.Credit).reduce((a, b) => currency(a).add(b).value, 0),
    [tableData]
  );

  const postJournalEntry = async payload => {
    if (payload.currency && payload.currency !== generalSettings?.prevailingCurrency) {
      payload = convertToBaseCurrency({
        data: payload,
        conversionAmount: payload.conversionAmount,
      });
    }

    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    let response = await fetch(`${backendUrl}/api/journal/journal-entry/create`, {
      method: "POST",
      credentials: "include",
      body: formData,
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const postJournalEntryMutation = useMutation(
    payload => {
      if (formik.values.schedulePosting) {
        return scheduleTransaction(payload);
      }
      return postJournalEntry(payload);
    },
    {
      onSuccess: ({ data, message }) => {
        toast.success(message);
        if (formik.values.id) return;
        setTableData([]);
        formik.setFieldValue("Reference", `STN${Date.now()}`);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      Reference: "",
      salesDate: moment(),
      conversionAmount: generalSettings.dollarInBaseCurrency,
      currency: generalSettings?.prevailingCurrency,
      ...initialScheduledPostingForm,
    },
    validationSchema: yup.object().shape({
      Reference: yup.string().required("required"),
      ...scheduledFormValidation({ yup }),
    }),
    onSubmit: async values => {
      values = cloneDeep(values);
      if (isEmpty(tableData)) return toast.error(`Please select`);

      // Validate max ===================================================================
      const isDateBeforeMaxBackDate = dateIsBeforeMaxBackDate({
        dateSelected: values.salesDate,
        maxBackDateDurationNumber: generalSettings.maxBackDateDurationNumber,
        maxBackDateCustom: generalSettings.maxBackDateCustom,
        maxBackDateDurationValue: generalSettings.maxBackDateDurationValue,
      });

      if (!Boolean(isDateBeforeMaxBackDate)) {
        return toast.error("Cannot back date, contact Admin");
      }
      // ================================================================================

      // send to pending
      if (
        await ConfirmDialog({
          title: "Post Transaction",
          description: "Are you sure, you want to make this transaction",
        })
      ) {
        const journals = cloneDeep(tableData).map(el => ({
          ...el,
          ...values,
          ...(type === "journal-modification" ? { isApproved: true } : {}),
        }));

        // Ensure DEBIT AND CREDIT MATCH -------------------------------
        if (type !== "journal-modification") {
          if (journals.length == 1) {
            return toast.error(`Please enter the opposite account`);
          }

          if (Number(totalDebit) !== Number(totalCredit)) {
            return toast.error(
              `Total Debit: ${currency(totalDebit, {
                symbol: "",
              }).format()}  does not match \n Total Credit: ${currency(totalCredit, {
                symbol: "",
              }).format()}`
            );
          }
        }

        postJournalEntryMutation.mutate({
          journals,
          currency: values.currency,
          conversionAmount: values.conversionAmount,
          requiresApproval: requiresApproval && !formik.values.schedulePosting,
        });
      }
    },
    onReset: () => {
      setTableData([]);
    },
  });

  useEffectOnce(() => {
    formik.setFieldValue("Reference", `STN${Date.now()}`);
  });

  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/invoice/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.vendors = [
      {
        value: "",
        label: "One-time vendor",
      },
      ...data?.vendors.map(el => ({
        label: el.CompanyName,
        value: el.Vendor_ID,
      })),
    ];

    data.banks = data?.banks
      .filter(el => el?.BankName)
      .map(el => ({
        ...el,
        label: `${el.BankName} ${el?.currency ? `(${el?.currency})` : `(NGN)`}`,
        value: el.BankName,
      }));

    data.branch = [
      {
        value: "General",
        label: "General",
      },
    ];
    return data;
  };

  const { data = { banks: [], vendors: [], branch: [] } } = useQuery(
    [queryActions.INVOICE_SETUP],
    () => fetchSetUpData(),
    {
      keepPreviousData: true,
    }
  );

  const handleSelectedExpense = expense => {
    // balance Table
    if (!isEmpty(tableData)) {
      const lastItem = last(tableData);
      console.log(
        lastItem.Credit,
        Number(lastItem.Credit) > 0,
        lastItem.Debit,
        Number(lastItem.Debit) > 0
      );

      expense.Credit = Number(lastItem.Credit) > 0 ? 0 : lastItem.Debit;
      expense.Debit = Number(lastItem.Debit) > 0 ? 0 : lastItem.Credit;
    } else {
      expense.Credit = 0;
      expense.Debit = 0;
    }

    //  console.log(expense);

    setTableData([...tableData, expense]);
    setShowExpensesPopover(false);
  };

  eventBus.useCustomEventListener("JOURNAL_ACCOUNT_CREATED", account => {
    handleSelectedExpense(account);
  });

  const edit = ({ index, formValues }) => {
    tableData[index] = {
      ...tableData[index],
      ...formValues,
    };
    setTableData([...tableData]);
  };

  const handleRemove = index => {
    setTableData([...tableData.filter((el, i) => i !== index)]);
  };

  const discard = () => {
    formik.resetForm();
    setTableData([]);
    formik.setFieldValue("Reference", `STN${Date.now()}`);
  };

  const currencySymbol = useMemo(() => {
    const foundCurrency = currenciesOptions.find(el => el.cc === formik.values.currency);
    return foundCurrency ? foundCurrency.symbol : "";
  }, [formik.values.currency]);

  function handleFile({ eventData: e, type }) {
    /*  toast.info("Service not Available");
    return; */
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async function(e) {
        const workbook = read(e.target.result, {
          cellDates: true,
          dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
        });
        let rows = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
          defval: "",
          dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
        });

        //--
        if (
          !(await ConfirmDialog({
            title: "Are you sure?",
            description: "You want to import",
          }))
        ) {
          return;
        }

        let schema = yup.object().shape({
          items: yup.array().of(
            yup.object({
              Account_ID: yup.string().required(),
              Account_Name: yup.string().required(),
              // Description: yup.string().required(),
              Account_Type: yup.string().required(),
              Type_Detail: yup.string().required(),
              Remark: yup.string().required(),
            })
          ),
        });
        try {
          await schema.validate({ items: rows });

          // Clean Up
          rows = rows.map(el => ({
            AccountID: String(el.Account_ID),
            Description: el.Account_Name,
            Account_Desc: el.Account_Name,
            Type: el.Account_Type.toUpperCase(),
            DetailType: el.Type_Detail.toUpperCase(),
            Debit: el.Debit,
            Credit: el.Credit,
            Remark: el.Remark,
          }));

          setTableData(rows);
        } catch (err) {
          console.log(err);
          toast.error(err.name);
          toast.error(JSON.stringify(err.errors));
        }
      };
      reader.readAsArrayBuffer(file);
    }
    e.target.value = "";
  }

  const scheduleTransaction = async payload => {
    if (payload.currency && payload.currency !== generalSettings?.prevailingCurrency) {
      payload = convertToBaseCurrency({
        data: payload,
        conversionAmount: payload.conversionAmount,
      });
    }

    const formData = new FormData();
    if (formik.values.id) {
      payload.id = formik.values?.id;
    }

    formData.append("payload", JSON.stringify(payload));

    let response = await fetch(
      `${backendUrl}/api/journal/journal-entry/create-reocurring-invoice`,
      {
        method: "POST",
        credentials: "include",
        body: formData,
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  return (
    <IsPrivileged roleName="Journal">
      <main className="create-invoice">
        <PageHeader
          name={
            type === "journal-modification"
              ? "GL Opening Balance "
              : `Journal Entry ${formik.values?.id ? "(Edit)" : ""}`
          }
          description=""
          icon={<CreateInvoiceIcon />}
        />
        <div className="p-3 content">
          <div className="d-md-flex content-holder rounded">
            <section
              /*  style={
              formik.values.pendingTransaction
                ? {
                    pointerEvents: "none",
                  }
                : {}
            } */
              className="item-details"
            >
              <div>
                {/*  <header>
                <h1>Bill Payment</h1>
              </header> */}
                <div className="actions">
                  <div>
                    <h1 className="h4">
                      {type === "journal-modification" ? "GL Opening Balance " : "Journal Entry"}{" "}
                    </h1>
                  </div>

                  <div className="d-flex gap-3">
                    <Button as="label" title="Import" className="btn btn-print border" variant="">
                      <input
                        type="file"
                        className="d-none"
                        onChange={e => handleFile({ eventData: e })}
                        accept=".xlsx"
                      />
                      <ExcelIcon color="#008000" /> Import
                    </Button>

                    <a
                      href={`/excel_templates/INVEX_INSERT_JOURNAL_ENTRY_TEMPLATE.xlsx`}
                      target="_blank"
                      title="Download"
                      download=""
                      className="btn btn-print border"
                    >
                      <span>
                        <DownloadIcon /> Template
                      </span>
                    </a>

                    <Form.Group>
                      <Select
                        classNamePrefix="form-select"
                        placeholder="Select Currency"
                        isSearchable={false}
                        options={currenciesOptions}
                        value={currenciesOptions.find(el => el.value === formik.values.currency)}
                        onChange={({ value }) => formik.setFieldValue("currency", value)}
                      />
                    </Form.Group>

                    {formik.values.currency &&
                    formik.values.currency !== generalSettings?.prevailingCurrency ? (
                      <Form.Group>
                        <CurrencyCustomInput
                          currencySymbol={"Ex. Rate"}
                          name="conversionAmount"
                          value={formik.values.conversionAmount}
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value);
                          }}
                          placeholder="0.00"
                        />
                      </Form.Group>
                    ) : null}
                  </div>
                </div>

                <div className="selected-data-area">
                  <div className="table-holder">
                    <Table
                      responsive
                      borderless
                      hover
                      striped
                      className="product-table text-nowrap with-large-textarea"
                    >
                      <thead>
                        <tr>
                          <th />
                          <th>GL/Accounts</th>
                          <th>Account Type</th>
                          <th>Description</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th>Remark</th>
                        </tr>
                      </thead>
                      <tbody key={tableData?.length}>
                        {tableData.map((el, index) => (
                          <React.Fragment key={index}>
                            <EditableRow
                              index={index}
                              expense={el}
                              handleRemove={handleRemove}
                              edit={edit}
                              currencySymbol={currencySymbol}
                            />
                          </React.Fragment>
                        ))}
                        <tr style={{ backgroundColor: "#F7f7f7" }}>
                          <td colSpan={7}>
                            <Popover
                              isOpen={showExpensesPopover}
                              onClickOutside={() => setShowExpensesPopover(false)}
                              content={() => (
                                <ExpensesSelector
                                  handleSelectedExpense={handleSelectedExpense}
                                  usage={"chart of accounts"}
                                  onHide={() => setShowExpensesPopover(false)}
                                />
                              )}
                              position="bottom"
                            >
                              <Button
                                variant="outline-primary"
                                className="px-3"
                                onClick={() => setShowExpensesPopover(true)}
                              >
                                + Add
                              </Button>
                            </Popover>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>

                  {isEmpty(tableData) ? (
                    <div className="no-item my-4">
                      <div className="info">
                        <NoSelectedItemIcon />
                        <h2 className="mb-2">Haven't selected an item yet</h2>
                        <p>You can click + Add to add an item to the table.</p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="d-flex justify-content-end total-info">
                <table className="table table-borderless balance">
                  <tbody>
                    <tr>
                      <td>Total Debit</td>
                      <td>
                        {currency(totalDebit, {
                          symbol: currencySymbol,
                        }).format()}
                      </td>
                    </tr>
                    <tr>
                      <td>Total Credit</td>
                      <td>
                        {currency(totalCredit, {
                          symbol: currencySymbol,
                        }).format()}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section className="customer">
              <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
                <section className="date">
                  <h2 className="mb-4">Payment Details</h2>

                  <Form.Group className="form-mb align-items-center">
                    <Form.Label>Date</Form.Label>
                    <Datetime
                      timeFormat={false}
                      closeOnSelect={true}
                      closeOnClickOutside={true}
                      dateFormat="MMM DD, YYYY"
                      name="salesDate"
                      inputProps={{
                        className: `date-input form-control ${
                          formik.touched.salesDate && !!formik.errors.salesDate ? "is-invalid" : ""
                        }`,
                        placeholder: "Select date",
                        readOnly: true,
                      }}
                      value={formik.values.salesDate}
                      onChange={date => {
                        formik.setFieldValue("salesDate", date, true);
                      }}
                      onBlur={() => formik.setFieldTouched("salesDate", true)}
                    />
                  </Form.Group>
                </section>

                <ScheduledPostingForm formik={formik} />

                {/*  <Form.Group className="form-mb align-items-center">
                  <Form.Label>Branch</Form.Label>
                  <Select
                    classNamePrefix="form-select"
                    menuPlacement="top"
                    placeholder="HQ"
                    isSearchable={false}
                    value={formik.values.branch}
                    onChange={(option) => formik.setFieldValue("branch", option)}
                  />
                </Form.Group> */}

                <Form.Group className="form-mb align-items-center">
                  <Form.Label>Reference</Form.Label>
                  <Form.Control
                    name="Reference"
                    value={formik.values.Reference}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.Reference && !!formik.errors.Reference}
                    readOnly
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.Reference}
                  </Form.Control.Feedback>
                </Form.Group>

                <section className="buttons">
                  <Button
                    type="button"
                    variant="outline-primary"
                    // className="border-0"
                    onClick={() => discard()}
                  >
                    Refresh
                  </Button>
                  <Button type="submit" variant="primary">
                    {requiresApproval && !isAdmin && !formik.values.schedulePosting
                      ? "Send for approval"
                      : formik.values.schedulePosting
                      ? "Schedule"
                      : "Post Now"}
                  </Button>
                </section>
              </Form>
            </section>
          </div>
        </div>

        {/*   Modals */}

        {/*  {showCustomerSelectorModal && (
        <CustomerSelectModal
          setShowCustomerSelectorModal={setShowCustomerSelectorModal}
          setSelectedCustomer={setSelectedCustomer}
          selectedCustomer={selectedCustomer}
          withCredit={true}
        />
      )}



      {showCreateNewCustomerModal && (
        <NewCustomerModal
          showCreateNewCustomerModal={showCreateNewCustomerModal}
          setShowCreateNewCustomerModal={setShowCreateNewCustomerModal}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}

      {editedItemIndex !== null && (
        <EditIronRodItemModal
          setEditedItemIndex={setEditedItemIndex}
          handleEditItem={handleEditItem}
          selectedItemToEdit={{
            ...tableData[editedItemIndex],
            //  quantityInStock: tableData[editedItemIndex].Quantity,
          }}
          saleTypes={saleTypes}
        />
      )}

      {showPermitModal && (
        <PermitModal
          setShowPermitModal={setShowPermitModal}
          handleSelectedPermit={handleSelectedPermit}
          batchData={true}
        />
      )}

    */}
        <ModalLoader show={postJournalEntryMutation.isLoading} />
      </main>
    </IsPrivileged>
  );
}
