import { useState } from "react";
import { Tooltip, OverlayTrigger, Dropdown } from "react-bootstrap";
import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";

export function Component(props) {
  //given that props.story contains 'PhotoStory' or 'VideoStory'
  const SpecificComponent = props.component;
  return <SpecificComponent {...props} />;
}

export function FormSwitch() {
  return "switch";
}

export function OverlayTooltip({ text, component }) {
  const renderTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  return (
    <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
      {component}
    </OverlayTrigger>
  );
}

export const HoverDropDown = props => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleMouseOver = () => {
    setDropdownOpen(true);
  };

  const handleMouseOut = () => {
    setDropdownOpen(false);
  };

  return (
    <Dropdown
      {...props}
      show={dropdownOpen}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {props.children}
    </Dropdown>
  );
};

export const OverflowDropdown = ({ toggle, menu }) => {
  const [show, setShow] = useState(false);

  const menuContainer = useRef(document.createElement("div"));

  React.useEffect(() => {
    document.body.appendChild(menuContainer.current);
    return () => {
      document.body.removeChild(menuContainer.current);
    };
  }, []);

  const handleMouseEnter = () => setShow(true);
  const handleMouseLeave = () => setShow(false);

  const clonedMenu = menu
    ? React.cloneElement(menu, {
        as: CustomMenu,
        container: menuContainer.current,
        style: { marginLeft: "-8px" }, // Add your custom styles here
      })
    : null;

  return (
    <div
      style={{
        overflow: "hidden",
        width: "100%",
        height: "fit-content",
        position: "relative",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="mouse-area"
    >
      <Dropdown
        show={show}
        onToggle={() => {}}
        alignRight
        drop="end"
        className="w-100"
        autoClose="outside"
      >
        {toggle}
        {clonedMenu}
      </Dropdown>
    </div>
  );
};

const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    return ReactDOM.createPortal(
      <div
        ref={ref}
        style={{ ...style, position: "absolute" }}
        className={className}
        aria-labelledby={labeledBy}
      >
        {children}
      </div>,
      document.body
    );
  }
);

export const StarCheckbox = ({ checked, setChecked }) => {
  const handleCheckboxChange = event => {
    setChecked(event.target.checked);
  };

  return (
    <div style={{ display: "inline-block", position: "relative" }}>
      {/* Hidden checkbox input */}
      <input
        type="checkbox"
        id="star-checkbox"
        checked={checked}
        onChange={handleCheckboxChange}
        style={{
          position: "absolute",
          opacity: 0,
          width: "100%",
          height: "100%",
          cursor: "pointer",
        }}
        aria-label="Star checkbox"
      />

      {/* Custom checkbox visuals */}
      <label
        htmlFor="star-checkbox"
        style={{
          display: "inline-block",
          cursor: "pointer",
        }}
        title={!checked ? "Not Starred" : ""}
      >
        {checked ? (
          // Checked SVG
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
          >
            <path
              d="M9.53834 1.10996C9.70914 0.699318 10.2909 0.699318 10.4617 1.10996L12.5278 6.07744C12.5998 6.25056 12.7626 6.36885 12.9495 6.38383L18.3123 6.81376C18.7556 6.8493 18.9354 7.40256 18.5976 7.69189L14.5117 11.1919C14.3693 11.3139 14.3071 11.5053 14.3506 11.6876L15.5989 16.9208C15.7021 17.3534 15.2315 17.6954 14.8519 17.4635L10.2606 14.6592C10.1006 14.5615 9.89938 14.5615 9.73937 14.6592L5.14806 17.4635C4.76851 17.6954 4.29788 17.3534 4.40108 16.9208L5.64939 11.6876C5.69289 11.5053 5.6307 11.3139 5.48831 11.1919L1.40241 7.69189C1.06464 7.40256 1.24441 6.8493 1.68773 6.81376L7.05054 6.38383C7.23744 6.36885 7.40024 6.25056 7.47225 6.07744L9.53834 1.10996Z"
              fill="#F4C867"
              stroke="#F4C867"
            />
          </svg>
        ) : (
          // Unchecked SVG
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
          >
            <path
              d="M9.53834 1.10996C9.70914 0.699318 10.2909 0.699318 10.4617 1.10996L12.5278 6.07744C12.5998 6.25056 12.7626 6.36885 12.9495 6.38383L18.3123 6.81376C18.7556 6.8493 18.9354 7.40256 18.5976 7.69189L14.5117 11.1919C14.3693 11.3139 14.3071 11.5053 14.3506 11.6876L15.5989 16.9208C15.7021 17.3534 15.2315 17.6954 14.8519 17.4635L10.2606 14.6592C10.1006 14.5615 9.89938 14.5615 9.73937 14.6592L5.14806 17.4635C4.76851 17.6954 4.29788 17.3534 4.40108 16.9208L5.64939 11.6876C5.69289 11.5053 5.6307 11.3139 5.48831 11.1919L1.40241 7.69189C1.06464 7.40256 1.24441 6.8493 1.68773 6.81376L7.05054 6.38383C7.23744 6.36885 7.40024 6.25056 7.47225 6.07744L9.53834 1.10996Z"
              fill="#F4F7FA"
              stroke="#5d696f"
            />
          </svg>
        )}
      </label>
    </div>
  );
};
