// import { Formik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import { useFormik } from "formik";
import { initialGeneralSettings, waitFor } from "../../utils/helpers";
// import { setDefaultLocale } from "react-datepicker";
import { backendApis, appSettings, IS_HR } from "../../config";
import { useStoreActions } from "easy-peasy";
import { useEffectOnce } from "../../utils/hooks";
import { loginHRSite } from "../../utils/loginHRSite";
import { useLocalStorage } from "../../utils/hooks";

import FingerprintJS from "@fingerprintjs/fingerprintjs";
import platform from "platform";

const options = backendApis
  .map((el) => ({
    ...el,
    value: el.name,
    label: el.name,
  }))
  .filter((el) => !el.viewOnly);

export default function LogUserIn({ userData, saveUserLoggedIn }) {
  const [user, setUser] = useLocalStorage("user", null);
  const queryClient = useQueryClient();
  const setItemMeasurements = useStoreActions(
    (actions) => actions.setItemMeasurements
  );
  const setGeneralSettings = useStoreActions(
    (actions) => actions.setGeneralSettings
  );
  let [loginAction, setLoginAction] = useState("login");
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [deviceID, setDeviceID] = useState("");

  const initialValues = {
    username: "username",
    password: "password",
    company: "Bua Cement",
    rememberMe: true,
  };

  const getFingerprint = async () => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    return result.visitorId; // This is a unique identifier for the device
  };

  useEffect(() => {
    getFingerprint().then((deviceId) => {
      setDeviceID(deviceId);
    });
  }, []);

  const schema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required(),
    company: yup.string().required(),
  });

  const loginUser = async (values) => {
    let body = JSON.stringify({
      ...values,
      overwriteToken: values.overwriteToken,
      adminOnly: appSettings.adminOnly,
      deviceId: deviceID,
      deviceInfo: deviceInfo,
    });
    // await waitFor(5000);
    const backendApi = backendApis.find(
      (el) => el.name === formik.values.company
    );
    let response = await fetch(`${backendApi.url}/api/auth/initiate-login`, {
      method: "POST",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      body,
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error(response.message);
    }
    const output = await response.json();
    // check if the response is login to determine weather to log the user in or ask for OTP
    if (output.success && output.status === "login") {
      setLoginAction("login");
      return output;
    }
  };

  const { status, error, mutate, isLoading } = useMutation(
    ["LOGIN"],
    (values) => {
      if (loginAction === "login") return loginUser(values);
    },
    {
      enabled: false,
      onSuccess: async (data) => {
        if (!data) return;
        queryClient.removeQueries("LOGIN");
        await waitFor(50);
        setUser({
          ...data.user,
          company: formik.values.company,
        });
        console.log("Login successful!");

        const backendApi = backendApis.find(
          (el) => el.name === formik.values.company
        );

        data.user.password = formik.values.password;
        IS_HR && loginHRSite({ user: data.user, backendApi });
        setItemMeasurements(data.itemMeasurements);
        setGeneralSettings({ ...initialGeneralSettings, ...data.settings });
      },
    }
  );

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      let value = {
        username: userData.email,
        firstUserToken: userData.Staff_ID,
        password: initialValues.password,
        company: initialValues.company,
        rememberMe: initialValues.rememberMe,
      };
      mutate(value);
    },
  });

  useEffectOnce(() => {
    if (options && options.length === 1) {
      formik.setFieldValue("company", options[0].value);
    }

    const { name, version, os, manufacturer } = platform;
    let operatingSystem = os
      ?.toString()
      .split(" ")
      .slice(0, -1)
      .join(" ");
    let browser = `${name} ${version.split(".")[0]}`;

    setDeviceInfo(`${browser} on ${operatingSystem}`);
    saveUserLoggedIn(formik.submitForm);
  });
}
