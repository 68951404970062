// export const currencies = [
//   { cc: "AED", symbol: "\u062f.\u0625;", name: "UAE dirham" },
//   { cc: "AFN", symbol: "Afs", name: "Afghan afghani" },
//   { cc: "ALL", symbol: "L", name: "Albanian lek" },
//   { cc: "AMD", symbol: "AMD", name: "Armenian dram" },
//   { cc: "ANG", symbol: "NA\u0192", name: "Netherlands Antillean gulden" },
//   { cc: "AOA", symbol: "Kz", name: "Angolan kwanza" },
//   { cc: "ARS", symbol: "$", name: "Argentine peso" },
//   { cc: "AUD", symbol: "$", name: "Australian dollar" },
//   { cc: "AWG", symbol: "\u0192", name: "Aruban florin" },
//   { cc: "AZN", symbol: "AZN", name: "Azerbaijani manat" },
//   {
//     cc: "BAM",
//     symbol: "KM",
//     name: "Bosnia and Herzegovina konvertibilna marka",
//   },
//   { cc: "BBD", symbol: "Bds$", name: "Barbadian dollar" },
//   { cc: "BDT", symbol: "\u09f3", name: "Bangladeshi taka" },
//   { cc: "BGN", symbol: "BGN", name: "Bulgarian lev" },
//   { cc: "BHD", symbol: ".\u062f.\u0628", name: "Bahraini dinar" },
//   { cc: "BIF", symbol: "FBu", name: "Burundi franc" },
//   { cc: "BMD", symbol: "BD$", name: "Bermudian dollar" },
//   { cc: "BND", symbol: "B$", name: "Brunei dollar" },
//   { cc: "BOB", symbol: "Bs.", name: "Bolivian boliviano" },
//   { cc: "BRL", symbol: "R$", name: "Brazilian real" },
//   { cc: "BSD", symbol: "B$", name: "Bahamian dollar" },
//   { cc: "BTN", symbol: "Nu.", name: "Bhutanese ngultrum" },
//   { cc: "BWP", symbol: "P", name: "Botswana pula" },
//   { cc: "BYR", symbol: "Br", name: "Belarusian ruble" },
//   { cc: "BZD", symbol: "BZ$", name: "Belize dollar" },
//   { cc: "CAD", symbol: "$", name: "Canadian dollar" },
//   { cc: "CDF", symbol: "F", name: "Congolese franc" },
//   { cc: "CHF", symbol: "Fr.", name: "Swiss franc" },
//   { cc: "CLP", symbol: "$", name: "Chilean peso" },
//   { cc: "CNY", symbol: "\u00a5", name: "Chinese/Yuan renminbi" },
//   { cc: "COP", symbol: "Col$", name: "Colombian peso" },
//   { cc: "CRC", symbol: "\u20a1", name: "Costa Rican colon" },
//   { cc: "CUC", symbol: "$", name: "Cuban peso" },
//   { cc: "CVE", symbol: "Esc", name: "Cape Verdean escudo" },
//   { cc: "CZK", symbol: "K\u010d", name: "Czech koruna" },
//   { cc: "DJF", symbol: "Fdj", name: "Djiboutian franc" },
//   { cc: "DKK", symbol: "Kr", name: "Danish krone" },
//   { cc: "DOP", symbol: "RD$", name: "Dominican peso" },
//   { cc: "DZD", symbol: "\u062f.\u062c", name: "Algerian dinar" },
//   { cc: "EEK", symbol: "KR", name: "Estonian kroon" },
//   { cc: "EGP", symbol: "\u00a3", name: "Egyptian pound" },
//   { cc: "ERN", symbol: "Nfa", name: "Eritrean nakfa" },
//   { cc: "ETB", symbol: "Br", name: "Ethiopian birr" },
//   { cc: "EUR", symbol: "\u20ac", name: "European Euro" },
//   { cc: "FJD", symbol: "FJ$", name: "Fijian dollar" },
//   { cc: "FKP", symbol: "\u00a3", name: "Falkland Islands pound" },
//   { cc: "GBP", symbol: "\u00a3", name: "British pound" },
//   { cc: "GEL", symbol: "GEL", name: "Georgian lari" },
//   { cc: "GHS", symbol: "GH\u20b5", name: "Ghanaian cedi" },
//   { cc: "GIP", symbol: "\u00a3", name: "Gibraltar pound" },
//   { cc: "GMD", symbol: "D", name: "Gambian dalasi" },
//   { cc: "GNF", symbol: "FG", name: "Guinean franc" },
//   { cc: "GQE", symbol: "CFA", name: "Central African CFA franc" },
//   { cc: "GTQ", symbol: "Q", name: "Guatemalan quetzal" },
//   { cc: "GYD", symbol: "GY$", name: "Guyanese dollar" },
//   { cc: "HKD", symbol: "HK$", name: "Hong Kong dollar" },
//   { cc: "HNL", symbol: "L", name: "Honduran lempira" },
//   { cc: "HRK", symbol: "kn", name: "Croatian kuna" },
//   { cc: "HTG", symbol: "G", name: "Haitian gourde" },
//   { cc: "HUF", symbol: "Ft", name: "Hungarian forint" },
//   { cc: "IDR", symbol: "Rp", name: "Indonesian rupiah" },
//   { cc: "ILS", symbol: "\u20aa", name: "Israeli new sheqel" },
//   { cc: "INR", symbol: "\u20B9", name: "Indian rupee" },
//   { cc: "IQD", symbol: "\u062f.\u0639", name: "Iraqi dinar" },
//   { cc: "IRR", symbol: "IRR", name: "Iranian rial" },
//   { cc: "ISK", symbol: "kr", name: "Icelandic kr\u00f3na" },
//   { cc: "JMD", symbol: "J$", name: "Jamaican dollar" },
//   { cc: "JOD", symbol: "JOD", name: "Jordanian dinar" },
//   { cc: "JPY", symbol: "\u00a5", name: "Japanese yen" },
//   { cc: "KES", symbol: "KSh", name: "Kenyan shilling" },
//   { cc: "KGS", symbol: "\u0441\u043e\u043c", name: "Kyrgyzstani som" },
//   { cc: "KHR", symbol: "\u17db", name: "Cambodian riel" },
//   { cc: "KMF", symbol: "KMF", name: "Comorian franc" },
//   { cc: "KPW", symbol: "W", name: "North Korean won" },
//   { cc: "KRW", symbol: "W", name: "South Korean won" },
//   { cc: "KWD", symbol: "KWD", name: "Kuwaiti dinar" },
//   { cc: "KYD", symbol: "KY$", name: "Cayman Islands dollar" },
//   { cc: "KZT", symbol: "T", name: "Kazakhstani tenge" },
//   { cc: "LAK", symbol: "KN", name: "Lao kip" },
//   { cc: "LBP", symbol: "\u00a3", name: "Lebanese lira" },
//   { cc: "LKR", symbol: "Rs", name: "Sri Lankan rupee" },
//   { cc: "LRD", symbol: "L$", name: "Liberian dollar" },
//   { cc: "LSL", symbol: "M", name: "Lesotho loti" },
//   { cc: "LTL", symbol: "Lt", name: "Lithuanian litas" },
//   { cc: "LVL", symbol: "Ls", name: "Latvian lats" },
//   { cc: "LYD", symbol: "LD", name: "Libyan dinar" },
//   { cc: "MAD", symbol: "MAD", name: "Moroccan dirham" },
//   { cc: "MDL", symbol: "MDL", name: "Moldovan leu" },
//   { cc: "MGA", symbol: "FMG", name: "Malagasy ariary" },
//   { cc: "MKD", symbol: "MKD", name: "Macedonian denar" },
//   { cc: "MMK", symbol: "K", name: "Myanma kyat" },
//   { cc: "MNT", symbol: "\u20ae", name: "Mongolian tugrik" },
//   { cc: "MOP", symbol: "P", name: "Macanese pataca" },
//   { cc: "MRO", symbol: "UM", name: "Mauritanian ouguiya" },
//   { cc: "MUR", symbol: "Rs", name: "Mauritian rupee" },
//   { cc: "MVR", symbol: "Rf", name: "Maldivian rufiyaa" },
//   { cc: "MWK", symbol: "MK", name: "Malawian kwacha" },
//   { cc: "MXN", symbol: "$", name: "Mexican peso" },
//   { cc: "MYR", symbol: "RM", name: "Malaysian ringgit" },
//   { cc: "MZM", symbol: "MTn", name: "Mozambican metical" },
//   { cc: "NAD", symbol: "N$", name: "Namibian dollar" },
//   {
//     cc: "NGN",
//     symbol: "\u20a6",
//     //  name: "Naira",
//     fraction: "Kobo",
//     name: "Nigerian naira",
//   },
//   { cc: "NIO", symbol: "C$", name: "Nicaraguan c\u00f3rdoba" },
//   { cc: "NOK", symbol: "kr", name: "Norwegian krone" },
//   { cc: "NPR", symbol: "NRs", name: "Nepalese rupee" },
//   { cc: "NZD", symbol: "NZ$", name: "New Zealand dollar" },
//   { cc: "OMR", symbol: "OMR", name: "Omani rial" },
//   { cc: "PAB", symbol: "B./", name: "Panamanian balboa" },
//   { cc: "PEN", symbol: "S/.", name: "Peruvian nuevo sol" },
//   { cc: "PGK", symbol: "K", name: "Papua New Guinean kina" },
//   { cc: "PHP", symbol: "\u20b1", name: "Philippine peso" },
//   { cc: "PKR", symbol: "Rs.", name: "Pakistani rupee" },
//   { cc: "PLN", symbol: "z\u0142", name: "Polish zloty" },
//   { cc: "PYG", symbol: "\u20b2", name: "Paraguayan guarani" },
//   { cc: "QAR", symbol: "QR", name: "Qatari riyal" },
//   { cc: "RON", symbol: "L", name: "Romanian leu" },
//   { cc: "RSD", symbol: "din.", name: "Serbian dinar" },
//   { cc: "RUB", symbol: "R", name: "Russian ruble" },
//   { cc: "SAR", symbol: "SR", name: "Saudi riyal" },
//   { cc: "SBD", symbol: "SI$", name: "Solomon Islands dollar" },
//   { cc: "SCR", symbol: "SR", name: "Seychellois rupee" },
//   { cc: "SDG", symbol: "SDG", name: "Sudanese pound" },
//   { cc: "SEK", symbol: "kr", name: "Swedish krona" },
//   { cc: "SGD", symbol: "S$", name: "Singapore dollar" },
//   { cc: "SHP", symbol: "\u00a3", name: "Saint Helena pound" },
//   { cc: "SLL", symbol: "Le", name: "Sierra Leonean leone" },
//   { cc: "SOS", symbol: "Sh.", name: "Somali shilling" },
//   { cc: "SRD", symbol: "$", name: "Surinamese dollar" },
//   { cc: "SYP", symbol: "LS", name: "Syrian pound" },
//   { cc: "SZL", symbol: "E", name: "Swazi lilangeni" },
//   { cc: "THB", symbol: "\u0e3f", name: "Thai baht" },
//   { cc: "TJS", symbol: "TJS", name: "Tajikistani somoni" },
//   { cc: "TMT", symbol: "m", name: "Turkmen manat" },
//   { cc: "TND", symbol: "DT", name: "Tunisian dinar" },
//   { cc: "TRY", symbol: "TRY", name: "Turkish new lira" },
//   { cc: "TTD", symbol: "TT$", name: "Trinidad and Tobago dollar" },
//   { cc: "TWD", symbol: "NT$", name: "New Taiwan dollar" },
//   { cc: "TZS", symbol: "TZS", name: "Tanzanian shilling" },
//   { cc: "UAH", symbol: "UAH", name: "Ukrainian hryvnia" },
//   { cc: "UGX", symbol: "USh", name: "Ugandan shilling" },
//   {
//     cc: "USD",
//     symbol: "$",
//     // name: "Dollar",
//     plural: "Dollars",
//     fraction: "Cent",
//     fractionPlural: "Cents",
//     name: "United States dollar",
//   },
//   { cc: "UYU", symbol: "$U", name: "Uruguayan peso" },
//   { cc: "UZS", symbol: "UZS", name: "Uzbekistani som" },
//   { cc: "VEB", symbol: "Bs", name: "Venezuelan bolivar" },
//   { cc: "VND", symbol: "\u20ab", name: "Vietnamese dong" },
//   { cc: "VUV", symbol: "VT", name: "Vanuatu vatu" },
//   { cc: "WST", symbol: "WS$", name: "Samoan tala" },
//   { cc: "XAF", symbol: "CFA", name: "Central African CFA franc" },
//   { cc: "XCD", symbol: "EC$", name: "East Caribbean dollar" },
//   { cc: "XDR", symbol: "SDR", name: "Special Drawing Rights" },
//   { cc: "XOF", symbol: "CFA", name: "West African CFA franc" },
//   { cc: "XPF", symbol: "F", name: "CFP franc" },
//   { cc: "YER", symbol: "YER", name: "Yemeni rial" },
//   { cc: "ZAR", symbol: "R", name: "South African rand" },
//   { cc: "ZMK", symbol: "ZK", name: "Zambian kwacha" },
//   { cc: "ZWR", symbol: "Z$", name: "Zimbabwean dollar" },
// ];

export const currencies = [
  {
    cc: "AED",
    symbol: "\u062f.\u0625;",
    name: "UAE dirham",
    country_code: "AE",
  },
  { cc: "AFN", symbol: "Afs", name: "Afghan afghani", country_code: "AF" },
  { cc: "ALL", symbol: "L", name: "Albanian lek", country_code: "AL" },
  { cc: "AMD", symbol: "AMD", name: "Armenian dram", country_code: "AM" },
  {
    cc: "ANG",
    symbol: "NA\u0192",
    name: "Netherlands Antillean gulden",
    country_code: "AN",
  },
  { cc: "AOA", symbol: "Kz", name: "Angolan kwanza", country_code: "AO" },
  { cc: "ARS", symbol: "$", name: "Argentine peso", country_code: "AR" },
  { cc: "AUD", symbol: "$", name: "Australian dollar", country_code: "AU" },
  { cc: "AWG", symbol: "\u0192", name: "Aruban florin", country_code: "AW" },
  { cc: "AZN", symbol: "AZN", name: "Azerbaijani manat", country_code: "AZ" },
  {
    cc: "BAM",
    symbol: "KM",
    name: "Bosnia and Herzegovina konvertibilna marka",
    country_code: "BA",
  },
  { cc: "BBD", symbol: "Bds$", name: "Barbadian dollar", country_code: "BB" },
  { cc: "BDT", symbol: "\u09f3", name: "Bangladeshi taka", country_code: "BD" },
  { cc: "BGN", symbol: "BGN", name: "Bulgarian lev", country_code: "BG" },
  {
    cc: "BHD",
    symbol: ".\u062f.\u0628",
    name: "Bahraini dinar",
    country_code: "BH",
  },
  { cc: "BIF", symbol: "FBu", name: "Burundi franc", country_code: "BI" },
  { cc: "BMD", symbol: "BD$", name: "Bermudian dollar", country_code: "BM" },
  { cc: "BND", symbol: "B$", name: "Brunei dollar", country_code: "BN" },
  { cc: "BOB", symbol: "Bs.", name: "Bolivian boliviano", country_code: "BO" },
  { cc: "BRL", symbol: "R$", name: "Brazilian real", country_code: "BR" },
  { cc: "BSD", symbol: "B$", name: "Bahamian dollar", country_code: "BS" },
  { cc: "BTN", symbol: "Nu.", name: "Bhutanese ngultrum", country_code: "BT" },
  { cc: "BWP", symbol: "P", name: "Botswana pula", country_code: "BW" },
  { cc: "BYR", symbol: "Br", name: "Belarusian ruble", country_code: "BY" },
  { cc: "BZD", symbol: "BZ$", name: "Belize dollar", country_code: "BZ" },
  { cc: "CAD", symbol: "$", name: "Canadian dollar", country_code: "CA" },
  { cc: "CDF", symbol: "F", name: "Congolese franc", country_code: "CD" },
  { cc: "CHF", symbol: "Fr.", name: "Swiss franc", country_code: "CH" },
  { cc: "CLP", symbol: "$", name: "Chilean peso", country_code: "CL" },
  {
    cc: "CNY",
    symbol: "\u00a5",
    name: "Chinese/Yuan renminbi",
    country_code: "CN",
  },
  { cc: "COP", symbol: "Col$", name: "Colombian peso", country_code: "CO" },
  {
    cc: "CRC",
    symbol: "\u20a1",
    name: "Costa Rican colon",
    country_code: "CR",
  },
  { cc: "CUC", symbol: "$", name: "Cuban peso", country_code: "CU" },
  { cc: "CVE", symbol: "Esc", name: "Cape Verdean escudo", country_code: "CV" },
  { cc: "CZK", symbol: "K\u010d", name: "Czech koruna", country_code: "CZ" },
  { cc: "DJF", symbol: "Fdj", name: "Djiboutian franc", country_code: "DJ" },
  { cc: "DKK", symbol: "Kr", name: "Danish krone", country_code: "DK" },
  { cc: "DOP", symbol: "RD$", name: "Dominican peso", country_code: "DO" },
  {
    cc: "DZD",
    symbol: "\u062f.\u062c",
    name: "Algerian dinar",
    country_code: "DZ",
  },
  { cc: "EEK", symbol: "KR", name: "Estonian kroon", country_code: "EE" },
  { cc: "EGP", symbol: "\u00a3", name: "Egyptian pound", country_code: "EG" },
  { cc: "ERN", symbol: "Nfa", name: "Eritrean nakfa", country_code: "ER" },
  { cc: "ETB", symbol: "Br", name: "Ethiopian birr", country_code: "ET" },
  { cc: "EUR", symbol: "\u20ac", name: "European Euro", country_code: "EU" },
  { cc: "FJD", symbol: "FJ$", name: "Fijian dollar", country_code: "FJ" },
  {
    cc: "FKP",
    symbol: "\u00a3",
    name: "Falkland Islands pound",
    country_code: "FK",
  },
  { cc: "GBP", symbol: "\u00a3", name: "British pound", country_code: "GB" },
  { cc: "GEL", symbol: "GEL", name: "Georgian lari", country_code: "GE" },
  { cc: "GHS", symbol: "GH\u20b5", name: "Ghanaian cedi", country_code: "GH" },
  { cc: "GIP", symbol: "\u00a3", name: "Gibraltar pound", country_code: "GI" },
  { cc: "GMD", symbol: "D", name: "Gambian dalasi", country_code: "GM" },
  { cc: "GNF", symbol: "FG", name: "Guinean franc", country_code: "GN" },
  {
    cc: "GQE",
    symbol: "CFA",
    name: "Central African CFA franc",
    country_code: "GQ",
  },
  { cc: "GTQ", symbol: "Q", name: "Guatemalan quetzal", country_code: "GT" },
  { cc: "GYD", symbol: "GY$", name: "Guyanese dollar", country_code: "GY" },
  { cc: "HKD", symbol: "HK$", name: "Hong Kong dollar", country_code: "HK" },
  { cc: "HNL", symbol: "L", name: "Honduran lempira", country_code: "HN" },
  { cc: "HRK", symbol: "kn", name: "Croatian kuna", country_code: "HR" },
  { cc: "HTG", symbol: "G", name: "Haitian gourde", country_code: "HT" },
  { cc: "HUF", symbol: "Ft", name: "Hungarian forint", country_code: "HU" },
  { cc: "IDR", symbol: "Rp", name: "Indonesian rupiah", country_code: "ID" },
  {
    cc: "ILS",
    symbol: "\u20aa",
    name: "Israeli new sheqel",
    country_code: "IL",
  },
  { cc: "INR", symbol: "\u20B9", name: "Indian rupee", country_code: "IN" },
  {
    cc: "IQD",
    symbol: "\u062f.\u0639",
    name: "Iraqi dinar",
    country_code: "IQ",
  },
  { cc: "IRR", symbol: "IRR", name: "Iranian rial", country_code: "IR" },
  { cc: "ISK", symbol: "kr", name: "Icelandic kr\u00f3na", country_code: "IS" },
  { cc: "JMD", symbol: "J$", name: "Jamaican dollar", country_code: "JM" },
  { cc: "JOD", symbol: "JOD", name: "Jordanian dinar", country_code: "JO" },
  { cc: "JPY", symbol: "\u00a5", name: "Japanese yen", country_code: "JP" },
  { cc: "KES", symbol: "KSh", name: "Kenyan shilling", country_code: "KE" },
  {
    cc: "KGS",
    symbol: "\u0441\u043e\u043c",
    name: "Kyrgyzstani som",
    country_code: "KG",
  },
  { cc: "KHR", symbol: "\u17db", name: "Cambodian riel", country_code: "KH" },
  { cc: "KMF", symbol: "KMF", name: "Comorian franc", country_code: "KM" },
  { cc: "KPW", symbol: "W", name: "North Korean won", country_code: "KP" },
  { cc: "KRW", symbol: "W", name: "South Korean won", country_code: "KR" },
  { cc: "KWD", symbol: "KWD", name: "Kuwaiti dinar", country_code: "KW" },
  {
    cc: "KYD",
    symbol: "KY$",
    name: "Cayman Islands dollar",
    country_code: "KY",
  },
  { cc: "KZT", symbol: "T", name: "Kazakhstani tenge", country_code: "KZ" },
  { cc: "LAK", symbol: "KN", name: "Lao kip", country_code: "LA" },
  { cc: "LBP", symbol: "\u00a3", name: "Lebanese lira", country_code: "LB" },
  { cc: "LKR", symbol: "Rs", name: "Sri Lankan rupee", country_code: "LK" },
  { cc: "LRD", symbol: "L$", name: "Liberian dollar", country_code: "LR" },
  { cc: "LSL", symbol: "M", name: "Lesotho loti", country_code: "LS" },
  { cc: "LTL", symbol: "Lt", name: "Lithuanian litas", country_code: "LT" },
  { cc: "LVL", symbol: "Ls", name: "Latvian lats", country_code: "LV" },
  { cc: "LYD", symbol: "LD", name: "Libyan dinar", country_code: "LY" },
  { cc: "MAD", symbol: "MAD", name: "Moroccan dirham", country_code: "MA" },
  { cc: "MDL", symbol: "MDL", name: "Moldovan leu", country_code: "MD" },
  { cc: "MGA", symbol: "FMG", name: "Malagasy ariary", country_code: "MG" },
  { cc: "MKD", symbol: "MKD", name: "Macedonian denar", country_code: "MK" },
  { cc: "MMK", symbol: "K", name: "Myanma kyat", country_code: "MM" },
  { cc: "MNT", symbol: "\u20ae", name: "Mongolian tugrik", country_code: "MN" },
  { cc: "MOP", symbol: "P", name: "Macanese pataca", country_code: "MO" },
  { cc: "MRO", symbol: "UM", name: "Mauritanian ouguiya", country_code: "MR" },
  { cc: "MUR", symbol: "Rs", name: "Mauritian rupee", country_code: "MU" },
  { cc: "MVR", symbol: "Rf", name: "Maldivian rufiyaa", country_code: "MV" },
  { cc: "MWK", symbol: "MK", name: "Malawian kwacha", country_code: "MW" },
  { cc: "MXN", symbol: "$", name: "Mexican peso", country_code: "MX" },
  { cc: "MYR", symbol: "RM", name: "Malaysian ringgit", country_code: "MY" },
  { cc: "MZM", symbol: "MTn", name: "Mozambican metical", country_code: "MZ" },
  { cc: "NAD", symbol: "N$", name: "Namibian dollar", country_code: "NA" },
  {
    cc: "NGN",
    symbol: "\u20a6",
    name: "Nigerian naira",
    fraction: "Kobo",
    country_code: "NG",
  },
  {
    cc: "NIO",
    symbol: "C$",
    name: "Nicaraguan c\u00f3rdoba",
    country_code: "NI",
  },
  { cc: "NOK", symbol: "kr", name: "Norwegian krone", country_code: "NO" },
  { cc: "NPR", symbol: "NRs", name: "Nepalese rupee", country_code: "NP" },
  { cc: "NZD", symbol: "NZ$", name: "New Zealand dollar", country_code: "NZ" },
  { cc: "OMR", symbol: "OMR", name: "Omani rial", country_code: "OM" },
  { cc: "PAB", symbol: "B./", name: "Panamanian balboa", country_code: "PA" },
  { cc: "PEN", symbol: "S/.", name: "Peruvian nuevo sol", country_code: "PE" },
  {
    cc: "PGK",
    symbol: "K",
    name: "Papua New Guinean kina",
    country_code: "PG",
  },
  { cc: "PHP", symbol: "\u20b1", name: "Philippine peso", country_code: "PH" },
  { cc: "PKR", symbol: "Rs.", name: "Pakistani rupee", country_code: "PK" },
  { cc: "PLN", symbol: "z\u0142", name: "Polish zloty", country_code: "PL" },
  {
    cc: "PYG",
    symbol: "\u20b2",
    name: "Paraguayan guarani",
    country_code: "PY",
  },
  { cc: "QAR", symbol: "QR", name: "Qatari riyal", country_code: "QA" },
  { cc: "RON", symbol: "L", name: "Romanian leu", country_code: "RO" },
  { cc: "RSD", symbol: "din.", name: "Serbian dinar", country_code: "RS" },
  { cc: "RUB", symbol: "R", name: "Russian ruble", country_code: "RU" },
  { cc: "SAR", symbol: "SR", name: "Saudi riyal", country_code: "SA" },
  {
    cc: "SBD",
    symbol: "SI$",
    name: "Solomon Islands dollar",
    country_code: "SB",
  },
  { cc: "SCR", symbol: "SR", name: "Seychellois rupee", country_code: "SC" },
  { cc: "SDG", symbol: "SDG", name: "Sudanese pound", country_code: "SD" },
  { cc: "SEK", symbol: "kr", name: "Swedish krona", country_code: "SE" },
  { cc: "SGD", symbol: "S$", name: "Singapore dollar", country_code: "SG" },
  {
    cc: "SHP",
    symbol: "\u00a3",
    name: "Saint Helena pound",
    country_code: "SH",
  },
  { cc: "SLL", symbol: "Le", name: "Sierra Leonean leone", country_code: "SL" },
  { cc: "SOS", symbol: "Sh.", name: "Somali shilling", country_code: "SO" },
  { cc: "SRD", symbol: "$", name: "Surinamese dollar", country_code: "SR" },
  { cc: "SYP", symbol: "LS", name: "Syrian pound", country_code: "SY" },
  { cc: "SZL", symbol: "E", name: "Swazi lilangeni", country_code: "SZ" },
  { cc: "THB", symbol: "\u0e3f", name: "Thai baht", country_code: "TH" },
  { cc: "TJS", symbol: "TJS", name: "Tajikistani somoni", country_code: "TJ" },
  { cc: "TMT", symbol: "m", name: "Turkmen manat", country_code: "TM" },
  { cc: "TND", symbol: "DT", name: "Tunisian dinar", country_code: "TN" },
  { cc: "TRY", symbol: "TRY", name: "Turkish new lira", country_code: "TR" },
  {
    cc: "TTD",
    symbol: "TT$",
    name: "Trinidad and Tobago dollar",
    country_code: "TT",
  },
  { cc: "TWD", symbol: "NT$", name: "New Taiwan dollar", country_code: "TW" },
  { cc: "TZS", symbol: "TZS", name: "Tanzanian shilling", country_code: "TZ" },
  { cc: "UAH", symbol: "UAH", name: "Ukrainian hryvnia", country_code: "UA" },
  { cc: "UGX", symbol: "USh", name: "Ugandan shilling", country_code: "UG" },
  {
    cc: "USD",
    symbol: "$",
    name: "United States dollar",
    plural: "Dollars",
    fraction: "Cent",
    fractionPlural: "Cents",
    country_code: "US",
  },
  { cc: "UYU", symbol: "$U", name: "Uruguayan peso", country_code: "UY" },
  { cc: "UZS", symbol: "UZS", name: "Uzbekistani som", country_code: "UZ" },
  { cc: "VEB", symbol: "Bs", name: "Venezuelan bolivar", country_code: "VE" },
  { cc: "VND", symbol: "\u20ab", name: "Vietnamese dong", country_code: "VN" },
  { cc: "VUV", symbol: "VT", name: "Vanuatu vatu", country_code: "VU" },
  { cc: "WST", symbol: "WS$", name: "Samoan tala", country_code: "WS" },
  {
    cc: "XAF",
    symbol: "CFA",
    name: "Central African CFA franc",
    country_code: "CF",
  },
  {
    cc: "XCD",
    symbol: "EC$",
    name: "East Caribbean dollar",
    country_code: "XC",
  },
  {
    cc: "XDR",
    symbol: "SDR",
    name: "Special Drawing Rights",
    country_code: "IMF",
  },
  {
    cc: "XOF",
    symbol: "CFA",
    name: "West African CFA franc",
    country_code: "XOF",
  },
  { cc: "XPF", symbol: "F", name: "CFP franc", country_code: "PF" },
  { cc: "YER", symbol: "YER", name: "Yemeni rial", country_code: "YE" },
  { cc: "ZAR", symbol: "R", name: "South African rand", country_code: "ZA" },
  { cc: "ZMK", symbol: "ZK", name: "Zambian kwacha", country_code: "ZM" },
  { cc: "ZWR", symbol: "Z$", name: "Zimbabwean dollar", country_code: "ZW" },
];

export const currenciesSelected = [
  {
    cc: "NGN",
    symbol: "\u20a6",
    name: "Naira",
    fraction: "Kobo",
    //  name: "Nigerian naira",
  },
  {
    cc: "USD",
    symbol: "$",
    name: "Dollar",
    plural: "Dollars",
    fraction: "Cent",
    fractionPlural: "Cents",
  },
];

export const currenciesOptions = currenciesSelected.map((el) => ({
  ...el,
  label: `${el.name} (${el.symbol})`,
  value: el.cc,
  currency: el.cc,
}));

export const currenciesOptionsAll = currencies.map((el) => ({
  ...el,
  label: `${el.name} (${el.symbol})`,
  value: el.cc,
  currency: el.cc,
}));
