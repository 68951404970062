import { Modal } from "react-bootstrap";

import { useAuth } from "../../hooks/useAuth";

export default function PrintIssueItemModal({
  showPrintModal,
  setShowPrintModal,
  Trans_ID,
  warehouse,
  waybill,
}) {
  const { backendUrl } = useAuth();

  return (
    <Modal
      show={showPrintModal}
      onHide={() => setShowPrintModal(false)}
      centered={true}
      animation={false}
      enforceFocus={false}
      size="sm"
    >
      <Modal.Body>
        <div>
          <div className="text-center">
            <a
              className="btn btn-primary"
              href={
                warehouse
                  ? `${warehouse}/${Trans_ID}`
                  : `${backendUrl}/api/invoice/pdf/issue-invoice/${Trans_ID}`
              }
              target="_blank"
              onClick={() => setShowPrintModal(false)}
            >
              Print Receipt
            </a>

            {waybill && (
              <>
                <a
                  className="btn btn-primary mx-4"
                  href={
                    waybill
                      ? `${waybill}/${Trans_ID}?waybill=${true}`
                      : `${backendUrl}/api/invoice/pdf/issue-invoice/${Trans_ID}`
                  }
                  target="_blank"
                  onClick={() => setShowPrintModal(false)}
                >
                  Print Waybill
                </a>
                <div className="mb-4" />
              </>
            )}
            <button className="btn border mx-4" onClick={() => setShowPrintModal(false)}>
              Close
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
