import { Table, Dropdown } from "react-bootstrap";
import CachedIcon from "mdi-react/CachedIcon";
import { CSVLink } from "react-csv";
import queryString from "query-string";
import { useQuery } from "react-query";
import { useEffect, useState, useRef } from "react";
import { format, parse } from "date-fns";
import { useOutletContext } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { isEmpty, lowerCase } from "lodash";
import currency from "currency.js";

import {
  ExportIcon,
  ExcelIcon,
  PDFIcon,
  FilterTwoIcon,
  BookIcon,
  RecieptIcon,
  PageHeaderListIcon,
} from "../../Icons";
import NoTableItem from "../../utils/NoTableItem";
import { useDownloadExcel } from "../../../hooks/useDownloadExcel";
import { useAuth } from "../../../hooks/useAuth";
import useDebounce, { useScrollTop } from "../../../utils/hooks";
import ModalLoader from "../../utils/ModalLoader";
import { paginationOptions } from "../../../utils/helpers";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import SetSalesRepModal from "../../SalesAndInvoicing/SetSalesRepModal";
import {
  fetchActionsUtil,
  customerFullName,
  formatDate,
} from "../../../utils/helpers";

function Payment() {
  useScrollTop();
  const {
    queryParams,
    setQueryParams,
    showFilter,
    setShowFilter,
  } = useOutletContext();

  const initialFilterParams = {};

  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  const { backendUrl } = useAuth();

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const [filterParams] = useState({
    ...initialFilterParams,
    ...queryParams,

    // Get the date in url and parse so date picker can use it
    date: queryParams?.date
      ? parse(queryParams?.date, "yyyy-MM-dd", new Date())
      : "",
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const {
    data = {
      count: 0,
      customerLedgers: [],
    },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    ["FETCH_SALES_CUSTOMERS", queryParams],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/customers/payment-sales-rep?${queryString.stringify(
          queryParams
        )}`,
        "GET"
      ),
    {
      keepPreviousData: true,
    }
  );

  // console.log(data);
  // const handleSearchQueryChange = (e) => {
  //   setQueryParams({
  //     // ...searchParamsToObject(searchQuery.entries()),
  //     ...queryParams,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/customers/payment-sales-rep?${queryString.stringify(
        rest
      )}`,
      "GET"
    );
    const company = exData?.company?.CompName;

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
          new Date(rest.startDate),
          "E MMM d yyyy k:mm:ss z"
        )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";

    exData = exData?.customerLedgers?.map(
      ({ Cust_Rep_CustomerLedger, Cust_Rep_Customers }, i) => [
        i + 1,
        Cust_Rep_Customers?.LastName === "Walk-In" &&
          Cust_Rep_CustomerLedger?.transaction
          ? `${Cust_Rep_CustomerLedger?.transaction?.ShipTo} (Walk-In)`
          : customerFullName(Cust_Rep_Customers),
        Cust_Rep_CustomerLedger.Post_Time
          ? formatDate(Cust_Rep_CustomerLedger.Post_Time, "dd MMM, yyyy")
          : "",
        Cust_Rep_CustomerLedger.DocType,
        Cust_Rep_CustomerLedger.Trans_ID,
        Cust_Rep_CustomerLedger.PaymentType,
        currency(Cust_Rep_CustomerLedger.Credit, {
          symbol: "",
        }).format(),
        Cust_Rep_CustomerLedger.InvoiceCat,
        Cust_Rep_CustomerLedger.Username,
      ]
    );

    exData = [
      [company],
      ["Rep.Sales/Payment Report"],
      [date],
      [""],
      [
        "S/N",
        "Business Name",
        "Trans Date",
        "Doc Type",
        "Trans ID",
        "Payment Type",
        "Credit Amount",
        "InvoiceCat",
        "Username",
      ],
      ...exData,
    ];
    console.log(exData);
    setExcelData(exData);
  };

  return (
    <>
      <div className="absolute-title">
        <h1> Sales Reps Analysis</h1>
      </div>

      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className="content-body">
              <header>
                <h1 className="d-flex align-items-center gap-3">
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Sales/Payment Report
                  <button
                    title="Refresh"
                    onClick={() => refetch()}
                    className="btn text-primary"
                  >
                    <CachedIcon />
                  </button>
                </h1>

                <div className="actions">
                  <CSVLink
                    className="btn print d-none"
                    filename={`Rep.Sales/Payment Report(${format(
                      new Date(),
                      "dd-MMM-yyyy hh:mm:ss a"
                    )}).csv`}
                    data={excelData}
                    ref={CSVLinkRef}
                  />

                  {/* <button /> */}
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="btn print"
                      disabled={isfetchingExcel}
                      bsPrefix=""
                    >

                      <ExportIcon color="#008000" />Export
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="text-center"
                    >
                      <Dropdown.Item
                        as="div"
                        onClick={onDownloadExcelData}
                        className="p-cursor"
                      >
                        Excel <ExcelIcon color="#008000" />
                      </Dropdown.Item>
                      <Dropdown.Item as="div">
                        <a
                          href={`${backendUrl}/api/customers/pdf/payment-sales-rep?${queryString.stringify(
                            rest
                          )}`}
                          target="_blank"
                        >
                          PDF
                          <PDFIcon color="#ff0000" />
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </header>
              <div className="px-md-4">
                <Table
                  responsive
                  borderless
                  striped
                  className="product-table mt-4"
                >
                  <thead>
                    <tr>
                      <th />
                      <th>Business Name</th>
                      <th>Trans Date</th>
                      <th>Doc Type</th>
                      <th>Trans ID</th>
                      <th>Payment Type</th>

                      <th>Credit Amount</th>
                      <th>InvoiceCat</th>
                      <th>Username</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.customerLedgers?.map(
                      (
                        { Cust_Rep_CustomerLedger, Cust_Rep_Customers },
                        index
                      ) => (
                        <tr key={index}>
                          <td>
                            {["invoice", "official receipt"].includes(
                              lowerCase(Cust_Rep_CustomerLedger.DocType)
                            ) && (
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant=""
                                    className="bg-white border-0"
                                    bsPrefix="print more"
                                  >
                                    <DotsVerticalIcon />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu
                                    popperConfig={{
                                      strategy: "fixed",
                                    }}
                                    renderOnMount
                                    className="dropdown-with-icons"
                                  >
                                    {lowerCase(
                                      Cust_Rep_CustomerLedger.DocType
                                    ) === "invoice" && (
                                        <>
                                          <Dropdown.Item as="div">
                                            <a
                                              href={`${backendUrl}/api/invoice/pdf/invoice/${Cust_Rep_CustomerLedger.Trans_ID
                                                }`}
                                              target="_blank"
                                            >
                                              <BookIcon />
                                              Open Invoice - A4
                                            </a>
                                          </Dropdown.Item>
                                          <Dropdown.Item as="div">
                                            <a
                                              href={`${backendUrl}/api/invoice/pdf/invoice/${Cust_Rep_CustomerLedger.Trans_ID
                                                }?thermalPrinter=true`}
                                              target="_blank"
                                            >
                                              <BookIcon />
                                              Open Invoice - Thermal Printer
                                            </a>
                                          </Dropdown.Item>
                                        </>
                                      )}
                                    {lowerCase(
                                      Cust_Rep_CustomerLedger.DocType
                                    ) === "official receipt" && (
                                        <>
                                          <Dropdown.Item as="div">
                                            <a
                                              href={`${backendUrl}/api/invoice/pdf/receipt/${Cust_Rep_CustomerLedger.Trans_ID
                                                }`}
                                              target="_blank"
                                            >
                                              <RecieptIcon />
                                              Open Reciept - A4
                                            </a>
                                          </Dropdown.Item>

                                          <Dropdown.Item as="div">
                                            <a
                                              href={`${backendUrl}/api/invoice/pdf/receipt/${Cust_Rep_CustomerLedger.Trans_ID
                                                }?thermalPrinter=true`}
                                              target="_blank"
                                            >
                                              <RecieptIcon />
                                              Open Reciept - Thermal Printer
                                            </a>
                                          </Dropdown.Item>
                                        </>
                                      )}
                                    {lowerCase(
                                      Cust_Rep_CustomerLedger.DocType
                                    ) === "invoice" && (
                                        <Dropdown.Item as="button">
                                          <a
                                            href={`${backendUrl}/api/invoice/pdf/waybill/${Cust_Rep_CustomerLedger.Trans_ID
                                              }`}
                                            target="_blank"
                                          >
                                            <PageHeaderListIcon />
                                            Open Waybill
                                          </a>
                                        </Dropdown.Item>
                                      )}
                                  </Dropdown.Menu>{" "}
                                </Dropdown>
                              )}
                          </td>
                          <td>
                            {" "}
                            {Cust_Rep_Customers?.LastName === "Walk-In" &&
                              Cust_Rep_CustomerLedger?.transaction
                              ? `${Cust_Rep_CustomerLedger?.transaction?.ShipTo
                              } (Walk-In)`
                              : customerFullName(Cust_Rep_Customers)}
                          </td>
                          <td>
                            {Cust_Rep_CustomerLedger.Post_Time
                              ? formatDate(
                                Cust_Rep_CustomerLedger.Post_Time,
                                "dd MMM, yyyy"
                              )
                              : ""}
                          </td>
                          <td>{Cust_Rep_CustomerLedger.DocType}</td>
                          <td>{Cust_Rep_CustomerLedger.Trans_ID}</td>
                          <td>{Cust_Rep_CustomerLedger.PaymentType}</td>

                          <td>
                            {currency(Cust_Rep_CustomerLedger.Credit, {
                              symbol: "",
                            }).format()}
                          </td>

                          <td>{Cust_Rep_CustomerLedger.InvoiceCat}</td>
                          <td>{Cust_Rep_CustomerLedger.Username}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
                {!isFetching &&
                  isSuccess &&
                  data?.customerLedgers?.length < 1 ? (
                  <NoTableItem queryParams={queryParams} />
                ) : null}
              </div>
              {/* {data?.customerLedgers?.length > 0 ? (
                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>

                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={data.count / queryParams.limit}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              ) : (
                <div className="py-2" />
              )} */}
            </div>
          </div>
        </div>
        <ModalLoader show={isfetchingExcel} />
      </main>
    </>
  );
}

export default Payment;
