import { Table, InputGroup, Button, Dropdown, Form } from "react-bootstrap";
import { CSVLink } from "react-csv";
import printJS from "print-js";
import {
  CalendarIcon,
  CubeIcon,
  DeleteIcon,
  EditIcon,
  ExportIcon,
  FIleUploadLineIcon,
  FilterCollapseIcon,
  PrintIcon,
  RecieptIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import DateRangePicker from "../utils/DateRangePicker";
import CachedIcon from "mdi-react/CachedIcon";
import { FilterTwoIcon, PDFIcon, ExcelIcon } from "../Icons";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import useDebounce, {
  useEffectOnce,
  useIsAdmin,
  useIsCashier,
  useIsStore,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import queryString from "query-string";
import { appSettings, backendApis, services } from "../../config";
import { useMutation, useQuery, useQueryClient } from "react-query";
import currency from "currency.js";
import { addDays, addMonths, addWeeks, addYears, compareAsc, format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  defaultSelectValue,
  fetchActionsUtil,
  formatDate,
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  scrollToTop,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";
import UpdateItemModal from "../UpdateItemModal";
import NewItemModal from "../NewItemModal";
import PurchaseDialog from "../PurchaseDialog";
import NewItemServiceModal from "../NewItemServiceModal";
import NewItemModalWrapper from "../NewItemModalWrapper";
import NewVendorModal from "../NewVendorModal";
import BatchesModal from "./BatchesModal";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import LoginDialog from "../LoginDialog";
import ConfirmDialog from "../ConfirmDialogue";
import { toast } from "react-toastify";
import CubeOutline from "mdi-react/CubeOutlineIcon";
import { Link } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import Select from "react-select";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import ItemsTable from "../utils/ItemsTable";
import TableComponent from "../TableComponent";
import ConvertQuantity from "../utils/ConvertQuantity";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import UpdateItemPriceModal from "../UpdateItemPriceModal";
import QuantityConversionModal from "../QuantityConversionModal";
import GRNDialog from "../GRNDialog";
import NoTableItem from "../utils/NoTableItem";
import { isEmpty } from "lodash";
import { IsPrivileged } from "../DisplayChildElement";

export default function ItemsExpiryReport() {
  const queryClient = useQueryClient();
  const { backendUrl, isIronRod, isCement } = useAuth();
  const generalSettings = useStoreState(state => state.generalSettings);
  const itemMeasurements = useStoreState(state => state.itemMeasurements);
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showUpdatePriceModal, setShowUpdatePriceModal] = useState(false);
  const [consolidate, setConsolidate] = useState(false);
  useScrollTop();

  const [showUpdateInventoryModal, setShowUpdateInventoryModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [newData, setNewData] = useState([]);

  const [showBatchesModal, setShowBatchesModal] = useState(false);
  const [showCreateNewInventoryModal, setShowCreateNewInventoryModal] = useState(false);

  const [showLoadingTitle, setShowLoadingTitle] = useState(null);

  const initialFilterParams = useMemo(() => {
    try {
      let expiryFilterDate = new Date();
      let expiryFilterDurationValue = generalSettings.expiryFilterDurationValue;
      let expiryFilterDurationNumber = Number(generalSettings.expiryFilterDurationNumber);

      let expiryEndDate =
        expiryFilterDurationValue === "Day"
          ? addDays(new Date(expiryFilterDate), expiryFilterDurationNumber)
          : expiryFilterDurationValue === "Month"
          ? addMonths(new Date(expiryFilterDate), expiryFilterDurationNumber)
          : expiryFilterDurationValue === "Week"
          ? addWeeks(new Date(expiryFilterDate), expiryFilterDurationNumber)
          : new Date();

      //  console.log(generalSettings);
      //  console.log(expiryEndDate);
      return {
        page: 1,
        limit: 40,
        barcode: "",
        itemName: "",
        withProduct: false,
        withCategory: false,
        itemsOutOfStock: false,
        startDate: format(new Date(), "yyyy-MM-dd"),
        endDate: format(expiryEndDate, "yyyy-MM-dd"),
        model: "StockBatches",
        withQuantity: true,
        withExpiry: true,
      };
    } catch (err) {
      console.log(err);
      return {};
    }
  }, [generalSettings]);

  /* useEffectOnce(() => {
    console.log(generalSettings, filterParams);
    setFilterParams({});
  });

  (() => {
    return {
      ...initialFilterParams,
      endDate: format(new Date(), "yyyy-MM-dd"),
    };
  })() */

  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...queryParams,
    ...initialFilterParams,
  });

  const [excelData, setExcelData] = useState([]);
  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(excelData, CSVLinkRef);

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const fetchItems = async queryParams => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/items/?&${queryString.stringify(queryParams)}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { data = { count: 0, items: [] }, refetch, isFetching, isSuccess } = useQuery(
    [queryActions.ITEMS_OUT_OF_STOCK, queryParams],
    () => fetchItems(queryParams),
    {
      keepPreviousData: true,
    }
  );

  /*   const handleShowBatches = (item) => {
    setSelectedItem(item);
    setShowBatchesModal(true);
  }; */

  const deleteItem = async payload => {
    let response = await fetch(`${backendUrl}/api/items/delete`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteMutation = useMutation(payload => deleteItem(payload), {
    onSuccess: ({ message, data: { item } }) => {
      toast.success(message);

      queryClient.setQueryData([queryActions.ITEMS_OUT_OF_STOCK, queryParams], data => {
        data.items = data.items.filter(el => el.Bar_Code !== item.Bar_Code);
        return data;
      });
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const handleDeleteItem = async item => {
    if (await LoginDialog()) {
      if (
        await ConfirmDialog({
          title: "Delete Item",
          description: "Are you sure you want to delete this Item from your inventory",
        })
      ) {
        deleteMutation.mutate(item);
      }
    }
  };

  const handleFilterParamsChange = e => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = e => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = date => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams({
      ...initialFilterParams,
    });
  };

  const handleSelectedItem = item => {
    setFilterParams({
      ...filterParams,
      barcode: item.Bar_Code,
      itemName: item.Item_Name,
    });
    setShowItemsPopover(false);
  };

  const editPrice = async el => {
    if (await LoginDialog()) {
      setSelectedItem(el);
      setShowUpdatePriceModal(true);
    }
  };

  const printGRN = async InvoiceNo => {
    await GRNDialog({
      title: "Transaction posted successfully",
      // description: "...",
      InvoiceNo,
    });
  };

  const quarantineBatch = async payload => {
    let response = await fetch(`${backendUrl}/api/items/quarantine-from-batch`, {
      method: "POST",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...payload,
      }),
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const quarantineBatchMutation = useMutation(payload => quarantineBatch(payload), {
    onSuccess: () => {
      toast.success(`Success`);
      refetch();
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const quarantine = async batch => {
    if (await LoginDialog()) {
      if (
        await ConfirmDialog({
          title: "Quarantine Batch",
          description: "Are you sure you want to quarantine this Batch",
        })
      ) {
        quarantineBatchMutation.mutate({ batch });
      }
    }
  };

  // The main table data
  const tableBodyData = (el, index) => {
    const color =
      compareAsc(new Date(), new Date(el?.ExpireDate)) === 1
        ? { borderLeft: "5px solid red" }
        : { borderLeft: "5px solid inherit" };

    return (
      <>
        <td style={color} key={el?.Bar_Code}>
          <Dropdown>
            <Dropdown.Toggle variant="" className="bg-white border-0" bsPrefix="print more">
              <DotsVerticalIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
              className="dropdown-with-icons"
            >
              {el?.InvoiceNo && (
                <>
                  <Dropdown.Item as="button" onClick={() => quarantine(el)}>
                    <RecieptIcon /> Quarantine
                  </Dropdown.Item>
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </td>
        <td>{data?.startIndex + index + 1}</td>
        <td>{el?.Bar_Code}</td>
        <td>{el?.ItemName}</td>
        <td title={el.Quantity}>
          <ConvertQuantity quantity={el.Quantity} desc={el.Item_Desc} />
        </td>
        <td>{el?.ManDate ? formatDate(el?.ManDate, "dd MMM, yyyy") : ""}</td>
        <td>{el?.ExpireDate ? formatDate(el?.ExpireDate, "dd MMM, yyyy") : ""}</td>
        {consolidate && <td>{el?.company}</td>}
      </>
    );
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th />
          <th>S/N</th>
          <th>Item Code</th>
          <th>Item Name</th>
          <th>QTY</th>
          <th>Man Date</th>
          <th>Expiry Date</th>
          {consolidate && <th>Branch</th>}
        </tr>
      </thead>
    );
  };

  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    const { limit, page, ...rest } = queryParams;
    let exData = await fetchExcelData(
      `${backendUrl}/api/items?&${queryString.stringify(rest)}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;

    exData = newData?.map(row => ({
      ...row,
      Date_Log: format(new Date(row.RegDate), "dd-MMM-yyyy"),
      ManDate: format(new Date(row.ManDate), "dd-MMM-yyyy"),
      ExpireDate: format(new Date(row.ExpireDate), "dd-MMM-yyyy"),
    }));

    exData = exData.map((d, i) => [
      data?.startIndex + i + 1,
      d.Bar_Code,
      d.ItemName,

      isIronRod
        ? qtyFormatToString(qtyFormat(d.Quantity, d.Item_Desc, itemMeasurements))
        : d.Quantity,
      d?.ManDate,
      d?.ExpireDate,
      consolidate ? d?.company : "",
    ]);

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [company],
      ["Items Expiry Report"],
      [date],
      [""],
      [
        "S/N",
        "Item Code",
        "Item Name",
        "QTY",
        "Man Date",
        "Expiry Date",
        consolidate ? "Branch" : "",
      ],
      ...exData,
      [""],
    ];
    // console.log(exData);
    setExcelData(exData);
  };

  const consolidateItems = async filters => {
    setShowLoadingTitle("Consolidating...");
    setIsLoading(true);

    const consItems = await Promise.all(
      backendApis.map(endPoints => {
        return new Promise(async (resolve, reject) => {
          try {
            const items = await fetchActionsUtil(
              `${endPoints.url}/api/items?&${queryString.stringify(filters)}&requesterName=${
                endPoints.name
              }`
            );

            const mainItems = items?.data?.items?.map(el => ({
              ...el,
              company: endPoints.name,
            }));
            resolve(mainItems);
          } catch (error) {
            console.log(error);
            reject(error);
          }
        });
      })
    );

    setShowLoadingTitle("Consolidating Completed ✔️");
    setIsLoading(false);
    return consItems;
  };

  useEffect(() => {
    const changeDisplay = async () => {
      try {
        if (consolidate && !filterParams.startDate) {
          scrollToMonth();
          return toast.info("Select Date Range, so that other branches (BU) can be consolidated");
        }

        let QData = data?.items;
        if (consolidate) {
          QData = await consolidateItems(queryParams);
          QData = QData?.flat(1);
        } else {
          QData = data?.items;
        }
        setNewData(QData);
      } catch (error) {
        console.log({ error });
      }
    };

    changeDisplay();
  }, [consolidate, queryParams, data?.items, filterParams.startDate]);

  const monthRef = useRef(null);

  const scrollToMonth = () => {
    monthRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onConsolidate = () => {
    if (!consolidate && !filterParams.startDate) {
      scrollToMonth();
      return toast.info("Select Date Range, so that other branches (BU) can be consolidated");
    }
    setConsolidate(!consolidate);
  };

  return (
    <IsPrivileged roleName={["Items Expiry Report"]}>
      <section className="cashbook-wrapped">
        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className={`filter-area ${showFilter ? "open" : "close"}`}>
                <div className="filter-header">
                  <h2>
                    <FilterTwoIcon /> Filters
                  </h2>

                  <Button variant="white" onClick={() => setShowFilter(!showFilter)}>
                    <FilterCollapseIcon />
                  </Button>
                </div>
                <div className="filter-body">
                  <Form>
                    <div className="body">
                      <Form.Group className="mb-2-5">
                        <Form.Label>Item Code</Form.Label>

                        <InputGroup>
                          <Form.Control
                            name="barcode"
                            placeholder="Enter Item Code"
                            value={filterParams?.barcode}
                            onChange={e => handleFilterParamsChange(e)}
                          />

                          <Popover
                            isOpen={showItemsPopover}
                            onClickOutside={() => setShowItemsPopover(false)}
                            content={() => <ItemsTable handleSelectedItem={handleSelectedItem} />}
                            position="bottom"
                          >
                            <InputGroup.Text onClick={() => setShowItemsPopover(!showItemsPopover)}>
                              <MagnifyIcon />
                            </InputGroup.Text>
                          </Popover>
                        </InputGroup>
                      </Form.Group>

                      <Form.Group className="mb-2-5">
                        <Form.Label>Item Name</Form.Label>
                        <Form.Control
                          name="itemName"
                          value={filterParams?.itemName}
                          placeholder="Enter Item Name"
                          onChange={e => handleFilterParamsChange(e)}
                        />
                      </Form.Group>
                      <hr className="mt-3 mb-4" />
                      <Form.Group className="mb-3" ref={monthRef} style={{ zIndex: 9999 }}>
                        <Form.Label>Date Range</Form.Label>
                        <div className="position-relative">
                          <RsDateRangePicker
                            placement="bottomLeft"
                            value={
                              filterParams.startDate && filterParams.endDate
                                ? [
                                    parse(filterParams.startDate, "yyyy-MM-dd", new Date()),
                                    parse(filterParams.endDate, "yyyy-MM-dd", new Date()),
                                  ]
                                : []
                            }
                            onClean={() => clearDateRange()}
                            onOk={date => filterByDateRange(date)}
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="buttons rounded">
                      <Button onClick={() => reset()} variant="white">
                        Reset
                      </Button>
                      <Button onClick={() => search()} variant="primary">
                        Search
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="content-body">
                <header>
                  <h1>
                    {!showFilter && (
                      <button onClick={() => setShowFilter(!showFilter)} className="btn filter">
                        <FilterTwoIcon />
                      </button>
                    )}
                    Items Expiry Report
                    <button onClick={() => refetch()} className="btn text-primary" title="Refresh">
                      <CachedIcon />
                    </button>
                  </h1>

                  <div className="actions mr-5">
                    <Form.Check
                      type="switch"
                      label="Consolidate"
                      className="ga-form-check fs-6"
                      checked={consolidate}
                      onChange={() => onConsolidate()}
                    />

                    <CSVLink
                      className="btn print d-none"
                      filename={`Items Expiry Report(${format(
                        new Date(),
                        "dd-MMM-yyyy hh:mm:ss a"
                      )}).csv`}
                      data={excelData}
                      ref={CSVLinkRef}
                    />

                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="btn print"
                        disabled={isfetchingExcel}
                        bsPrefix=""
                      >
                        Export
                        <ExportIcon color="#008000" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className="text-center"
                      >
                        <Dropdown.Item as="div" className="p-cursor" onClick={onDownloadExcelData}>
                          Excel <ExcelIcon color="#008000" />
                        </Dropdown.Item>
                        <Dropdown.Item as="div">
                          <a
                            href={`${backendUrl}/api/items/pdf/expiry-items-report?${queryString.stringify(
                              rest
                            )}`}
                            target="_blank"
                          >
                            PDF <PDFIcon color="#ff0000" />
                          </a>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </header>

                <div className="px-md-4">
                  <TableComponent
                    responsive
                    borderless
                    striped
                    tableHeadsFunction={tableHead}
                    mainDataArray={newData}
                    tableDataRowFunction={tableBodyData}
                    className="product-table text-nowrap"
                  />{" "}
                  {!isFetching && isSuccess && isEmpty(newData) ? (
                    <NoTableItem queryParams={queryParams} />
                  ) : null}
                </div>

                {!consolidate && (
                  <div className="d-flex justify-content-between px-3 align-items-center pagination">
                    <div className="pagination_left">
                      <p className="m-0 p-0">Show</p>
                      <select
                        value={queryParams.limit}
                        name="limit"
                        className="form-select "
                        onChange={e => handleSearchQueryChange(e)}
                      >
                        <option value="10">10 rows</option>
                        <option value="20">20 rows</option>
                        <option value="30">30 rows</option>
                        <option value="40">40 rows</option>
                        <option value="50">50 rows</option>
                        <option value="100">100 rows</option>
                      </select>
                    </div>

                    <ReactPaginate
                      {...paginationOptions}
                      pageCount={Math.ceil(data.count / queryParams.limit)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={0}
                      onPageChange={({ selected }) => {
                        scrollToTop();
                        setQueryParams({
                          ...queryParams,
                          page: selected + 1,
                        });
                      }}
                      forcePage={queryParams.page - 1}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          {showUpdateInventoryModal && (
            <UpdateItemModal
              showUpdateInventoryModal={showUpdateInventoryModal}
              setShowUpdateInventoryModal={setShowUpdateInventoryModal}
              refetch={refetch}
              printGRN={printGRN}
            />
          )}

          {showCreateNewInventoryModal && (
            <NewItemModalWrapper
              setShowCreateNewInventoryModal={setShowCreateNewInventoryModal}
              showCreateNewInventoryModal={showCreateNewInventoryModal}
              refetch={refetch}
            />
          )}

          {showBatchesModal && selectedItem && (
            <BatchesModal
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              showBatchesModal={showBatchesModal}
              setShowBatchesModal={setShowBatchesModal}
            />
          )}

          {showUpdatePriceModal && selectedItem ? (
            <UpdateItemPriceModal
              showUpdatePriceModal={showUpdatePriceModal}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              setShowUpdatePriceModal={setShowUpdatePriceModal}
              refetch={refetch}
            />
          ) : null}

          <ModalLoader show={isfetchingExcel || isLoading} title={showLoadingTitle} />

          {/*  <QuantityConversionModal /> */}
        </main>
      </section>
    </IsPrivileged>
  );
}
