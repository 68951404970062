import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { DownloadIcon, ExcelIcon } from "../Icons";
import ModalLoader from "../utils/ModalLoader";
import { read, utils } from "xlsx";
import { toast } from "react-toastify";
import { cloneDeep, isEmpty, lowerCase } from "lodash";
import { calculateMarkUp_Incentive_Margin, pcsToTons, tonsToPcs } from "../../utils/helpers";
import currency from "currency.js";
import * as yup from "yup";
import ConfirmDialog from "../ConfirmDialogue";
import { useStoreState } from "easy-peasy";
import { Button } from "react-bootstrap";

export default function ImportCartItems({
  mode,
  isQuotation = false,
  handleAddItem,
  previouslySelectedItems = [],
  Warehouse,
}) {
  const { backendUrl } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const itemMeasurements = useStoreState(state => state.itemMeasurements);

  const getBatches = async items => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        let response = await fetch(
          Warehouse
            ? `${backendUrl}/api/warehouse/batches-for-sales/${Warehouse}/multiple?itemCodes=${items
                .map(el => el.Bar_Code)
                .join(",")}`
            : `${backendUrl}/api/items/batches-for-sales/multiple?itemCodes=${items
                .map(el => el.Bar_Code)
                .join(",")}`,
          {
            method: "GET",
            headers: {
              Accept: "Application/json",
              "Content-Type": "Application/json",
            },
            credentials: "include",
          }
        );
        if (!response.ok) {
          response = await response.json();
          throw new Error(response?.message);
        } else {
          const { data } = await response.json();
          resolve(data);
        }
      } catch (err) {
        console.log(err);
        toast.error(`Unable to load batches, Try again: ${err}`);
        reject();
      } finally {
        setIsLoading(false);
      }
    });
  };

  const submit = async values => {
    try {
      const { items: itemsWithBatches } = await getBatches(values.itemsForSale);
      const itemsForSale = cloneDeep(values.itemsForSale).map(item => {
        const foundItem = itemsWithBatches.find(el => el.Bar_Code === item.Bar_Code);

        /*  let saleType = foundItem.Product_Model;
        if (foundItem.Product_Model === "Tons") {
          // check if has enough tons
          const foundMeasurement = itemMeasurements.find(
            (measurement) => measurement.Size === selectedItem.Item_Desc
          );
          if (foundMeasurement) {
            const quantityInTon = Number(foundMeasurement.Quantity);

            if (Number(quantityInTon) > Number(item.quantityInStock)) {
              saleType = "Pieces";
            }
          }
        } */

        const selectedItem = {
          ...foundItem,
          ...item,
          Size: foundItem.Item_Desc,
          Serial_Number: foundItem.Item_Desc,
          saleType: foundItem.Product_Model,
          PriceSold: currency(
            item?.PriceSold && item?.PriceSold != "" ? item?.PriceSold : foundItem.UnitPrice
          ).value,
          batches: foundItem?.batches,
        };

        const values = cloneDeep(selectedItem);

        values.Quantity =
          lowerCase(values.saleType) === "tons" && selectedItem?.Item_Desc
            ? tonsToPcs(values.Quantity, selectedItem.Item_Desc, itemMeasurements)
            : values.Quantity;

        if (lowerCase(selectedItem.Item_Type) !== "service" && !isQuotation) {
          if (Number(values.Quantity) > selectedItem?.quantityInStock) {
            values.error = `${selectedItem.Item_Name}: Not enough item in stock`;
          }
        }
        /* values.Warrant_Duration = `${values.durationNumber} ${
        values.durationValue
      }`; */

        values.Warrant_Duration = values?.Warrant_Duration || "";

        values.PriceSold = currency(values.PriceSold).value;

        values.SubTotal = currency(values.PriceSold).multiply(
          lowerCase(values.saleType) === "tons"
            ? pcsToTons(values.Quantity, selectedItem.Item_Desc, itemMeasurements)
            : values.Quantity
        ).value;

        values.Discount = currency(values.Discount, {
          symbol: "",
          separator: "",
        })
          .multiply(
            lowerCase(values.saleType) === "tons"
              ? pcsToTons(values.Quantity, selectedItem.Item_Desc, itemMeasurements)
              : values.Quantity
          )
          .format();

        // Get Profit Based on batch we are picking from---------------------------------------------------
        const quantityToSell = values.Quantity;
        let remainder = quantityToSell;
        const updatedBatchDataToSave = [];
        let measurement = itemMeasurements.find(
          measurement => measurement.Size === selectedItem.Item_Desc
        );

        if (lowerCase(values.saleType) !== "tons" && lowerCase(values.saleType) !== "pieces") {
          measurement = {
            Quantity: 1,
          };
        }

        if (lowerCase(values.saleType) === "tons" && !measurement) {
          return toast.error("This item is not sold in Tons");
        }

        const priceSoldPerUnit =
          lowerCase(values.saleType) === "tons"
            ? currency(values.PriceSold)
                .divide(measurement?.Quantity || 1)
                .format()
            : values.PriceSold;

        for (let [index, batch] of selectedItem.batches.entries()) {
          const pcsUnitCost = currency(batch.UnitCost, {
            symbol: "",
            separator: "",
          })
            .divide(measurement?.Quantity || 1)
            .format();

          // console.log(selectedItem);

          //
          const { calculatedMarkUp, calculatedIncentive } = calculateMarkUp_Incentive_Margin({
            ...selectedItem,
            UnitCost: pcsUnitCost,
            PriceSold: priceSoldPerUnit,
            /*  Margin,
              marginType,
              marginPercentage,
              Incentive,
              incentiveType,
              incentivePercentage,
              markUp,
              markUpType,
              markUpPercentage, */
          });

          if (Number(batch.Quantity) >= Number(remainder)) {
            // means we are at the last
            updatedBatchDataToSave.push({
              unitCost: pcsUnitCost,
              quantity: remainder,
              totalUnitCost: currency(pcsUnitCost, {
                symbol: "",
                separator: "",
              })
                .multiply(remainder)
                .format(),
              totalUnitPrice: currency(priceSoldPerUnit, {
                symbol: "",
                separator: "",
              })
                .multiply(remainder)
                .format(),
              totalMargin: currency(selectedItem?.Margin || 0)
                .divide(measurement?.Quantity || 1)
                .multiply(remainder).value,
              totalIncentive: currency(calculatedIncentive || 0)
                .divide(measurement?.Quantity || 1)
                .multiply(remainder).value,
              totalMarkUp: currency(calculatedMarkUp || 0).multiply(remainder).value,
            });

            if (lowerCase(values.saleType) === "pieces") {
              values.UnitCost = pcsUnitCost;
            }
            break;
          } else {
            updatedBatchDataToSave.push({
              unitCost: pcsUnitCost,
              quantity: batch.Quantity,
              totalUnitCost: currency(pcsUnitCost, {
                symbol: "",
                separator: "",
              })
                .multiply(batch.Quantity)
                .format(),
              totalUnitPrice: currency(priceSoldPerUnit, {
                symbol: "",
                separator: "",
              })
                .multiply(batch.Quantity)
                .format(),
              totalMargin: currency(selectedItem?.Margin || 0)
                .divide(measurement?.Quantity || 1)
                .multiply(batch.Quantity).value,
              totalIncentive: currency(calculatedIncentive || 0)
                .divide(measurement?.Quantity || 1)
                .multiply(batch.Quantity).value,
              totalMarkUp: currency(calculatedMarkUp || 0).multiply(batch.Quantity).value,
            });

            remainder = Number(
              currency(remainder, {
                symbol: "",
                separator: "",
              })
                .subtract(batch.Quantity)
                .format()
            );
          }
        }

        //  console.log(updatedBatchDataToSave);

        const totalProfit = updatedBatchDataToSave
          .map(el =>
            currency(el.totalUnitPrice)
              .subtract(el.totalUnitCost)
              .subtract(el.totalIncentive)
              .format()
          )
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          );

        values.Profit = totalProfit;
        //-------------------------------------------------------------------------------------------------

        //---------------------------------
        values.Total_Incentive = updatedBatchDataToSave
          .map(el => el.totalIncentive)
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          );

        //  For Service Overwrite ----------------------------------------------------------
        if (lowerCase(selectedItem.Item_Type) === "service") {
          values.Profit = currency(priceSoldPerUnit)
            .subtract(selectedItem.UnitCost)
            .multiply(values.Quantity).value;
          values.Total_Incentive = currency(selectedItem?.Incentive).multiply(
            values.Quantity
          ).value;
        }

        return {
          ...selectedItem,
          Serial_Number: values?.Size ? values.Size : selectedItem.Item_Desc,
          ...values,
          UnitCost: !isEmpty(updatedBatchDataToSave)
            ? updatedBatchDataToSave[0]?.unitCost
            : selectedItem.UnitCost,
        };
      });

      //console.log(itemsForSale);

      const foundError = itemsForSale.find(el => el.error);
      if (foundError) {
        toast.error(foundError.error);
        return;
      }
      handleAddItem(itemsForSale);
    } catch (err) {
      console.log(err);
      toast.error("Unable to get batches, Check connection and try again");
    }
  };

  function handleFile({ eventData: e, type }) {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async function(e) {
        const workbook = read(e.target.result, {
          cellDates: true,
          dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
        });
        let rows = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
          defval: "",
          dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
        });

        if (
          !(await ConfirmDialog({
            title: "Are you sure?",
            description: `You want to import${
              !isEmpty(previouslySelectedItems) ? ": Existing import will be replaced" : ""
            }`,
          }))
        ) {
          return;
        }
        // TODO - validate excel
        let schema = yup.object().shape({
          items: yup.array().of(
            yup.object({
              Item_Code: yup.string().required(),
              Item_Name: yup.string().required(),
              Quantity: yup.string().required(),
              PriceSold: yup.string(),
            })
          ),
        });
        try {
          await schema.validate({ items: rows });

          // Clean Up
          rows = rows.map(item => {
            return {
              ...item,
              durationNumber: 0,
              durationValue: "Month",
              Warranty: false,
              requirePermission: false,
              selectedBatches: [],
              Item_Name: item.Item_Name,
              Bar_Code: String(item.Item_Code),
              Quantity: Number(item.Quantity),
              PriceSold: item.PriceSold,
            };
          });

          /*  console.log(rows);
          return; */

          submit({
            itemsForSale: rows,
          });
        } catch (err) {
          console.log(err);
          toast.error(err.name);
          toast.error(JSON.stringify(err.errors));
        }
      };
      reader.readAsArrayBuffer(file);
    }
    e.target.value = "";
  }

  return (
    <>
      <div className="d-flex gap-3">
        <label variant="" className="btn print" title="Import" bsPrefix="">
          <input
            type="file"
            className="d-none"
            onChange={e =>
              handleFile({
                eventData: e,
                type: "",
              })
            }
            accept=".xlsx"
          />
          Import <ExcelIcon color="#008000" />
        </label>

        <a
          href={`/excel_templates/INVEX_INVOICE_CART_ITEMS_TEMPLATE.xlsx`}
          target="_blank"
          title="Download Cart Template"
          download=""
          className="btn border print"
        >
          <DownloadIcon />
        </a>
      </div>

      <ModalLoader show={isLoading} />
    </>
  );
}
