// import { Formik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import {
  QueryClient,
  useQuery,
  useQueryClient,
  QueryCache,
  useMutation,
} from "react-query";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import Select from "react-select";
import "./../assets/scss/scoped/login.scoped.scss";
import {
  LoginImageOne,
  LoginImageTwo,
  LogoMeduim,
  OrnamentOne,
  OrnamentTwo,
  SlideShowIndex,
} from "./Icons";
import { useFormik } from "formik";
import { initialGeneralSettings, waitFor } from "../utils/helpers";
import { useAuth } from "../hooks/useAuth";
import { setDefaultLocale } from "react-datepicker";
import { services, backendApis, appSettings, IS_HR } from "../config";
import { useStoreActions } from "easy-peasy";
import { useEffectOnce } from "../utils/hooks";
import { loginHRSite } from "../utils/loginHRSite";
import EyeOffOutlineIcon from "mdi-react/EyeOffOutlineIcon";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import ConfirmDialog from "./ConfirmDialogue";
import { SendPasswordResetLink } from "./SendPasswordResetLinkModal";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import Cookies from "universal-cookie";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import OtpModal from "./Login/OtpModal";
import platform from "platform";
import { GoogleLogin } from "@react-oauth/google";

const options = backendApis
  .map((el) => ({
    ...el,
    value: el.name,
    label: el.name,
  }))
  .filter((el) => !el.viewOnly);

const selectedCompany = options.find((el) => el.isSelected);

export default function Login() {
  const location = useLocation();
  const { login } = useAuth();
  const queryClient = useQueryClient();
  const setItemMeasurements = useStoreActions(
    (actions) => actions.setItemMeasurements
  );
  const setGeneralSettings = useStoreActions(
    (actions) => actions.setGeneralSettings
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [loginUsername, setLoginUsername] = useState("");
  let [loginAction, setLoginAction] = useState("login");
  const [otpLoading, setOtpLoading] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [deviceID, setDeviceID] = useState("");
  const [userEmail, setUserEmail] = useState("");

  //const [showAlert, setShowAlert] = useState(true);
  const [
    showSendPasswordResetLinkModal,
    setShowsSendPasswordResetLinkModal,
  ] = useState(false);
  const initialValues = {
    username: "",
    password: "",
    company: selectedCompany ? selectedCompany?.value : "",
    rememberMe: true,
  };

  const getFingerprint = async () => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    return result.visitorId; // This is a unique identifier for the device
  };

  useEffect(() => {
    getFingerprint().then((deviceId) => {
      setDeviceID(deviceId);
    });
  }, []);

  const schema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required(),
    company: yup.string().required(),
  });

  const loginUser = async (values) => {
    try {
      // console.log(deviceID)
      // await waitFor(5000);
      const backendApi = backendApis.find(
        (el) => el.name === formik.values.company
      );
      let response = await fetch(`${backendApi.url}/api/auth/initiate-login`, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        body: JSON.stringify({
          ...formik.values,
          overwriteToken: values.overwriteToken,
          adminOnly: appSettings.adminOnly,
          deviceId: deviceID,
          deviceInfo: deviceInfo,
        }),
        credentials: "include",
      });

      if (!response.ok) {
        response = await response.json();
        if (response.errors) formik.setErrors(response.errors);
        if (response.alreadyLoggedIn) {
          handleAlreadyLoggedInUser();
        }
        throw new Error(response.message);
      }
      const output = await response.json();
      // check if the response is login to determine weather to log the user in or ask for OTP
      if (output.success && output.status === "login") {
        setLoginAction("login");
        return output;
      }

      // else if status === "OTP" or "New Device" then we prompt for OTP
      setLoginAction(output.status);
      setUserEmail(output.data);
      setShowOTPModal(true);
    } catch (error) {
      console.error("Error in login: ", error);
      toast.error(error.message);
    }
  };

  const resendOTP = async () => {
    try {
      const backendApi = backendApis.find(
        (el) => el.name === formik.values.company
      );
      // send otp again
      const response = await fetch(
        `${backendApi.url}/api/auth/resend-otp/${userEmail}/${loginAction}`
      );
      let result = await response.json();
      if (!result.success) throw new Error(result.message);
      if (result.success) {
        toast.success(result.message);
        return true;
      }
    } catch (error) {
      toast.error(error.message);
      return false;
    }
  };

  const submitOTP = (values) => {
    setOtpLoading(true);
    values.username = loginUsername;
    mutate({ ...values, deviceId: deviceID, deviceInfo });
  };

  const validateOTP = async (values) => {
    try {
      const backendApi = backendApis.find(
        (el) => el.name === formik.values.company
      );
      let response = await fetch(`${backendApi.url}/api/auth/complete-login`, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        body: JSON.stringify(values),
        credentials: "include",
      });
      // if (!response.ok) throw new Error("Error in connection")

      response = await response.json();
      if (!response.success) throw new Error(response.message);
      return response;
    } catch (err) {
      toast.error(err.message);
    } finally {
      setOtpLoading(false);
    }
  };

  const { status, error, mutate, isLoading } = useMutation(
    ["LOGIN"],
    (values) => {
      if (loginAction === "login") return loginUser(values);
      else return validateOTP(values);
    },
    {
      enabled: false,
      // cacheTime: 0,
      onSuccess: async (data) => {
        if (!data) return;
        queryClient.removeQueries("LOGIN");
        await waitFor(50);
        /* const cookies = new Cookies();
        cookies.set("x-access-token", data.token, {
          path: "/",
        }); */
        login(
          {
            ...data.user,
            company: formik.values.company,
          },
          location,
          data.isExpired
        );

        const backendApi = backendApis.find(
          (el) => el.name === formik.values.company
        );

        data.user.password = formik.values.password;
        IS_HR && loginHRSite({ user: data.user, backendApi });
        setItemMeasurements(data.itemMeasurements);
        setGeneralSettings({ ...initialGeneralSettings, ...data.settings });
      },
    }
  );

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      /* login(
        {
          ...values,
          company: formik.values.company,
          Department: "Admin",
        },
        location
      ); */
      setLoginUsername(values.username);
      mutate(values);
    },
  });

  useEffectOnce(() => {
    if (options && options.length === 1) {
      formik.setFieldValue("company", options[0].value);
    }

    const { name, version, os, manufacturer } = platform;
    let operatingSystem = os
      ?.toString()
      .split(" ")
      .slice(0, -1)
      .join(" ");
    let browser = `${name} ${version.split(".")[0]}`;

    setDeviceInfo(`${browser} on ${operatingSystem}`);
  });

  const handleAlreadyLoggedInUser = async () => {
    const proceed = await ConfirmDialog({
      title: "User already logged In",
      description: "End previous session",
      choice: true,
      backdrop: "static",
    });
    if (proceed) {
      mutate({
        ...formik.values,
        overwriteToken: true,
      });
    }
  };

  // ================================================
  const googleLoginUser = async (values) => {
    const backendApi =
      backendApis.find((el) => el.name === formik.values.company) ||
      backendApis[0];
    let response = await fetch(`${backendApi.url}/api/auth/google/login`, {
      method: "POST",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      body: JSON.stringify({
        ...values,
      }),
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      if (response.errors) formik.setErrors(response.errors);
      if (response.alreadyLoggedIn) {
        handleAlreadyLoggedInUser();
      }
      throw new Error(response.message);
    }

    const res = await response.json();
    return res;
  };

  const googleAuthMutation = useMutation(
    ["GOOGLE_LOGIN"],
    (values) => googleLoginUser(values),
    {
      onSuccess: async (data) => {
        if (!data) return;
        queryClient.removeQueries("GOOGLE_LOGIN");
        await waitFor(50);
        login(
          {
            ...data.user,
            company: formik.values.company,
          },
          location,
          data.isExpired
        );
        setItemMeasurements(data.itemMeasurements);
        setGeneralSettings({ ...initialGeneralSettings, ...data.settings });
      },
    }
  );

  const handleLoginSuccess = async (credentialResponse) => {
    try {
      const { credential } = credentialResponse;
      googleAuthMutation.mutate({ credential });
    } catch (error) {
      toast.error("Login failed:", error);
    }
  };
  //==================================================

  return (
    <div className="login">
      <OtpModal
        title={loginAction}
        open={showOTPModal}
        setOpen={setShowOTPModal}
        submitOTP={submitOTP}
        confirmLoading={otpLoading}
        setLoginAction={setLoginAction}
        userEmail={userEmail}
        handleResendOTP={resendOTP}
      />
      <div className="form-area">
        <Link to={"/dashboard"} className="logo">
          <LogoMeduim />
        </Link>

        <div className="content">
          <div className="text-center">
            {process.env?.REACT_APP_DEPLOYMENT_TYPE === "shared" ? (
              <h1>Sign in</h1>
            ) : (
              <img
                src={`${options[0].url}/images/company-logo.png`}
                alt="Company Logo"
                width={200}
                className="mb-2"
              />
            )}
            <p>Welcome back! Please enter your details.</p>
          </div>
          {!isLoading &&
            (status === "error" && error?.message ? (
              <Alert variant="danger">{error?.message}</Alert>
            ) : status === "success" ? (
              <Alert variant="success">Success</Alert>
            ) : null)}

          {process.env?.REACT_APP_DEPLOYMENT_TYPE === "shared" && (
            <>
              <div
                className="d-flex justify-content-center align-items-center google-auth-holder"
                style={{
                  pointerEvents: googleAuthMutation.isLoading ? "none" : "auto",
                  opacity: googleAuthMutation.isLoading ? 0.5 : 1,
                  animation: googleAuthMutation.isLoading
                    ? "pulsate 1.5s infinite ease-in-out"
                    : "none",
                }}
              >
                <GoogleLogin
                  onSuccess={handleLoginSuccess}
                  onError={() => toast.error("Login Failed")}
                />
              </div>

              <div class="d-flex justify-content-between align-items-center gap-3 my-3">
                <hr className="flex-grow-1" />
                <span>Or sign in with email</span>
                <hr className="flex-grow-1" />
              </div>
            </>
          )}

          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label className="login-label">
                Username or email address
              </Form.Label>
              <Form.Control
                className="ga-form-control"
                type="text"
                placeholder="Enter your username or email address"
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                isInvalid={formik.touched.username && !!formik.errors.username}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.username}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3 position-relative">
              <Form.Label className="login-label">Password</Form.Label>
              <Form.Control
                className="ga-form-control"
                type={showPassword ? "text" : "password"}
                placeholder="••••••••"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                isInvalid={formik.touched.password && !!formik.errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.password}
              </Form.Control.Feedback>
              <Button
                variant=""
                type="button"
                style={{
                  position: "absolute",
                  right: 0,
                  top: "2rem",
                  transform: `scale(0.8)`,
                }}
                onClick={(e) => {
                  e.target.blur();
                  setShowPassword(!showPassword);
                }}
                className="no-focus text-light"
              >
                {!showPassword ? <EyeOffOutlineIcon /> : <EyeOutlineIcon />}
              </Button>
            </Form.Group>

            {process.env?.REACT_APP_DEPLOYMENT_TYPE !== "shared" && (
              <Form.Group className="mb-3">
                <Form.Label htmlFor="company" className="login-label">
                  Company
                </Form.Label>
                <Select
                  id="company"
                  placeholder="Select"
                  menuShouldScrollIntoView={false}
                  className={
                    formik.touched.company && !!formik.errors.company
                      ? "is-invalid"
                      : ""
                  }
                  classNamePrefix={`select-company`}
                  isSearchable={false}
                  value={options.find(
                    (el) => el.value === formik.values.company
                  )}
                  onChange={(selected) => {
                    formik.setFieldValue("company", selected.value, true);
                  }}
                  onBlur={() => formik.setFieldTouched("company", true)}
                  options={options}
                />
                {formik.touched.company && !!formik.errors.company ? (
                  <span className="text-danger mt-2">
                    {formik.errors.company}
                  </span>
                ) : null}
              </Form.Group>
            )}

            <div className="d-flex justify-content-between align-items-center">
              <Form.Group className="">
                <Form.Check
                  type={"checkbox"}
                  id={`default`}
                  label={`Remember me`}
                  name={"rememberMe"}
                  // value={formik.values.remeberMe}
                  checked={formik.values.rememberMe}
                  onChange={formik.handleChange}
                />
              </Form.Group>

              <Button
                onClick={() => {
                  if (isEmpty(formik.values.company)) {
                    formik.setFieldTouched("company", true);
                    formik.setFieldError("company", "Select Company");
                    setShowsSendPasswordResetLinkModal(false);
                  } else {
                    setShowsSendPasswordResetLinkModal(true);
                  }
                }}
                variant=""
                className="text-primary forgot py-1 mb-2"
                type="button"
              >
                Forgot Password
              </Button>
            </div>

            <Button
              variant="primary"
              className="w-100 p-2"
              disabled={isLoading || googleAuthMutation.isLoading}
              type="submit"
            >
              {isLoading || googleAuthMutation.isLoading
                ? "Please wait…"
                : "Sign in"}
            </Button>
          </Form>

          {process.env?.REACT_APP_DEPLOYMENT_TYPE === "shared" && (
            <p className="my-3">
              Don't Have An Account?{" "}
              <Link to={"/register"} className="text-primary">
                Sign Up
              </Link>
            </p>
          )}
        </div>

        <a
          className="owner"
          href="https://excellentbridge.com"
          target="_blank"
          rel="noreferrer"
        >
          ©{new Date().getFullYear()} ExcellentBridge Technologies - version{" "}
          {process.env.REACT_APP_VERSION}
        </a>
      </div>
      <div className="bg-primary slide-show">
        <OrnamentTwo className="ornament-two" />
        <OrnamentOne className="ornament-one" />

        <div className="image">
          {/*   <div className="slide-image-holder">{<LoginImageOne />}</div> */}
          <div className="slide-image-holder-two">
            <LoginImageTwo />
          </div>
          <div>
            <h2 className="text-white">Access insightful data</h2>
            <p>
              Get direct access to key information with customizable dashboard.{" "}
              <br /> Powerful tools for data and analytics, integration and AI.
            </p>
          </div>

          <SlideShowIndex />
        </div>
      </div>
      {showSendPasswordResetLinkModal && (
        <SendPasswordResetLink
          backendUrl={
            backendApis.find((el) => el.name === formik.values.company)?.url
          }
          showSendPasswordResetLinkModal={showSendPasswordResetLinkModal}
          setShowsSendPasswordResetLinkModal={
            setShowsSendPasswordResetLinkModal
          }
          company={formik.values.company}
        />
      )}
    </div>
  );
}
