import { format } from "date-fns";
import { Modal, Table, Form, Button } from "react-bootstrap";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { services } from "../../config";
import { cacheTimes, queryActions, requisitionActions } from "../../utils/reactQueryActions";
import { ApproveModalIcon, FileLargeIcon } from "../Icons";
import PaperClipIcon from "mdi-react/PaperclipIcon";
import { truncate, uniq } from "lodash";
import { useFormik, getIn } from "formik";
import * as yup from "yup";
import { Fragment, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import eventBus from "../../utils/EventBus";
import { last } from "lodash";
import { useCanGoBack } from "../../utils/hooks";
import { styleHelper } from "../../utils/helpers";
import moment from "moment";
import { isEmpty, uniqBy, lowerCase, size } from "lodash";
import Select from "react-select";
import { useStoreState } from "easy-peasy";
import ConfirmDialog from "../ConfirmDialogue";
import AccountPlusIcon from "mdi-react/AccountPlusIcon";
import StaffInviteModal from "../utils/StaffInviteModal";

export default function ApproveAndSendModal({
  location,
  requestid,
  setShowApproveAndSendModal,
  refetchRequisition,
}) {
  const generalSettings = useStoreState(state => state.generalSettings);
  const { user: authUser, backendUrl } = useAuth();
  // const queryClient = useQueryClient();
  const goBack = useCanGoBack();
  let { id: requisitionid } = useParams();
  const id = useMemo(() => {
    return requisitionid && requisitionid.includes("REQ") ? requisitionid : requestid;
  }, [requestid, requisitionid]);

  const [reciever, setReceiver] = useState();
  const [showStaffInviteModal, setShowStaffInviteModal] = useState(false);

  const getNextUserToPerformAction = requisition => {
    const requisitiontracks = requisition.requisitiontracks;
    //---------------------------------------------------------------------
    let permissions = [];
    const page = JSON.parse(requisition.filledFormData)[0];
    const signatories = page.children
      .filter(el => el?.type === "signatoryElement")
      .map(el => el.props.fields);
    if (signatories) {
      permissions = signatories.flat().map(el => el.permission);

      if (permissions) {
        //  user we want  to  send  to
        const lastTrack = requisitiontracks.length + 1;
        setReceiver(permissions[lastTrack]);
        formik.setFieldValue("department", permissions[lastTrack]?.department);
        formik.setFieldValue("recipient", permissions[lastTrack]?.staff);
        formik.setFieldValue("jobGrade", permissions[lastTrack]?.jobGrade);
      }
    }
  };

  const getNextUserToPerformActionForSystemForm = requisition => {
    const requisitiontracks = requisition?.requisitiontracks;
    let permissions = JSON.parse(requisition?.preSelectedSignatory);

    if (permissions) {
      //  user we want  to  send  to
      const lastTrack = requisitiontracks.length + 1;

      setReceiver(permissions[lastTrack]);
      formik.setFieldValue("department", permissions[lastTrack]?.department);
      formik.setFieldValue("recipient", permissions[lastTrack]?.staff);
      formik.setFieldValue("jobGrade", permissions[lastTrack]?.jobGrade);
    }
  };

  const getRequisitionDetails = async id => {
    let response = await fetch(`${backendUrl}/api/requisition/${id}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    if (data.requisition?.filledFormData) {
      getNextUserToPerformAction(data.requisition);
    } else if (data.requisition?.preSelectedSignatory) {
      getNextUserToPerformActionForSystemForm(data.requisition);
    } else if (["fund request", "material request"].includes(lowerCase(data.requisition?.type))) {
      getNextUserToPerformAction(data.requisition);
    }

    return data;
  };
  const { data: { requisition } = { requisition: null } } = useQuery(
    [requisitionActions.GET_REQUISITION_DETAILS, id],
    () => getRequisitionDetails(id),
    {}
  );

  const getDepartments = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/users/departments`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.departments = [
      { label: "Select Recipient's Department", value: "" },
      ...data.departments.map(el => ({
        ...el,
        label: el.Department,
        value: el.Department,
      })),
    ];
    return data;
  };
  const { data: { departments } = { departments: [] } } = useQuery(
    [queryActions.GET_DEPARTMENTS],
    () => getDepartments(),
    {}
  );

  const requisitionAction = async () => {
    let response = await fetch(`${backendUrl}/api/requisition/action`, {
      method: "POST",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        previousRequisitionTrackId: last(requisition?.requisitiontracks).ID,
        requestid: requisition?.requestid,
        sentby: authUser.Staff_ID,
        receivedby: formik.values.recipient,
        status: "Approved & Sent",
        // format(new Date(), "yyyy-MM-dd"),
        regdate: moment(),
        comment: formik.values.message,
        department: formik.values.department,
        isReservedUser: Boolean(canReservedUserSend),
        recipientEmails: formik.values.recipientEmails,
      }),
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };
  const { isFetching: isSubmitting, refetch: submit } = useQuery(
    [requisitionActions.ACTION],
    () => requisitionAction(),
    {
      enabled: false,
      onError: () => toast.error("Unable to Approve & send"),
      onSuccess: data => {
        toast.success("Requisition Approved & sent");
        eventBus.dispatch("REQUISITION_UPDATED", data.requisition);
        if (refetchRequisition) {
          refetchRequisition();
        }
        if (setShowApproveAndSendModal) {
          return setShowApproveAndSendModal(false);
        }

        goBack("/requisition/request");
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      department: "",
      recipient: "",
      jobGrade: "",
      message: "",
      recipientEmails: [],
      selectedUsers: [],
    },
    validationSchema: yup.object().shape({
      //  department: yup.string().required(),
      // recipient: yup.string().required(),
      message: yup.string(),
    }),
    onSubmit: async values => {
      if (
        isEmpty(values.recipient) &&
        isEmpty(values.recipientEmails) &&
        !(await ConfirmDialog({
          title: `Recipient not Selected`,
          description: "Continue?",
        }))
      ) {
        return;
      }

      submit();
    },
  });

  const getUsersInDepartments = async department => {
    let response = await fetch(`${backendUrl}/api/users/by-department/${department}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.users = [
      { label: "Select Recipient", value: "" },
      ...data.users
        .map(el => ({
          ...el,
          label: `${el?.changeableId ? el?.changeableId : el.Staff_ID} - ${el.Name}`,
          value: el.Staff_ID,
        }))
        .filter(el => el.Staff_ID !== authUser.Staff_ID),
    ];
    return data;
  };
  const {
    data: { users } = { users: [] },
    refetch: getUsers,
    isFetching: isLoadingUsers,
  } = useQuery(
    [queryActions.GET_USERS_IN_DEPARTMENTS, formik.values.department],
    () => getUsersInDepartments(formik.values.department),
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (formik.values.department) {
      getUsers();
    }
  }, [formik.values.department]);

  const canReservedUserSend = useMemo(() => {
    if (!requisition) return false;

    const numberOfMandatoryApprovalsBeforeApproveAndClose = Number(
      generalSettings.numberOfMandatoryApprovalsBeforeApproveAndClose
    );

    // Reserved Users - Mandatory Signatories
    const reservedUsers = generalSettings.fundRequestMandatorySignatory.map(el => el.staff);

    const usersThatHaveApproved = uniq(requisition?.requisitiontracks.map(el => el.sentby));

    // How many mandatory has Approved
    const howManyMandatoryUserHasApproved = size(
      usersThatHaveApproved.filter(el => reservedUsers.includes(el))
    );

    // Has User Approved Before
    const hasUserApproved = usersThatHaveApproved.find(el => el === authUser.Staff_ID);

    return (
      !hasUserApproved &&
      Number(howManyMandatoryUserHasApproved) <
        Number(numberOfMandatoryApprovalsBeforeApproveAndClose) &&
      reservedUsers.includes(authUser?.Staff_ID) &&
      requisition.type === "Fund Request" &&
      !requisition.status.includes("Approved & Closed") &&
      !requisition.status.includes("Disapproved")
    );
  }, [requisition]);

  const canPerformAction = () => {
    const pendingRequisition = last(requisition?.requisitiontracks);

    // By Department
    if (
      pendingRequisition?.sentToDepartment === authUser.Department &&
      pendingRequisition?.sentby !== authUser.Staff_ID &&
      pendingRequisition?.status === "Pending"
    ) {
      return true;
    }

    return (
      pendingRequisition &&
      pendingRequisition?.status === "Pending" &&
      pendingRequisition?.receivedby === authUser.Staff_ID
    );
  };

  //----------------------------------------------------------------------
  // fetch data for form
  const getDepartmentsForForm = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/users/departments-with-users`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    data = {
      departments: [],
      staffs: [],
      jobGrades: [],
      departmentWithLeads: [],
    },
    isFetching,
  } = useQuery([queryActions.GET_USERS_WITH_DEPARTMENTS], () => getDepartmentsForForm(), {
    enabled: true,
    keepPreviousData: true,
    cacheTime: cacheTimes.GET_USERS_WITH_DEPARTMENTS,
  });

  const staffSelectOptions = useMemo(() => {
    const jobGrades = data.staffs
      .filter(el => el.Department === formik.values.department)
      .map(el => ({ jobGrade: el.jobGrade }));

    const staffs = data.staffs.filter(el => {
      if (!formik.values.department) return true;

      if (!formik.values.department && !formik.values.jobGrade) return true;

      if (formik.values.department === "Department Lead") {
        const myDepartmentLead = data.departmentWithLeads
          .filter(el => el.Dept_Lead && el.Dept_Name === authUser.Department)
          .map(el => JSON.parse(el.Dept_Lead))
          .flat();

        return myDepartmentLead.find(lead => lead.Staff_ID === el.Staff_ID);
      }

      if (formik.values.department && !formik.values.jobGrade) {
        return el.Department === formik.values.department;
      }

      if (formik.values.department && formik.values.jobGrade) {
        return el.Department === formik.values.department && el.jobGrade === formik.values.jobGrade;
      }
    });

    return {
      departments: [
        { label: "Any Department", value: "" },
        { label: "Department Lead", value: "Department Lead" },
        ...data.departments.map(el => ({
          label: el.Department,
          value: el.Department,
        })),
      ],
      jobGrades: [
        { label: "Any Job Grade", value: "" },
        ...uniqBy(jobGrades, "jobGrade").map(el => ({
          label: el.jobGrade,
          value: el.jobGrade,
        })),
      ],
      staffs: [
        { label: "Any Staff", value: "" },
        ...staffs.map(el => ({
          label: `${el?.changeableId ? el?.changeableId : el.Staff_ID} - ${el.Name}`,
          value: el.Staff_ID,
        })),
      ],
    };
  }, [data, formik.values.department, formik.values.jobGrade]);

  const handleSelectionCompleted = selectedUsers => {
    formik.setFieldValue("recipientEmails", []);
    formik.setFieldValue("selectedUsers", selectedUsers);
    formik.setFieldValue("department", selectedUsers[0].Department);
    formik.setFieldValue("jobGrade", selectedUsers[0].jobGrade);
    formik.setFieldValue("recipient", selectedUsers[0].Staff_ID);
    // console.log(selectedUsers)
  };

  const handleSelectedEmail = email => {
    formik.setFieldValue("recipientEmails", [email]);
    formik.setFieldValue("selectedUsers", []);
  };

  return (
    <>
      <Modal
        show={true}
        onHide={() => {
          if (setShowApproveAndSendModal) {
            return setShowApproveAndSendModal(false);
          }
          goBack("/requisition/request");
        }}
        dialogClassName="requisition-details-modal"
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        fullscreen={styleHelper.isMobile}
      >
        <Modal.Header closeButton />

        <Modal.Body className="approve-action">
          <Form
            noValidate
            onSubmit={formik.handleSubmit}
            /*  style={!canPerformAction() ? { pointerEvents: "none" } : {}} */
          >
            <div className="text-info-h">
              <ApproveModalIcon className="mb-4" />
              <h2>Approve and Send Requisition</h2>
              <p>are you sure you want to approve and send the Requisition ?</p>
            </div>
            <div className="border rounded">
              <h3 className="border-bottom mb-0 p-3">
                <FileLargeIcon className="me-3" />
                <span> {requisition?.title}</span>
              </h3>

              <div className="d-md-flex p-3">
                <Table borderless className="info-table mb-0 mb-md-3">
                  <tbody>
                    <tr className="text-dark">
                      <td>Type:</td>
                      <td>
                        {requisition?.type} <br />
                        <span className="text-light d-block mt-1" title={requisition?.title}>
                          {truncate(requisition?.title, { length: 30 })}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table borderless className="info-table">
                  <tbody>
                    <tr>
                      <td>ID requisition :</td>
                      <td>#{id}</td>
                    </tr>
                    <tr>
                      <td>Date:</td>
                      <td>
                        {requisition?.regdate
                          ? format(new Date(requisition?.regdate), "dd / MM / yyyy")
                          : "..."}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="mt-4 py-4 border-top">
              <div className={`${authUser.account_type === 1 ? "d-none" : ""}`}>
                {/* OR PRE_SELECTED  */}
                {requisition?.filledFormData ||
                ["fund request", "material request"].includes(lowerCase(requisition?.type)) ||
                requisition?.preSelectedSignatory ? (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>Department :</Form.Label>
                      <Select
                        classNamePrefix={`form-select`}
                        name="department"
                        options={staffSelectOptions?.departments}
                        value={staffSelectOptions?.departments.find(
                          el => el.value === formik.values.department
                        )}
                        onChange={({ value }) => {
                          formik.setFieldValue("department", value);

                          // clear others
                          formik.setFieldValue("jobGrade", "");
                          formik.setFieldValue("recipient", "");
                        }}
                        isDisabled={reciever?.department}
                      />
                      {formik.touched.department && formik.errors.department ? (
                        <span className="text-danger mt-2">{formik.errors.department}</span>
                      ) : null}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Job Grade :</Form.Label>
                      <Select
                        classNamePrefix={`form-select`}
                        name="jobGrade"
                        options={staffSelectOptions?.jobGrades}
                        value={staffSelectOptions?.jobGrades.find(
                          el => el.value === formik.values.jobGrade
                        )}
                        onChange={({ value }) => {
                          formik.setFieldValue("jobGrade", value);

                          // clear others
                          formik.setFieldValue("recipient", "");
                        }}
                        isDisabled={
                          reciever?.jobGrade ||
                          reciever?.staff ||
                          reciever?.department === "Department Lead"
                        }
                      />
                      {formik.touched.jobGrade && formik.errors.jobGrade ? (
                        <span className="text-danger mt-2">{formik.errors.jobGrade}</span>
                      ) : null}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Recipient :</Form.Label>
                      <Select
                        classNamePrefix={`form-select`}
                        name="recipient"
                        options={staffSelectOptions?.staffs}
                        value={staffSelectOptions?.staffs.find(
                          el => el.value === formik.values.recipient
                        )}
                        onChange={({ value }) => formik.setFieldValue("recipient", value)}
                        isDisabled={isFetching || reciever?.staff}
                      />
                      {formik.touched.recipient && formik.errors.recipient ? (
                        <span className="text-danger mt-2">{formik.errors.recipient}</span>
                      ) : null}
                    </Form.Group>
                  </>
                ) : (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>Department :</Form.Label>
                      <Select
                        classNamePrefix={"form-select"}
                        name="department"
                        options={departments}
                        value={departments.find(el => el.value === formik.values.department)}
                        onChange={selected => {
                          formik.setFieldValue("department", selected?.value);
                        }}
                      />
                      {formik.touched.department && formik.errors.department ? (
                        <span className="text-danger mt-2">{formik.errors.department}</span>
                      ) : null}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Recipient :</Form.Label>
                      <Select
                        classNamePrefix={"form-select"}
                        name="recipient"
                        options={users}
                        value={users.find(el => el.value === formik.values.recipient)}
                        onChange={selected => formik.setFieldValue("recipient", selected?.value)}
                        isDisabled={isLoadingUsers}
                      />

                      {formik.touched.recipient && formik.errors.recipient ? (
                        <span className="text-danger mt-2">{formik.errors.recipient}</span>
                      ) : null}
                    </Form.Group>
                  </>
                )}
              </div>

              {authUser?.account_type === 1 ? (
                <div className="d-flex gap-3 align-items-center mb-3">
                  <Button
                    variant="outline-primary"
                    type="button"
                    onClick={() => setShowStaffInviteModal(true)}
                  >
                    <AccountPlusIcon /> Add Signatory
                  </Button>

                  <p>
                    {formik.values.recipientEmails
                      ? formik.values.recipientEmails.map(el => el).join(", ")
                      : ""}
                  </p>
                  <p>
                    {formik.values.selectedUsers
                      ? formik.values.selectedUsers
                          .map(el => `${el.Name} (${el.username})`)
                          .join(", ")
                      : ""}
                  </p>
                </div>
              ) : null}

              <Form.Group className="mb-3">
                <Form.Label>Message :</Form.Label>
                <label className="border p-0 rounded pb-2 message-area d-block">
                  <Form.Control
                    name="message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    as="textarea"
                    className="w-100 border-0 p-3"
                    placeholder="Enter your detail description"
                    rows={4}
                  />
                  {/*
                <Button variant="white" className="text-light mx-1">
                  <PaperClipIcon />
                </Button> */}
                </label>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        {(canPerformAction() || canReservedUserSend) && (
          <Modal.Footer>
            <span />
            <div className="proceed-actions">
              <Button
                disabled={isSubmitting}
                type="button"
                variant="white"
                className="border bg-white"
                onClick={() => {
                  if (setShowApproveAndSendModal) {
                    return setShowApproveAndSendModal(false);
                  }
                  goBack("/requisition/request");
                }}
              >
                Cancel
              </Button>
              <Button disabled={isSubmitting} onClick={() => formik.submitForm()} variant="primary">
                {isSubmitting ? "Please wait…" : "Okay"}
              </Button>
            </div>
          </Modal.Footer>
        )}
      </Modal>
      <StaffInviteModal
        isMutiple={false}
        show={showStaffInviteModal}
        setShow={value => setShowStaffInviteModal(value)}
        handleSelectionCompleted={handleSelectionCompleted}
        handleSelectedEmail={handleSelectedEmail}
      />
    </>
  );
}
