import React from "react";
import { Link } from "react-router-dom";
import completed from "./assets/complete.png";

const FinalPage = () => {
  return (
    <div className="contents finalPage">
      <img src={completed} alt="completed" />
      <h1>Congratulations!</h1>
      <p>
        You have successfully completed the setup process for InvexERP. We are
        glad you found our platform useful and informative. If you have any
        questions or need further assistance, please don't hesitate to reach out
        to our <a href="mailto:support@excellentbridge.com">support team</a>.
      </p>
      <p className="extra">
        Thank you for choosing InvexERP for your business automation needs!
      </p>
      <p className="link">
        <a className="button" href="/dashboard">
          Go to the dashboard
        </a>

        <a
          className="button learn"
          href="https://invexerp.excellentbridge.com"
          target="_blank"
        >
          Learn more about InvexERP
        </a>
      </p>
    </div>
  );
};

export default FinalPage;
