import Accordion from "react-bootstrap/Accordion";
import { useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Dropdown, Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { useAuth } from "../../hooks/useAuth";
import { uniq, uniqBy } from "lodash";
import { useStoreState } from "easy-peasy";
import { PrintIcon } from "../Icons";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import CachedIcon from "mdi-react/CachedIcon";
import currency from "currency.js";
import { formatForQty } from "../../utils/helpers";

function PurchaseOrderDistributionModal({
  purchaseOrder,
  showPurchaseOrderDistributionModal,
  setShowPurchaseOrderDistributionModal,
  POIdUnavailable,
}) {
  const { backendUrl } = useAuth();

  const generalSettings = useStoreState(state => state.generalSettings);
  const items = [];
  const vendor = {};

  // ----

  const getDistributedItems = async () => {
    let response = await fetch(
      POIdUnavailable
        ? `${backendUrl}/api/vendors/distribution-po-items?requestid=${
            purchaseOrder.requestid
          }&vendor=${purchaseOrder.vendor}&barcode=${
            purchaseOrder.barcode
          }&withVendor=true&withPOitems=true&withMainPO=true`
        : `${backendUrl}/api/vendors/distribution-po-items?POId=${
            purchaseOrder.POId
          }&withVendor=true&withPOitems=true`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const data = await response.json();
    return data;
  };

  const { data, refetch, isFetching, isSuccess } = useQuery(
    ["DISTRIBUTED_ITEMS"],
    () => getDistributedItems(),
    {
      enabled: true,
      keepPreviousData: false,
    }
  );

  const distribution = useMemo(() => {
    if (data?.items) {
      return uniqBy(data.items, "issueTo").map(el => {
        const items = data?.items
          .filter(item => item?.issueTo === el.issueTo)
          .map(item => ({
            ...data.poItems.find(poItem => poItem.barcode === item.barcode),
            ...item,
          }));

        return {
          ...el,
          receivingofficer: uniq(items?.map(el => el.receivingofficer)).join(", "),
          items: items.map(el => ({
            ...el,
            SubTotal: currency(el.quantity).multiply(el.amount).value,
          })),
          totalAmount: items.map(el => el.amount).reduce((a, b) => currency(a).add(b).value),
          totalQty: items.map(el => el.quantity).reduce((a, b) => currency(a).add(b).value),
          total: items
            .map(el => currency(el.amount).multiply(el.quantity))
            .reduce((a, b) => currency(a).add(b).value),
        };
      });
    }
    return [];
  }, [data?.items]);

  return (
    <Modal
      show={showPurchaseOrderDistributionModal}
      onHide={() => setShowPurchaseOrderDistributionModal(false)}
      backdrop="static"
      keyboard={false}
      centered
      size="xl"
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="d-flex gap-3">
            <h1 className="h5 d-flex gap-3 align-items-center">PO Distribution </h1>{" "}
            <Button variant="" onClick={() => refetch()}>
              <CachedIcon />
            </Button>
          </div>
        </Modal.Title>
        <p />
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex gap-3">
          <div className="d-flex w-75">
            <Table borderless striped>
              <tr>
                <td class="fw-bold">Title:</td>
                <td>{POIdUnavailable ? data?.mainPO?.POName : purchaseOrder?.POName || "..."}</td>
              </tr>
              <tr>
                <td class="fw-bold">Prepared By:</td>
                <td class="text-nowrap">
                  {POIdUnavailable ? data?.mainPO?.Username : purchaseOrder?.Username || "..."}
                </td>
              </tr>
              <tr>
                <td class="fw-bold">Vendor:</td>
                <td>{data?.vendor?.CompanyName || "..."}</td>
              </tr>
              <tr>
                <td class="fw-bold">Phone No:</td>
                <td>{data?.vendor?.Phone || "..."}</td>
              </tr>
            </Table>{" "}
            <Table borderless striped>
              <tr>
                <td class="fw-bold">PO Number:</td>
                <td>{POIdUnavailable ? data?.mainPO?.POId : purchaseOrder?.POId || "..."}</td>
              </tr>
              <tr>
                <td class="fw-bold">Email:</td>
                <td>{data?.vendor?.Email || "..."}</td>
              </tr>
              <tr>
                <td class="fw-bold">Address:</td>
                <td>
                  {data?.vendor?.CompanyAddress} {data?.vendor?.City}
                </td>
              </tr>
            </Table>
          </div>

          {/*  <a
            href={`${backendUrl}/api/invoice/pdf/view-system-po-all/${
              purchaseOrder.POId
            }`}
            target="_blank"
            className="btn"
          >
            <PrintIcon /> Print Purchase Order Supply Details
          </a> */}
        </div>

        <hr />

        {distribution.map((dist, index) => (
          <Accordion key={dist.issueTo} defaultActiveKey={["0"]} className="my-4">
            <Accordion.Item eventKey={`${index}`}>
              <Accordion.Header>
                {dist.issueTo} - {dist?.warehouseName ? `${dist?.warehouseName} - ` : ""}{" "}
                {dist.receivingofficer}
              </Accordion.Header>
              <Accordion.Body className="p-1">
                <div className="d-flex gap-3 mb-2">
                  <a
                    href={`${backendUrl}/api/invoice/pdf/view-system-po/${
                      POIdUnavailable ? data?.mainPO?.POId : purchaseOrder.POId
                    }?issueTo=${dist.issueTo}`}
                    target="_blank"
                    className="btn print"
                  >
                    <PrintIcon /> Print
                  </a>

                  <p>{dist?.issueAddress || "..."}</p>
                </div>

                <Table responsive borderless striped className="product-table">
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Quantity</th>
                      <th>Amount</th>
                      <th>Total</th>
                      {generalSettings?.pharmacySetting && (
                        <>
                          <th className="d-none">Generic Configuration</th>
                          <th className="d-none">Branded Generic</th>
                          <th>Pack Size</th>
                          <th>Tier</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {dist.items?.map((el, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{el?.barcode}</td>
                          <td>{el?.itemName}</td>
                          <td>
                            {currency(el?.quantity, {
                              symbol: "",
                              precision: 2,
                              format: formatForQty,
                            }).format()}
                          </td>
                          <td>
                            {currency(el?.amount, {
                              symbol: "",
                              precision: 2,
                            }).format()}
                          </td>
                          <td>
                            {currency(el?.SubTotal, {
                              symbol: "",
                              precision: 2,
                            }).format()}
                          </td>
                          {generalSettings?.pharmacySetting && (
                            <>
                              <td className="d-none">{el?.Generic_Configuration || "..."}</td>
                              <td className="d-none">{el?.Branded_Generic || "..."}</td>
                              <td>{el?.Pack_Size || "..."}</td>
                              <td>{el?.Tier || "..."}</td>
                            </>
                          )}
                        </tr>
                      ))}
                    </>
                  </tbody>
                  <tfoot className="border-top">
                    <tr>
                      <td> </td>
                      <td> </td>
                      <td>
                        <b>Total</b>{" "}
                      </td>

                      <td>
                        {currency(dist.totalQty, {
                          symbol: "",
                          precision: 2,
                          format: formatForQty,
                        }).format()}
                      </td>
                      <td>
                        {currency(dist.totalAmount, {
                          symbol: "",
                          precision: 2,
                        }).format()}
                      </td>
                      <td>
                        {currency(dist.total, {
                          symbol: "",
                          precision: 2,
                        }).format()}
                      </td>
                      {generalSettings?.pharmacySetting && (
                        <>
                          <td className="d-none"> </td>
                          <td className="d-none"> </td>
                          <td> </td>
                          <td> </td>
                        </>
                      )}
                    </tr>
                  </tfoot>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowPurchaseOrderDistributionModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PurchaseOrderDistributionModal;
