import { Dropdown, Table, Form, Button, InputGroup } from "react-bootstrap";
import { CSVLink } from "react-csv";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  RecieptIcon,
  PageHeaderListIcon,
  BookIcon,
  ExcelIcon,
  PDFIcon,
  NoSelectedItemIcon,
  DeleteIcon,
  LinkVariantIcon,
  EditIcon,
  CheckCircleIcon,
  CancelX,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useIsAdmin,
  useIsCashier,
  useIsSalesManager,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { appSettings, services } from "../../config";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import { useQuery, useMutation } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  copyText,
  customerFullName,
  defaultSelectValue,
  formatDate,
  paginationOptions,
  reactSelectTheme,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { useAuth } from "../../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import CustomerSelector from "../utils/CustomerSelector";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { lowerCase } from "lodash";
import { isEmpty } from "lodash";
import NoTableItem from "../utils/NoTableItem";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import TableComponent from "../TableComponent";
import { toast } from "react-toastify";
import ConfirmDialog from "../ConfirmDialogue";
import ViewOrdersModal from "../CustomerDetails/ViewOrdersModal";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import { useStoreState } from "easy-peasy";
import ChangeStoreOrderStatusDialog from "../ChangeStoreOrderPaymentStatus";

const options = [
  { label: "Pending", value: "Pending" },
  { label: "Approved", value: "Approved" },
  { label: "Invoiced", value: "Invoiced" },
  { label: "Disapproved", value: "Disapproved" },
];

export default function HMBProforma({ Status = "" }) {
  useScrollTop();
  const navigate = useNavigate();
  const initialFilterParams = {
    customerName: "",
    customerId: "",
    Status,
  };

  const { backendUrl, user: authUser } = useAuth();
  const isCashier = useIsCashier();
  const isAdmin = useIsAdmin();
  const isSalesManager = useIsSalesManager();
  const [activeRow, setActiveRow] = useState(null);
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [excelData, setExcelData] = useState([]);
  const [showTransactionPopover, setShowTransactionPopover] = useState(false);
  const CSVLinkRef = useRef(null);
  const [orderTransactionID, setOrderTransactionID] = useState();
  const generalSettings = useStoreState(state => state.generalSettings);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(excelData, CSVLinkRef);

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const getPermits = async debouncedFilterParams => {
    debouncedFilterParams.Staff_ID = authUser.Staff_ID;

    let response = await fetch(
      `${backendUrl}/api/permits/all?${queryString.stringify(debouncedFilterParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    data.sentByUsers = data?.sentByUsers.map(el => ({
      ...el,
      label: el.UserName,
      value: el.UserName,
    }));
    return data;
  };

  const { data = { permits: [] }, refetch, isFetching, isSuccess } = useQuery(
    [queryActions.ALL_PERMITS, debouncedFilterParams],
    () => getPermits(debouncedFilterParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/permits/all?${queryString.stringify(rest)}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;
    exData = exData.data.permits.map(row => ({
      ...row,
      Post_Time: format(new Date(row.Post_Time), "dd-MMM-yyyy hh:mm:ss a"),
    }));

    exData = exData.map(d => [
      d.customer.LastName,
      d.Post_Time,
      d.DocType,
      d.Trans_ID,
      d.PaymentType,
      d.Debit,
      d.Credit,
      d.Username,
      d.InvoiceCat,
    ]);
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [company],
      ["Customer Invoices and Payments Report"],
      [date],
      [""],
      [
        "Business Name",
        "Trans Date",
        "Doc Type",
        "Trans ID",
        "Payment Type",
        "Debit Amount",
        "Credit Amount",
        "Username",
        "InvoiceCat",
      ],
      ...exData,
      [""],
      ["Summary"],
      [
        "Total Invoice Amount",
        currency(data?.totalInvoiceAmt, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Payment Amount",
        currency(data?.totalPaymentAmt, {
          symbol: "",
        }).format(),
      ],
      [
        "Balance (LCY)",
        currency(data?.balance, {
          symbol: "",
        }).format(),
      ],
      [
        "Reversed Invoice Amount",
        currency(data?.reversedInvoiceAmount, {
          symbol: "",
        }).format(),
      ],
      [
        "Reversed Payment Amount",
        currency(data?.reversedPaymentAmount, {
          symbol: "",
        }).format(),
      ],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  const handleFilterParamsChange = e => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = e => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = date => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedCustomer = customer => {
    setFilterParams({
      ...filterParams,
      customerId: customer.Cust_ID,
      customerName: customerFullName(customer),
    });
    setShowItemsPopover(false);
  };

  const openProforma = (el, thermalPrinter = true) => {
    window.open(
      `${backendUrl}/api/invoice/pdf/proforma-invoice/${
        el.TransactionID
      }?thermalPrinter=${thermalPrinter}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const changePaymentStatus = async el => {
    await ChangeStoreOrderStatusDialog({
      TransactionID: el.TransactionID,
      paymentStatus: el.paymentStatus,
      refetch,
      customerName:
        el?.customer?.LastName === "Walk-In" && el?.ShipTo
          ? `${el?.ShipTo} (Walk-In)`
          : customerFullName(el.customer),
      oldPaymentStatus: el.paymentStatus,
    });
  };

  const openQuotation = el => {
    window.open(
      `${backendUrl}/api/invoice/pdf/quotation/${el.TransactionID}?thermalPrinter=false`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const updateAllPermit = async payload => {
    let response = await fetch(`${backendUrl}/api/permits/change-status-all`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const updatePermitsMutation = useMutation(payload => updateAllPermit(payload), {
    onSuccess: ({ status, transactionIds }) => {
      refetch();
      toast.success(`Success`);
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const approve = async ({ action, TransactionID = "" }) => {
    const { proceed, comment } = await ConfirmDialog({
      title: action,
      description: `Are you sure you want to ${action}`,
      withComment: true,
    });
    if (proceed) {
      updatePermitsMutation.mutate({
        status: "Approved",
        transactionIds: [TransactionID],
        comment,
      });
    }
  };

  const reject = async ({ action, TransactionID }) => {
    const { proceed, comment } = await ConfirmDialog({
      title: action,
      description: `Are you sure you want to ${action}`,
      withComment: true,
      type: "danger",
    });
    if (proceed) {
      updatePermitsMutation.mutate({
        status: "Disapproved",
        transactionIds: [TransactionID],
        comment,
      });
    }
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th />
          <th>Trans. ID</th>
          <th>Business Name</th>
          <th>Total Value</th>
          <th>Status</th>
          <th>Sent By</th>
          <th>Date</th>
        </tr>
      </thead>
    );
  };

  const tableData = (el, index) => {
    return (
      <>
        <td>
          {" "}
          <Dropdown style={{ margin: 0 }}>
            <Dropdown.Toggle variant="" className="text-primary" bsPrefix="print more">
              <DotsVerticalIcon />
            </Dropdown.Toggle>

            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
              className="dropdown-with-icons"
            >
              {el?.acceptedByCustomerStatus === "Accepted" && (
                <Dropdown.Item as="button" onClick={() => openQuotation(el)}>
                  <RecieptIcon /> View Quotation
                </Dropdown.Item>
              )}
              <Dropdown.Item as="button" onClick={() => openProforma(el, false)}>
                <RecieptIcon /> View Proforma A4 Size
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => openProforma(el, true)}>
                <RecieptIcon /> View Proforma A4 Thermal Printer
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => setOrderTransactionID(el.TransactionID)}>
                <EyeOutlineIcon /> View Items
              </Dropdown.Item>

              {el.Status === "Pending" ? (
                <Dropdown.Item as="div">
                  <Link to={`/approval/details/${el.TransactionID}`}>
                    <EditIcon /> Edit
                  </Link>
                </Dropdown.Item>
              ) : null}

              {!["Approved", "Invoiced"].includes(el.Status) && (
                <Dropdown.Item
                  as="button"
                  onClick={() =>
                    approve({
                      ...el,
                      action: "Approve",
                    })
                  }
                >
                  <CheckCircleIcon /> Approve
                </Dropdown.Item>
              )}

              <Dropdown.Item
                as="button"
                className="text-danger"
                onClick={() =>
                  reject({
                    ...el,
                    action: "Reject",
                  })
                }
              >
                <CancelX /> Reject
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
        <td>{el.TransactionID}</td>
        <td>{customerFullName(el.customer)}</td>
        <td>
          {currency(el.totalValue, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {el.Status}
          {el?.comment ? (
            <i className="d-block">
              <b>Comment:</b> {el?.comment}
            </i>
          ) : null}
        </td>
        <td>{el.UserName}</td>
        <td className="text-nowrap">{el?.Date_Log ? formatDate(el.Date_Log) : "..."}</td>
      </>
    );
  };

  const manageTransaction = (event, el) => {};

  const deletePermitApi = async payload => {
    let response = await fetch(`${backendUrl}/api/permits/delete`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const deletePermitMutation = useMutation(payload => deletePermitApi(payload), {
    onSuccess: ({ status, transactionIds }) => {
      refetch();
      toast.success(`Success`);
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const deletePermit = async el => {
    if (
      await ConfirmDialog({
        title: "Delete",
        description: "Are you sure, you want to delete",
      })
    ) {
      deletePermitMutation.mutate({
        transactionIds: [el.TransactionID],
      });
    }
  };

  return (
    <main className="cash-book">
      <div className="content">
        <div className="content-main">
          <div className={`filter-area ${showFilter ? "open" : "close"}`}>
            <div className="filter-header">
              <h2>
                <FilterTwoIcon /> Filters
              </h2>

              <Button variant="white" onClick={() => setShowFilter(!showFilter)}>
                <FilterCollapseIcon />
              </Button>
            </div>
            <div className="filter-body">
              <Form>
                <div className="body">
                  <Form.Group className="mb-2-5">
                    <Form.Label>Transaction ID</Form.Label>{" "}
                    <InputGroup>
                      <Form.Control
                        name="q"
                        value={filterParams?.q}
                        placeholder="Enter Transaction ID"
                        onChange={e => handleFilterParamsChange(e)}
                      />{" "}
                      <InputGroup.Text>
                        <MagnifyIcon />
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-2-5">
                    <Form.Label>Status</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      placeholder="Status"
                      isSearchable={false}
                      onChange={({ value }) =>
                        setFilterParams({
                          ...filterParams,
                          page: 1,
                          Status: value,
                        })
                      }
                      value={options.find(el => el.value === filterParams.Status)}
                      options={options}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2-5">
                    <Form.Label>Sent By</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      placeholder="Sent By"
                      isSearchable={false}
                      onChange={({ value }) =>
                        setFilterParams({
                          ...filterParams,
                          page: 1,
                          UserName: value,
                        })
                      }
                      value={data?.sentByUsers?.find(el => el.value === filterParams.UserName)}
                      options={data?.sentByUsers || []}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2-5">
                    <Form.Label>Customer ID</Form.Label>
                    <InputGroup>
                      <Form.Control
                        name="customerId"
                        placeholder="Enter Customer ID"
                        value={filterParams?.customerId}
                        onChange={e => handleFilterParamsChange(e)}
                      />
                      <Popover
                        isOpen={showItemsPopover}
                        onClickOutside={() => setShowItemsPopover(false)}
                        content={() => (
                          <CustomerSelector
                            handleSelectedCustomer={handleSelectedCustomer}
                            setShowCustomerSelector={setShowItemsPopover}
                          />
                        )}
                        position="bottom"
                      >
                        <InputGroup.Text onClick={() => setShowItemsPopover(!showItemsPopover)}>
                          <MagnifyIcon />
                        </InputGroup.Text>
                      </Popover>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-2-5">
                    <Form.Label>Business Name</Form.Label>
                    <Form.Control
                      name="customerName"
                      value={filterParams?.customerName}
                      placeholder="Enter Business Name"
                      onChange={e => handleFilterParamsChange(e)}
                    />
                  </Form.Group>
                  <hr className="mt-3 mb-4" />
                  <Form.Group className="">
                    <Form.Label>Date Range</Form.Label>
                    <div className="position-relative">
                      <RsDateRangePicker
                        placement="topStart"
                        value={
                          filterParams.startDate && filterParams.endDate
                            ? [
                                parse(filterParams.startDate, "yyyy-MM-dd", new Date()),
                                parse(filterParams.endDate, "yyyy-MM-dd", new Date()),
                              ]
                            : []
                        }
                        onClean={() => clearDateRange()}
                        onOk={date => filterByDateRange(date)}
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className="buttons rounded">
                  <Button onClick={() => reset()} variant="white">
                    Reset
                  </Button>
                  <Button onClick={() => search()} variant="primary">
                    Search
                  </Button>
                </div>
              </Form>
            </div>
          </div>
          <div className="content-body">
            <header>
              <h1>
                {!showFilter && (
                  <button onClick={() => setShowFilter(!showFilter)} className="btn filter">
                    <FilterTwoIcon />
                  </button>
                )}
                {queryParams.Status} Proforma
                <button title="Refresh" onClick={() => refetch()} className="btn text-primary">
                  <CachedIcon />
                </button>
              </h1>{" "}
              {queryParams?.customerName && (
                <p>
                  Business Name: <b>{queryParams?.customerName}</b>
                </p>
              )}
              <div className="actions">
                {/* <button className="btn print">
              Filters <FilterIcon />
            </button> */}
                {/*  <CSVLink
                  className="btn print d-none"
                  filename={`Customer Invoices and Payments(${format(
                    new Date(),
                    "dd-MMM-yyyy hh:mm:ss a"
                  )}).csv`}
                  data={excelData}
                  ref={CSVLinkRef}
                />

                <Dropdown>
                  <Dropdown.Toggle
                    variant=""
                    className="btn print"
                    disabled={isfetchingExcel}
                    bsPrefix=""
                  >
                    Export
                    <ExportIcon color="#008000" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    popperConfig={{
                      strategy: "fixed",
                    }}
                    renderOnMount
                    className="text-center"
                  >
                    <Dropdown.Item
                      as="div"
                      onClick={onDownloadExcelData}
                      className="p-cursor"
                    >
                      Excel <ExcelIcon color="#008000" />
                    </Dropdown.Item>
                    <Dropdown.Item as="div">
                      <a
                        href={`${backendUrl}/api/reports/pdf/sale-by-invoice?${queryString.stringify(
                          rest
                        )}`}
                        target="_blank"
                      >
                        PDF <PDFIcon color="#ff0000" />
                      </a>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}

                {/*  <button className="btn print">
                  Print <PrintIcon />
                </button>
                <button className="btn print">
                  Export <ExportIcon />
                </button>
 */}
                {/*   <button className="btn print">
                  Open Receipt <BusinessBillIcon className="pageListIcon" />
                </button>

                <button className="btn print">
                  Open Invoice <BusinessBillIcon className="pageListIcon" />
                </button> */}

                {/*  <button
                  onClick={() => setBtnSummaryActive(true)}
                  className="btn summaryBtn "
                >
                  Show Summary
                </button> */}
              </div>
            </header>

            <div className="px-md-4">
              <TableComponent
                responsive
                borderless
                striped
                tableHeadsFunction={tableHead}
                mainDataArray={data.permits}
                tableDataRowFunction={tableData}
                className="product-table text-nowrap"
                tableRowClick={(e, el) => manageTransaction(e, el)}
              />

              {!isFetching && isSuccess && isEmpty(data?.permits) ? (
                <NoTableItem queryParams={queryParams} />
              ) : null}
            </div>

            <div className="d-flex justify-content-between px-3 align-items-center pagination">
              {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
              <div className="pagination_left">
                <p className="m-0 p-0">Show</p>
                <select
                  value={queryParams.limit}
                  name="limit"
                  className="form-select "
                  onChange={e => handleSearchQueryChange(e)}
                >
                  <option value="10">10 rows</option>
                  <option value="20">20 rows</option>
                  <option value="30">30 rows</option>
                  <option value="40">40 rows</option>
                  <option value="50">50 rows</option>
                  <option value="100">100 rows</option>{" "}
                </select>
              </div>

              <ReactPaginate
                {...paginationOptions}
                pageCount={Math.ceil(data.count / queryParams.limit)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={0}
                onPageChange={({ selected }) => {
                  document.body.scrollTop = document.documentElement.scrollTop = 0;
                  setQueryParams({
                    ...queryParams,
                    page: selected + 1,
                  });
                }}
                forcePage={queryParams.page - 1}
              />
            </div>

            {/* <div className="p-4 border-top">
          <h2>Summary</h2>
          <div className="summary">
            <table className="table table-borderless balance">
              <tbody>
                <tr>
                  <td>Total Sales</td>
                  <td>218,134,177.92</td>
                </tr>

                <tr>
                  <td>Total Profit</td>
                  <td>9,276,961.63</td>
                </tr>

                <tr>
                  <td>Total Losses</td>
                  <td>N/A</td>
                </tr>

                <tr>
                  <td>Qty Sold</td>
                  <td>19884</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}

            {/*   <div
              className={
                btnSummryActive
                  ? `summaryNew border-top open`
                  : `summaryNew border-top`
              }
            >
              <div className="summaryHeader">
                <h2>Summary</h2>
                {btnSummryActive && (
                  <button onClick={() => close()} className="btn p-0">
                    <SummaryCloseIcon />
                  </button>
                )}
              </div>

              <div className="gridCont">
                <div className="gridChild gridChildBorderLeftBlue">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.totalInvoiceAmt, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Invoice Amt</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>

                <div className="gridChild gridChildBorderLeftOrange">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.totalPaymentAmt, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Payment Amt</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>

                <div className="gridChild gridChildBorderLeftBrown">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.balance, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Balance (LCY)</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconDown />
                    <p>5%</p>
                  </div>
                </div>

                <div className="gridChild gridChildBorderLeftDarkerGreen">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.reversedInvoiceAmount, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Reversed Invoice Amt</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconDown />
                    <p>5%</p>
                  </div>
                </div>

                <div className="gridChild gridChildBorderLeftDarkGreen">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.reversedPaymentAmount, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2"> Reversed Payment Amt</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {orderTransactionID && (
        <ViewOrdersModal
          show={orderTransactionID}
          onHide={() => setOrderTransactionID(false)}
          TransactionID={orderTransactionID}
          hasLockOptions={true}
        />
      )}
      <ModalLoader show={isfetchingExcel} />
    </main>
  );
}
