import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Button, Form, Modal, Nav, Table } from "react-bootstrap";
import "./../../assets/scss/requisition/index.scss";
import {
  ApproveBlueModalIcon,
  DeleteIcon,
  EditIcon,
  MessageIcon,
  RefreshIcon,
  ApproveCheckIcon,
} from "../Icons";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { isEmpty, lowerCase, max } from "lodash";
import { format } from "date-fns";
import { requisitionActions } from "../../utils/reactQueryActions";
import Avatar from "../utils/Avatar";
import { useAuth } from "../../hooks/useAuth";
import { useBackendUrl, useEffectOnce } from "../../utils/hooks";

import { last } from "lodash";
import { LinkWithQuery as Link } from "../utils/LinkWithQuery";
import eventBus from "../../utils/EventBus";
import {
  formatDate,
  formatDateTime,
  getUserThatRequestedToMe,
  isInventoryOrFundRequest,
  resolveApprovalBadgeBg,
  styleHelper,
} from "../../utils/helpers";
import { useCanGoBack, useIsAdmin } from "../../utils/hooks";
import CachedIcon from "mdi-react/CachedIcon";
import ConfirmDialog from "../ConfirmDialogue";
import { toast } from "react-toastify";
import { useStoreState } from "easy-peasy";
import RequisitionApproveFooter from "../Requisition/RequisitionApproveFooter";
import { uniq } from "lodash";
import { size } from "lodash";
import { confirmable, createConfirmation } from "react-confirm";

function Comments({ reportComments, refetch, requisitionid }) {
  const { user: authUser, backendUrl } = useAuth();

  useEffectOnce(() => {
    refetch();
  });

  const deleteComments = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/customers/report/${payload.id}`,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteCommentsMutation = useMutation(
    (payload) => deleteComments(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        if (refetch) refetch();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const deleteComment = async (comment) => {
    if (
      await ConfirmDialog({
        title: "Delete Comment",
        description: `"${comment.comment}"`,
      })
    ) {
      deleteCommentsMutation.mutate({
        ...comment,
      });
    }
  };

  const editComment = async (comment) => {
    await createConfirmation(confirmable(EditLeaveComment))({
      oldComment: comment.comment,
      id: comment.id,
      refetch,
    });
  };

  return (
    <>
      {!isEmpty(reportComments) ? (
        <div className="comments">
          {reportComments.map((comment, index) => {
            let { date, time } = formatDateTime(comment.regdate);
            return (
              <p key={index} className=" d-flex align-items-center gap-1">
                <div className="timeline border rounded-circle">
                  <ApproveCheckIcon />
                </div>
                <b>{comment.senderDetails?.Name || "Unknown Staff"}:</b>{" "}
                <span className="flex-grow-1">{comment.comment}</span>
                <span className="text-muted fs-6 text-nowrap">
                  {time} <br /> {date}
                </span>
                {authUser.Staff_ID === comment.senderID && (
                  <span>
                    {" "}
                    <Button
                      variant=""
                      className="p-1"
                      onClick={() => editComment(comment)}
                    >
                      <EditIcon />
                    </Button>
                    <Button
                      variant=""
                      className="p-1"
                      onClick={() => deleteComment(comment)}
                    >
                      <DeleteIcon />
                    </Button>
                  </span>
                )}
              </p>
            );
          })}
        </div>
      ) : null}
    </>
  );
}

function LeaveComment({
  proceed,
  show,
  customer,
  backendUrl,
  authUser,
  previousRequisitionTrackId,
  requisitionTitle,
  refetch,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");

  const submitReport = async () => {
    try {
      let response = await fetch(`${backendUrl}/api/customers/report`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ comment, customerID: customer.Cust_ID }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        response = response.json();
        throw new Error(response.message);
      }
      response = response.json();
      toast.success(response.message);
      return response;
    } catch (error) {
      toast.error(error.message);
    } finally {
      proceed(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => !isLoading && proceed(false)}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
      fullscreen={styleHelper.isMobile}
    >
      <Modal.Header closeButton />
      <Modal.Body className="approve-action">
        <div className="text-info-h">
          <ApproveBlueModalIcon className="mb-4" />
          <h2>Feedback</h2>
        </div>
        <Form.Group className="my-4">
          <Form.Label className="d-none">Feedback :</Form.Label>
          <label className="border p-0 rounded pb-2 message-area d-block">
            <Form.Control
              name="message"
              as="textarea"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="w-100 border-0 p-3"
              placeholder="Enter your comment"
              rows={4}
              autoFocus
            />
          </label>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <span />
        <div className="proceed-actions">
          <Button
            disabled={isLoading}
            onClick={() => proceed(false)}
            variant="white"
            className="border bg-white"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => submitReport()}
            variant="primary"
          >
            {isLoading ? "Please wait" : "Send"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

function EditLeaveComment({ proceed, show, id, refetch, oldComment }) {
  const backendUrl = useBackendUrl();
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");

  useEffectOnce(() => {
    setComment(oldComment);
  });

  const editComment = async () => {
    try {
      setIsLoading(true);
      let response = await fetch(`${backendUrl}/api/customers/report`, {
        method: "PATCH",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
          comment,
        }),
      });

      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }

      const { data } = await response.json();
      toast.success("Comment Edited");
      if (refetch) refetch();
      proceed(true);
    } catch (err) {
      console.log(err);
      toast.error("Unable to Edit Comment");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      show={show}
      onHide={() => !isLoading && proceed(false)}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
      fullscreen={styleHelper.isMobile}
    >
      <Modal.Header closeButton />
      <Modal.Body className="approve-action">
        <div className="text-info-h">
          <ApproveBlueModalIcon className="mb-4" />
          <h2>Edit Comment</h2>
        </div>
        <Form.Group className="my-4">
          <Form.Label className="d-none">Comment :</Form.Label>
          <label className="border p-0 rounded pb-2 message-area d-block">
            <Form.Control
              name="message"
              as="textarea"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="w-100 border-0 p-3"
              placeholder="Enter your comment"
              rows={4}
              autoFocus
            />
          </label>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <span />
        <div className="proceed-actions">
          <Button
            disabled={isLoading}
            onClick={() => proceed(false)}
            variant="white"
            className="border bg-white"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => editComment()}
            variant="primary"
          >
            {isLoading ? "Please wait" : "Save"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default function CustomerReportModal({ customer, onClose }) {
  const generalSettings = useStoreState((state) => state.generalSettings);
  const { user: authUser, backendUrl } = useAuth();
  // const queryClient = useQueryClient();
  let { id } = useParams();
  const [activeKey, setActiveKey] = useState("feedback");
  const isAdmin = useIsAdmin();

  const handleSelect = (eventKey) => setActiveKey(eventKey);
  let navigate = useNavigate();
  const goBack = useCanGoBack();
  const [reciever, setReceiver] = useState();
  const [report, setReport] = useState([]);

  const getReport = async (id) => {
    console.log(customer);
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/customers/report/${customer.Cust_ID}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    response = await response.json();
    if (response.success) {
      console.log(response.data);
      setReport(response.data);
      return response.data;
    }
  };

  const { error, data, isSuccess, refetch, isFetching } = useQuery({
    queryKey: ["customerReport"],
    queryFn: () => getReport(id),
    staleTime: Infinity,
    keepPreviousData: true,
  });

  useEffect(() => {
    console.log(error);
  }, [error]);

  const makeComment = async () => {
    await createConfirmation(confirmable(LeaveComment))({
      customer,
      backendUrl,
    });
    refetch();
    // await LeaveCommentDialog({
    //   comment,
    // });
  };

  return (
    <Modal
      show={true}
      onHide={() => onClose(false)}
      dialogClassName="requisition-details-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      fullscreen={styleHelper.isMobile}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>
            {" "}
            Customer Feedback{" "}
            <Button onClick={() => refetch()} variant="">
              <RefreshIcon />
            </Button>
          </h1>
          <p>More information about the customer</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-end">
          <Nav
            variant="tabs"
            onSelect={handleSelect}
            // defaultActiveKey="1"
            activeKey={activeKey}
          >
            <Nav.Item>
              <Nav.Link className={`ga-nav-tab`} eventKey="feedback">
                General Feedback
              </Nav.Link>
            </Nav.Item>
            {/*
            <Nav.Item>
              <Nav.Link className={`ga-nav-tab`} eventKey="quotaion">
                Quotation
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className={`ga-nav-tab`} eventKey="po">
                P.O
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className={`ga-nav-tab`} eventKey="loadingOrder">
                Loading Order
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className={`ga-nav-tab`} eventKey="invoice">
                Invoice
              </Nav.Link>
            </Nav.Item> */}
          </Nav>
          {/*
          <Link
            to={`/requisition/request/conversations/${id}`}
            state={{ backgroundLocation: location }}
            className="btn border text-primary message-btn"
          >
            <MessageProcessingOutlineIcon />
          </Link> */}
        </div>

        {activeKey === "feedback" && (
          <>
            <div className="info p-3 my-3 border rounded">
              <p className="border-bottom pb-3 mb-3 d-flex gap-3 title">
                <span>From:</span>
                <span className="fw-5">{authUser?.Name}</span>
              </p>

              <h2>{customer?.LastName}</h2>
              <p>{customer?.Dept}</p>

              <div className="d-md-flex">
                <Table borderless className="info-table mb-0 mb-md-3">
                  <tbody>
                    <tr>
                      <td>Customer Name :</td>
                      <td>{customer.LastName}</td>
                    </tr>
                    <tr>
                      <td>Date:</td>
                      <td>
                        {report.at(-1)?.regdate
                          ? format(
                              new Date(report.at(-1)?.regdate),
                              "dd / MM / yyyy"
                            )
                          : "..."}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                {/* <Table borderless className="info-table mb-0 mb-md-3">
                  <tbody>
                    <tr>
                      <td>Status :</td>
                      <td>
                        <ApprovalBadge
                          text={requisition?.status}
                          className="approval"
                          bg={resolveApprovalBadgeBg(requisition?.status)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Requester:</td>
                      <td className="fw-5">
                        {requisition?.requestbyUser?.Name ||
                          requisition?.sentByName ||
                          "..."}
                      </td>
                    </tr>
                  </tbody>
                </Table> */}
              </div>
            </div>

            <div className="approval p-3 my-3 border rounded">
              <p className="border-bottom pb-3 mb-3 title">
                <span>Feedback</span>
              </p>

              <div className="approval-history">
                <Comments
                  refetch={refetch}
                  reportComments={report}
                  // requisitionid={requisition?.requestid}
                />
              </div>
            </div>
          </>
        )}
        <div className="d-flex gap-3">
          <Button variant="light-blue" onClick={() => makeComment()}>
            <MessageIcon />
            {"  "}
            Feedback
          </Button>
        </div>
      </Modal.Body>
      {/* <RequisitionApproveFooter
        {...{
          requisition,
          canPerformAction,
          canReservedUserSend,
          reciever,
          approveAndClose,
          canReservedUserPerformAction,
          reject,
          preSelectedSignatoryData,
          approveOnly,
          id,
          canApproveAndClose,
          location,
        }}
      /> */}
    </Modal>
  );
}
